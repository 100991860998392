import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { z } from 'zod';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';

import { editorCommentSchema } from 'validation/schemas/editor.schema';

import {
  EditorDataType,
  EditorLoadDataType,
  EditorTheme,
} from 'components/editor/components/base-editor/base-editor.component';
import { Editor } from 'components/editor/editor.component';
import { CommentCreateDataType } from 'components/forms/comment-create/comment-create-form.component';
import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE, VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './text-comment-create-form.module.less';

const commentCreateSchema = z
  .object({
    editor: editorCommentSchema,
  })
  .strict();

interface ITextCommentCreateFormProps extends IForm<CommentCreateDataType> {
  title?: string;
  placeholder: string;
  isEditorExpand: boolean;
  isCreateCommentSuccess?: boolean;
  isFixedPosition: boolean;
  isNeedActivateEditor: boolean;
  withLoader?: boolean;
  setIsCreateCommentSuccess?: (isSuccess: boolean) => void;
  setIsNeedActivateEditor?: (activeEditor: boolean) => void;
  setIsEditorExpand?: (isExpand: boolean) => void;
}

export const TextCommentCreateForm = (props: ITextCommentCreateFormProps) => {
  const {
    placeholder,
    title = '',
    isCreateCommentSuccess = false,
    processing,
    initialValues,
    isEditorExpand,
    isNeedActivateEditor,
    isFixedPosition,
    withLoader = true,
    setIsCreateCommentSuccess,
    setIsEditorExpand,
    setIsNeedActivateEditor,
    onSubmit,
  } = props;

  const { isNativeApp } = useMainProvider();
  const { isKeyboardOpen } = useKeyboardTrigger(isNativeApp);

  const [commentEditor, setCommentEditor] = useState<EditorLoadDataType>();
  const [isEditorActive, setIsEditorActive] = useState<boolean>(false);
  const [isHighlighted, setIsHighlighted] = useState<boolean>(isEditorExpand);

  const { control, formState, handleSubmit, reset } = useForm<CommentCreateDataType>({
    defaultValues: initialValues,
    mode: VALIDATION_MODE,
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(commentCreateSchema),
  });

  const { errors, isSubmitSuccessful, isValid } = formState;

  const handleEditorChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: EditorDataType) => onChange(value),
    [],
  );

  const handleSetEditor = useCallback((editor: EditorLoadDataType) => {
    setCommentEditor(editor);
  }, []);

  const handleEditorFocus = useCallback(() => {
    setIsEditorActive(true);
    setIsHighlighted(true);
    setIsEditorExpand?.(true);
    setIsNeedActivateEditor?.(true);
  }, [setIsEditorExpand, setIsNeedActivateEditor]);

  const handleEditorBlur = useCallback(() => {
    setIsEditorActive(false);
    setIsHighlighted(false);
    setIsNeedActivateEditor?.(false);
  }, [setIsNeedActivateEditor]);

  useEffect(() => {
    if (isNeedActivateEditor) {
      handleEditorFocus();
    }
  }, [isNeedActivateEditor, handleEditorFocus]);

  useEffect(() => {
    if (!processing && isSubmitSuccessful && isCreateCommentSuccess) {
      commentEditor?.clear();
      reset();

      // Use setTimeout because commentEditor.update set focus on form that
      // can be removed in next render
      setTimeout(() => {
        setIsEditorActive(false);
        setIsHighlighted(false);
        setIsEditorExpand?.(false);
      });

      setIsCreateCommentSuccess?.(false);
    }
  }, [
    commentEditor,
    isSubmitSuccessful,
    processing,
    isCreateCommentSuccess,
    reset,
    setIsEditorExpand,
    setIsCreateCommentSuccess,
  ]);

  const formClasses = useMemo<string>(() => {
    return cn(styles.Wrapper, {
      [styles['Wrapper--processing']]: processing,
      [styles['Wrapper--keyboard']]: isFixedPosition && isKeyboardOpen,
    });
  }, [processing, isKeyboardOpen, isFixedPosition]);

  return (
    <>
      {title && <div className={styles.Title}>{title}</div>}
      <Form className={formClasses} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="editor"
          control={control}
          render={({ field }) => (
            <Editor
              disableAttachments
              theme={EditorTheme.Grey}
              placeholder={placeholder}
              id="editor"
              name="editor"
              isHighlighted={isHighlighted}
              disabled={!isValid || processing}
              isExpand={isEditorActive || isEditorExpand}
              isActive={isEditorActive}
              isEnabledAutoScrolling={!isFixedPosition}
              value={field.value}
              error={errors.editor?.content?.length?.message}
              onLoad={handleSetEditor}
              onEditorFocus={handleEditorFocus}
              onBlur={handleEditorBlur}
              onChange={handleEditorChange(field.onChange)}
            />
          )}
        />
      </Form>
      {withLoader && <Loader isShow={!!processing} />}
    </>
  );
};
