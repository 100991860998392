import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { ConvertImageItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import { ICreatePostParams } from 'services/posts/interfaces/create-post-payload.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import {
  CreatePollPostForm,
  CreatePollPostFormDataType,
} from 'components/forms/create-poll-post/create-poll-post-form.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';

import styles from './create-poll-post-modal.module.less';

interface ICreatePollPostModalProps {
  isVisible: boolean;
  isFetching: boolean;
  onPostCreate: (post: ICreatePostParams) => void;
  onClose: () => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
}

export const CreatePollPostModal = (props: ICreatePollPostModalProps) => {
  const { isVisible, isFetching, onPostCreate, onClose, convertImageItemToAttachment } = props;

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [isFormDirty, setIsFormDirty] = useState(false);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handlePostCreate = useCallback(
    (formData: CreatePollPostFormDataType) => {
      const options = formData.options.map((option) => ({
        id: option.id,
        title: option.title,
        order: option.order,
        image_uuid: option.imageData?.uuid,
      }));

      const postObject: ICreatePostParams = {
        title: formData.title,
        attachments: {
          poll: { options },
        },
      };

      onPostCreate(postObject);
    },
    [onPostCreate],
  );

  const handleModalClose = useCallback(() => {
    setConfirmationModalOpen(isFormDirty);

    if (!isFormDirty) {
      onClose();
    }
  }, [isFormDirty, onClose]);

  const handleConfirmationModalClose = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const handleConfirmClosePost = useCallback(() => {
    setConfirmationModalOpen(false);
    onClose();
  }, [onClose]);

  const handleFormStateChange = useCallback((isDirty: boolean) => {
    setIsFormDirty(isDirty);
  }, []);

  const postCreateModalClasses = useMemo<string>(() => {
    return cn(styles.PostCreateModal, {
      [styles['PostCreateModal--blur']]: isFetching,
    });
  }, [isFetching]);

  return (
    <>
      <BaseModalComponent
        title="Create Poll"
        visible={isVisible}
        size={ModalWindowSize.M}
        onClose={handleModalClose}
        isFullScreen={!isDesktopPlus}
      >
        <div className={postCreateModalClasses}>
          <CreatePollPostForm
            initialValues={{
              title: '',
              options: [],
            }}
            onSubmit={handlePostCreate}
            onFormStateChange={handleFormStateChange}
            convertImageItemToAttachment={convertImageItemToAttachment}
            submitText="Publish"
          />
        </div>
      </BaseModalComponent>
      <ConfirmationModal
        content="Are you sure you want to leave? The post will be deleted."
        onSuccessCallback={handleConfirmClosePost}
        onClose={handleConfirmationModalClose}
        title="Close Post"
        visible={confirmationModalOpen}
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
      />
    </>
  );
};
