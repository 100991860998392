import { GameLineScoreType } from '../types/game-line-score.type';

export function gameLineScoreAdapter(
  rawLineScore: Array<Maybe<string>>,
  totalPeriods: Maybe<number>,
): GameLineScoreType {
  let periods = 4;

  if (totalPeriods && totalPeriods > 4) {
    periods = totalPeriods;
  }

  const initialArray = rawLineScore;

  if (initialArray.length < periods) {
    while (initialArray.length < periods) {
      initialArray.push(null);
    }
  }
  return initialArray.map((item) => {
    if (item) {
      return Number(item);
    }

    return null;
  });
}
