import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ConvertImageItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { useMainProvider } from 'hooks/use-main-provider';

import { userGeneratedPostEditorSchema } from 'validation/schemas/editor.schema';

import {
  EditorDataType,
  EditorTheme,
} from 'components/editor/components/base-editor/base-editor.component';
import { Editor } from 'components/editor/editor.component';
import { Form } from 'components/forms/form.component';
import { IForm } from 'components/forms/form.interface';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './create-user-generated-post-form.module.less';

const createUserGeneratedPostFormSchema = z.object({
  editor: userGeneratedPostEditorSchema,
});

export type CreateUserGeneratedPostFormDataType = z.infer<typeof createUserGeneratedPostFormSchema>;

interface ICreateUserGeneratedPostFormProps extends IForm<CreateUserGeneratedPostFormDataType> {
  isFullHeight?: boolean;
  isPostVideoLoading: boolean;
  postVideo: Maybe<IVideoResponse>;
  onUploadVideo: (video: File) => void;
  onRemoveVideo: () => void;
  onFormStateChange: (flag: boolean) => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
}

export const CreateUserGeneratedPostForm = (props: ICreateUserGeneratedPostFormProps) => {
  const {
    processing,
    initialValues,
    submitText,
    postVideo,
    isFullHeight = false,
    isPostVideoLoading,
    onSubmit,
    onFormStateChange,
  } = props;

  const editorWrapperRef = useRef<HTMLDivElement>(null);

  const [loadingImages, setLoadingImages] = useState<string[]>([]);

  const { isNativeApp } = useMainProvider();

  const { control, formState, handleSubmit } = useForm<CreateUserGeneratedPostFormDataType>({
    defaultValues: initialValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(createUserGeneratedPostFormSchema),
  });

  const { isValid, isDirty, errors } = formState;

  const handleEditorChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: EditorDataType) => onChange(value),
    [],
  );

  const handleEditorFocus = useCallback(() => {
    let timeoutInstance: NodeJS.Timeout;

    if (isNativeApp) {
      timeoutInstance = setTimeout(() => {
        editorWrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }

    return () => {
      if (isNativeApp) {
        clearTimeout(timeoutInstance);
      }
    };
  }, [isNativeApp]);

  useEffect(() => {
    onFormStateChange(isDirty);
  }, [isDirty, onFormStateChange]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.PostCreateForm}>
        <div ref={editorWrapperRef} className={styles.PostCreateForm__Editor}>
          <Controller
            name="editor"
            control={control}
            render={({ field }) => (
              <Editor
                error={errors.editor?.content?.message}
                convertImageItemToAttachment={props.convertImageItemToAttachment}
                isFullHeight={isFullHeight}
                id="editor"
                name="editor"
                isExpand
                isSendHidden
                theme={EditorTheme.BottomSheet}
                loadingImages={loadingImages}
                onChange={handleEditorChange(field.onChange)}
                value={field.value}
                isNeedForceExpand
                postVideo={postVideo}
                onUploadVideo={props.onUploadVideo}
                onEditorFocus={handleEditorFocus}
                onRemoveVideo={props.onRemoveVideo}
                setLoadingImages={setLoadingImages}
                isVideoLoading={isPostVideoLoading}
                onVideoError={props.onVideoError}
              />
            )}
          />
        </div>
        <div className={styles.PostCreateForm__Footer}>
          <div className={styles.PostCreateForm__CreateBtn}>
            <Button
              type="submit"
              size={ButtonSize.Big}
              theme={ButtonTheme.Primary}
              disabled={!isValid || processing}
              iconName={IconFontName.SendFilled}
            >
              {submitText}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};
