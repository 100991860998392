import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IGetCommentPayload } from 'services/comments/interfaces/comments.interface';
import { ITogglePostCommentBookmarkPayload } from 'services/posts/interfaces/create-post-payload.interface';

import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IAttachments } from 'stores/posts/interfaces/post.interface';

import { BOOKMARK_POST_CARD_GROUPS } from 'configs/context-menu-groups.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { Attachments } from 'components/attachments/attachments.component';
import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import {
  BookmarkFooter,
  BookmarkFooterTheme,
  BookmarkFooterType,
} from 'components/bookmarks/bookmark-footer/bookmark-footer.component';
import { ICommentData } from 'components/comment/comment.component';
import { CommentToolTip } from 'components/comment/comment-tooltip/comment-tooltip.component';
import {
  IFormattedDates,
  IPostCardUserInfo,
} from 'components/posts/post-card/interfaces/post-info.interface';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';

import styles from './comment-small.module.less';

interface ICommentProps {
  isBookmarked: boolean;
  id: string;
  bookmarkDate: IFormattedDates;
  postId: string;
  postTitle: string;
  commentAuthor: IPostCardUserInfo;
  commentData: ICommentData;
  attachments: IAttachments;
  onBookmarkClick: (payload: ITogglePostCommentBookmarkPayload) => void;
  onPollVote: (voteData: IPollVoteData) => void;
  onPollExpired: (postId: string) => void;
  onOpenComment: (payload: IGetCommentPayload) => void;
  onShareClick: () => void;
  onOpenPreview: OpenPreviewType;
  onCloseBookmarks: () => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
}

export const CommentSmall = (props: ICommentProps) => {
  const {
    id,
    postTitle,
    postId,
    isBookmarked,
    commentAuthor,
    commentData,
    onBookmarkClick,
    bookmarkDate,
    attachments,
    onOpenComment,
    onShareClick,
    onCloseBookmarks,
  } = props;

  const { favoritePlayer, favoriteTeam } = commentAuthor;

  const navigate = useNavigate();

  const [isToolTipVisible, setIsToolTipVisible] = useState(false);
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const [isNeedCloseTooltip, setIsNeedCloseTooltip] = useState(false);

  const handleShowToolTip = useCallback(() => {
    setIsToolTipVisible(true);
  }, []);

  const handleHideToolTip = useCallback(() => {
    setIsToolTipVisible(false);
  }, []);

  const handleBookmarkClick = useCallback(() => {
    onBookmarkClick({
      postId,
      commentId: id,
    });
  }, [id, postId, onBookmarkClick]);

  const handleNavigateToComment = useCallback(() => {
    onOpenComment({
      postId,
      commentId: id,
      isNeedUpdateNestedComments: false,
    });

    navigate(
      getPath(path.HOME_FEED_POST, {
        [path.POST_ID_PARAM]: postId,
      }),
    );

    if (!isDesktopPlus) {
      onCloseBookmarks();
    }
  }, [postId, isDesktopPlus, id, onOpenComment, onCloseBookmarks, navigate]);

  const handleShareClick = useCallback(() => {
    onShareClick();
  }, [onShareClick]);

  const handleContextMenuClick = useCallback(
    (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'share') {
        handleShareClick();
      }
      if (barAction.type === BarAction.Click && barAction.payload === 'bookmark') {
        handleBookmarkClick();
      }

      setIsNeedCloseTooltip(true);
    },
    [handleShareClick, handleBookmarkClick],
  );

  return (
    <div
      className={styles.Comment}
      onMouseEnter={handleShowToolTip}
      onMouseLeave={handleHideToolTip}
    >
      <div className={styles.Comment__Header}>
        <Avatar
          size={AvatarSize.XS}
          username={commentAuthor.username}
          src={commentAuthor.smallAvatarUrl}
        />
        <div className={styles.TopSection}>
          <div className={styles.TopSection__FirstRow}>
            <div className={styles.TopSection__UserNick}>{commentAuthor.username}</div>
            <div className={styles.TopSection__Date}>{commentData.createAt}</div>
          </div>
          <TeamPlayerLabel
            team={favoriteTeam}
            player={favoritePlayer}
            onTeamClick={props.onTeamClick}
            onPlayerClick={props.onPlayerClick}
          />
        </div>
        {isToolTipVisible && isDesktopPlus && (
          <CommentToolTip
            isBookmarked={isBookmarked}
            onBookmarkClick={handleBookmarkClick}
            onShareClick={handleShareClick}
            isCurrentUserComment={false}
            isAllowedCommentDelete={false}
          />
        )}
        {!isDesktopPlus && (
          <div className={styles.Comment__HeaderTooltip}>
            <ContextMenuTooltip
              groups={BOOKMARK_POST_CARD_GROUPS}
              onItemClick={handleContextMenuClick}
              toggleOnClick
              setIsNeedCloseTooltip={setIsNeedCloseTooltip}
              tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
              isNeedCloseTooltip={isNeedCloseTooltip}
            >
              <IconButton
                iconName={IconFontName.More}
                theme={IconButtonTheme.Secondary}
                iconSize={IconFontSize.Big}
              />
            </ContextMenuTooltip>
          </div>
        )}
      </div>
      <div className={styles.Comment__Main}>
        <button className={styles.Comment__Content} onClick={handleNavigateToComment}>
          <RichTextPreview
            id={id}
            editorState={commentData.comment}
            editorType={RichTextContentTypes.Truncated}
          />
        </button>
        <Attachments
          team={null}
          entityId={id}
          isForBookmarks
          author={commentAuthor}
          attachments={attachments}
          isSmall
          date={bookmarkDate.relativeLong}
          onPollVote={props.onPollVote}
          onPollExpired={props.onPollExpired}
          parentEntityId={postId}
          onOpenPreview={props.onOpenPreview}
        />
        <div
          className={styles.Comment__Overlay}
          role="button"
          aria-label="Close"
          tabIndex={0}
          onClick={handleNavigateToComment}
          onKeyDown={handleNavigateToComment}
        />
      </div>
      <BookmarkFooter
        type={BookmarkFooterType.Comment}
        theme={BookmarkFooterTheme.Comment}
        title={postTitle}
        time={bookmarkDate.relativeLong}
      />
    </div>
  );
};
