import { FC } from 'react';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { ScoreboardContainer } from 'containers/scoreboard/scoreboard.container';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

import styles from './home-scores.module.less';

export const HomeScoresRoute: FC = () => {
  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <div className={styles.Scores}>
            <ScoreboardContainer />
          </div>
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
