import { FC } from 'react';

import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';

import { FeedActionsContainer } from 'containers/feed-actions/feed-actions.container';
import { HeadlinesFeedContainer } from 'containers/headlines-feed/headlines-feed.container';
import { ScrollTopContainer } from 'containers/scroll-top/scroll-top.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useEnableRefresher } from 'hooks/use-enable-refresher';
import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useResponsive } from 'hooks/use-responsive';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const HeadlinesFeedRoute: FC = () => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEnableRefresher();

  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });

  return (
    <Row layout={RowLayout.MainContent} withSpacing={false}>
      <Column mobile={1}>
        <HeadlinesFeedContainer />
      </Column>
      {isDesktopPlus && (
        <Column mobile={2}>
          <FeedActionsContainer />
          <ScrollTopContainer />
        </Column>
      )}
    </Row>
  );
};
