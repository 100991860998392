import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useUserBalanceQuery } from 'query-hooks/reputation/use-user-balance.query';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { useLayoutEntity } from 'hooks/use-layout-entity';

import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { EditProfileModal } from 'components/modals/edit-profile-modal/edit-profile-modal.component';
import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { ProfileInfo } from 'components/profile-info/profile-info.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const UserPublicProfileHeaderContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);

  const [editModalVisible, setEditModalVisible] = useState(false);

  const myUserData = authStore.userMe;

  const publicUserData = userPublicStore.userPublic;

  const myFavTeamId = authStore.userMe?.profile.favoriteTeamId || null;

  const params = useParams<{
    [paths.USERNAME_PARAM]: string;
  }>();

  const { username } = params;

  const isMyPage = Boolean(authStore.userMe?.username === username);

  const { data: userBalance } = useUserBalanceQuery(username);

  const handleUpdateUser = useCallback(
    async (updateUserParams: IUpdateUserParams, closeEditModal = true) => {
      if (authStore.fetching) {
        return;
      }

      if (closeEditModal) {
        setEditModalVisible(false);
      }

      await authStore.updateUserMe(updateUserParams);
    },
    [authStore],
  );

  const userProfileInitialData = useMemo(() => {
    const userName = myUserData?.username ? myUserData.username : '';
    const realName = myUserData?.profile.realName ? myUserData?.profile.realName : '';
    const biography = myUserData?.profile.biography ? myUserData?.profile.biography : '';
    return {
      username: userName,
      realname: realName,
      biography,
    };
  }, [myUserData]);

  const handleClose = () => {
    setEditModalVisible(false);
  };

  const handleCropAvatarModalOpen = useCallback(
    (isOpen: boolean) => {
      layoutStore.setIsCropBackgroundModalOpen(false);
      layoutStore.setIsCropAvatarModalOpen(isOpen);
    },
    [layoutStore],
  );

  const handleCropBackgroundModalOpen = useCallback(
    (isOpen: boolean) => {
      layoutStore.setIsCropAvatarModalOpen(false);
      layoutStore.setIsCropBackgroundModalOpen(isOpen);
    },
    [layoutStore],
  );

  const handleCloseConfirmationModal = useCallback(() => {
    layoutStore.setIsTeamBackgroundUpdateModalOpen(false);
  }, [layoutStore]);

  const handleConfirmationSuccess = useCallback(() => {
    authStore.setShouldApplyFavoriteTeamBackground(true);
    layoutStore.setIsTeamBackgroundUpdateModalOpen(false);
    authStore.updateUserMe({ favoriteTeamId: myFavTeamId });
  }, [authStore, layoutStore, myFavTeamId]);

  const handleOpenPreview = useCallback(
    (image: IInteractiveMediaAttachment) => {
      const watermarkEntity = publicUserData?.smallAvatarUrl
        ? {
            name: publicUserData.username,
            logoUrl: publicUserData.smallAvatarUrl,
          }
        : null;

      galleryStore.setGalleryAttachments([image]);
      galleryStore.setTheme(GalleryTheme.Preview);
      galleryStore.setWatermarkEntity(watermarkEntity);
      galleryStore.setIsGalleryOpen(true);
    },
    [galleryStore, publicUserData],
  );

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.UserProfile,
      },
    });
  }, []);

  const isAuthUserProfile = useMemo(() => {
    if (!authStore.isAuthorised || !myUserData) {
      return false;
    }

    return authStore.userMe?.username === myUserData?.username;
  }, [authStore.isAuthorised, authStore.userMe?.username, myUserData]);

  const handleShowModal = () => {
    setEditModalVisible(true);
  };

  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });

  return (
    <div>
      <ProfileInfo
        rating={userBalance?.totalPoints || 0}
        isMyPage={isMyPage}
        userData={publicUserData}
        onEditProfilePress={handleShowModal}
        isAuthUserProfile={isAuthUserProfile}
        onOpenPreview={handleOpenPreview}
      />
      <EditProfileModal
        loading={authStore.fetching}
        userData={myUserData}
        isCropAvatarModalVisible={layoutStore.isCropAvatarModalOpen}
        isCropBackgroundModalVisible={layoutStore.isCropBackgroundModalOpen}
        onSetAvatarCropModal={handleCropAvatarModalOpen}
        onSetBackgroundCropModal={handleCropBackgroundModalOpen}
        onClose={handleClose}
        visible={editModalVisible}
        initialValues={userProfileInitialData}
        onSubmit={handleUpdateUser}
      />
      <ConfirmationModal
        onClose={handleCloseConfirmationModal}
        visible={layoutStore.isTeamBackgroundUpdateModalOpen}
        onSuccessCallback={handleConfirmationSuccess}
        title="Update Background"
        content="Would you like to change your custom background to the team's related one?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
      />
    </div>
  );
});
