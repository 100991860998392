import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { IGameDetailedRecord } from 'query-hooks/scoreboard/interfaces/game-detailed-record.interface';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { SeasonType } from 'services/seasons/enums/season-type.enum';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatScheduledDate } from 'helpers/format/format-scheduled-date.util';

import { useResponsive } from 'hooks/use-responsive';

import { GameDetailsTabs } from 'components/game/game.config';
import {
  ScoreboardTeam,
  ScoreboardTeamOrder,
} from 'components/scoreboard/scoreboard-team/scoreboard-team.component';
import { LiveGamesScores } from 'components/statistics/live-game-scores/live-game-scores';

import scoreboardTableGridStyles from '../scoreboard-table-grid.module.less';
import styles from './scoreboard-table-row.module.less';

interface IScoreboardTableRowProps {
  status: GameStatus;
  home: IGameDetailedRecord;
  visitors: IGameDetailedRecord;
  formattedPeriod: string;
  clock: Maybe<string>;
  dateStart: string;
  seasonType: SeasonType;
  onTeamClick: (teamId: number) => void;
  onGameClick: (tabQuery: Maybe<GameDetailsTabs>) => void;
}

export const ScoreboardTableRow = memo((props: IScoreboardTableRowProps) => {
  const { status, home, visitors, formattedPeriod, clock, dateStart, seasonType, onGameClick } =
    props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const isScheduled = status === GameStatus.Scheduled;

  const classNames = useMemo<string>(
    () =>
      cn(styles.ScoreboardTableRow, {
        [scoreboardTableGridStyles.ScoreboardTableGrid]: isDesktopPlus,
        [styles['ScoreboardTableRow--scheduled']]: isScheduled,
      }),
    [isScheduled, isDesktopPlus],
  );

  const handleGameClick = useCallback(() => {
    onGameClick(null);
  }, [onGameClick]);

  return (
    <div
      className={classNames}
      role="row"
      onClick={handleGameClick}
      onKeyDown={handleGameClick}
      tabIndex={0}
    >
      <div role="cell" className={styles.ScoreboardTableRow__Teams}>
        <ScoreboardTeam
          teamOrder={ScoreboardTeamOrder.Horizontal}
          isHome={false}
          status={status}
          seasonType={seasonType}
          onTeamClick={props.onTeamClick}
          {...visitors.team}
        />
        {!isScheduled && (
          <div className={styles.ScoreboardTableRow__Scores}>
            <LiveGamesScores
              status={status}
              homePoints={home.scores.total || 0}
              visitorsPoints={visitors.scores.total || 0}
              clock={clock}
              formattedPeriod={formattedPeriod}
            />
          </div>
        )}
        <ScoreboardTeam
          teamOrder={ScoreboardTeamOrder.Horizontal}
          isHome
          seasonType={seasonType}
          status={status}
          onTeamClick={props.onTeamClick}
          {...home.team}
        />
      </div>

      {isDesktopPlus && (
        <div role="cell" className={scoreboardTableGridStyles.ScoreboardTableGrid__Time}>
          <div className={styles.ScoreboardTableRow__Time}>{formatScheduledDate(dateStart)}</div>
        </div>
      )}
    </div>
  );
});
