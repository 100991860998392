import { memo, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import cn from 'classnames';

import { ConvertVideoItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaType,
  ICollaborationItemPayload,
} from 'services/collaboration/interfaces/collaboration.interface';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IEventParams } from 'services/google-analytic/interfaces/event-payload.interface';
import { PostSourceType } from 'services/posts/enums/post-source-type.enum';
import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';
import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import { IBasePublicationAuthor } from 'stores/entries/interfaces/entries-autor.interface';
import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import { userReportHandler } from 'helpers/user-report-handler.util';
import * as paths from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';
import { useScrollTo } from 'hooks/use-scroll-to';

import { Attachments } from 'components/attachments/attachments.component';
import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import {
  BookmarkFooter,
  BookmarkFooterTheme,
  BookmarkFooterType,
} from 'components/bookmarks/bookmark-footer/bookmark-footer.component';
import { CollaborationSection } from 'components/collaboration-section/collaboration-section';
import { EmbeddedAttachment } from 'components/embedded-attachment/embedded-attachment.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { IFormattedDates } from 'components/posts/post-card/interfaces/post-info.interface';
import { ReportsPopup } from 'components/reports/reports-popup/reports-popup.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { ConvertPostImageItemToAttachmentType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import { ImageWithBadge } from 'components/ui/image-with-badge/image-with-badge.component';
import { IPollUpdateData, IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';
import { MAX_RICH_TEXT_LINES } from 'components/ui/rich-text-preview/rich-text-preview.config';
import { YouTubePlayer } from 'components/youtube-player/youtube-player.component';

import athleteBackgroundImage from 'assets/images/svg/athlete-background.svg';

import { PostsTheme } from './enums/posts-theme.enum';
import { IPostInfo } from './interfaces/post-info.interface';
import { PostCardActionsBar } from './post-card-actions-bar/post-card-actions-bar.component';
import { PostCardFeeds, PostCardFeedsTheme } from './post-card-feeds/post-card-feeds.component';
import { PostCardHeader } from './post-card-header/post-card-header.component';

import styles from './post-card.module.less';

export interface IPostCardProps {
  isCurrentUserStaff?: boolean;
  isPostDetails?: boolean;
  theme: PostsTheme;
  feedsTheme: PostCardFeedsTheme;
  isLinksClickable?: boolean;
  isPulledRefresher?: boolean;
  userInfo: IBasePublicationAuthor;
  favoriteTeam: Maybe<ITeamsStats>;
  favoritePlayer: Maybe<IPlayerStats>;
  postInfo: IPostInfo;
  editorType: RichTextContentTypes;
  bookmarkDate?: IFormattedDates;
  reportReasons?: Maybe<IReportReason[]>;
  isBookmarked: boolean;
  team: Maybe<ITeamsStats>;
  visitedPostId?: Maybe<string>;
  isForSidePanel?: boolean;
  userId: Maybe<string>;
  isAthlete: boolean;
  activeFeed?: Maybe<FeedTypes>;
  onShareClick: () => void;
  onPollVote?: (voteData: IPollVoteData) => void;
  onPollUpdate?: (pollData: IPollUpdateData) => void;
  onPollExpired?: (postId: string) => void;
  onOpenPreview?: OpenPreviewType;
  onResetCurrentPostId?: (id: Maybe<string>) => void;
  onDeletePost?: (id: string) => void;
  onCloseBookmarks?: () => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  onCloseReportsPopup?: (postId: string) => void;
  onTogglePostToBookmarks: (id: string) => void;
  onSendReport?: (postId: string, reasonId: number) => Promise<boolean>;
  onLikesClick?: (postIdParam: string) => Promise<void>;
  onOpenUserDetailsPopUp?: (userData: IBasePublicationAuthor) => void;
  postProcessingId?: Maybe<string>;
  convertImageItemToAttachment?: ConvertPostImageItemToAttachmentType;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  onCreateCollaborationItem?: (payload: ICollaborationItemPayload) => void;
  collaborationMediaItem?: Maybe<CollaborationMediaType>;
  setCollaborationMediaItem?: (value: Maybe<CollaborationMediaType>) => void;
  isCollaborationVideoLoading?: boolean;
  requestReasons?: () => void;
  isFirstPost?: boolean;
}

export const PostCard = memo((props: IPostCardProps) => {
  const {
    theme,
    onOpenUserDetailsPopUp,
    feedsTheme,
    isLinksClickable = false,
    isPostDetails = false,
    isForSidePanel = false,
    isPulledRefresher = false,
    userId,
    isAthlete,
    editorType,
    team,
    userInfo,
    favoriteTeam,
    favoritePlayer,
    postInfo,
    bookmarkDate = {
      timeOnly: '',
      relativeLong: '',
      relativeShort: '',
      full: '',
    },
    isFirstPost,
    reportReasons,
    isBookmarked,
    visitedPostId,
    activeFeed,
    onTogglePostToBookmarks,
    onShareClick,
    onResetCurrentPostId,
    onCloseBookmarks,
    onCloseReportsPopup,
    onSendReport,
    onDeletePost,
    onLikesClick,
    postProcessingId,
    isCurrentUserStaff = false,
  } = props;

  const titleRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [collapsedTitle, setCollapsedTitle] = useState(true);
  const [isLikesEnabled, setIsLikesEnabled] = useState(true);
  const [isNeedCloseTooltip, setIsNeedCloseTooltip] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isReportsPopupOpen, setIsReportsPopupOpen] = useState(false);
  const [scrollToRef, setShouldScrollTo] = useScrollTo<HTMLDivElement>();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { isNativeApp } = useMainProvider();

  const navigate = useNavigate();

  const handleOnCloseConfirmation = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, []);

  const feedTeam = useMemo(() => {
    if (postInfo.feeds.feed?.type === PostsFeedType.Team) {
      return postInfo.feeds.feed;
    }

    return null;
  }, [postInfo]);

  const feedPlayer = useMemo(() => {
    if (postInfo.feeds.feed?.type === PostsFeedType.Player) {
      return postInfo.feeds.feed;
    }

    return null;
  }, [postInfo]);

  const handleOnConfirmDeletePost = useCallback(() => {
    onDeletePost?.(postInfo.id);
    setIsConfirmModalOpen(false);
  }, [onDeletePost, postInfo.id]);

  const handleOpenUserDetailsPopUp = useCallback(() => {
    if (!postInfo.source && !userInfo.athlete) {
      onOpenUserDetailsPopUp?.(userInfo);
    }

    if (userInfo.athlete) {
      navigate(
        getPath(paths.PLAYER_PROFILE_OVERVIEW, {
          [paths.PLAYER_SLUG_PARAM]: userInfo.athlete.playerSlug,
        }),
      );
    }
  }, [postInfo.source, userInfo, onOpenUserDetailsPopUp, navigate]);

  const cardLink = useMemo(() => {
    if (postInfo.source) {
      return '';
    }

    return getPath(paths.HOME_FEED_POST, { [paths.POST_ID_PARAM]: postInfo.id });
  }, [postInfo.id, postInfo.source]);

  const handleBookmarkClick = useCallback(() => {
    const eventParams: IEventParams = {};

    if (FeedTypes.Team === activeFeed && feedTeam) {
      eventParams.button_tap_type = ButtonTapsEnum.TeamProfileFeedBookmark;
      eventParams.team = feedTeam.name;
    }

    if (FeedTypes.Player === activeFeed && feedPlayer) {
      eventParams.button_tap_type = ButtonTapsEnum.PlayerPostFeedBookmark;
      eventParams.player = `${feedPlayer.firstname} ${feedPlayer.lastname}`;
    }

    if (activeFeed && FeedTypes.Team !== activeFeed && FeedTypes.Player !== activeFeed) {
      eventParams.button_tap_type = ButtonTapsEnum.HomeFeedBookmark;
      eventParams.feed = activeFeed;
    }

    if (eventParams.button_tap_type) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams,
      });
    }

    onTogglePostToBookmarks(postInfo.id);
  }, [activeFeed, postInfo, feedTeam, feedPlayer, onTogglePostToBookmarks]);

  const handleShareClick = useCallback(() => {
    const eventParams: IEventParams = {};

    if (FeedTypes.Team === activeFeed && feedTeam) {
      eventParams.button_tap_type = ButtonTapsEnum.TeamProfileShare;
      eventParams.team = feedTeam.name;
    }

    if (FeedTypes.Player === activeFeed && feedPlayer) {
      eventParams.button_tap_type = ButtonTapsEnum.PlayerPostFeedShare;
      eventParams.player = `${feedPlayer.firstname} ${feedPlayer.lastname}`;
    }

    if (activeFeed && FeedTypes.Team !== activeFeed && FeedTypes.Player !== activeFeed) {
      eventParams.button_tap_type = ButtonTapsEnum.HomeFeedShare;
      eventParams.feed = activeFeed;
    }

    if (eventParams.button_tap_type) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams,
      });
    }

    onShareClick();
  }, [activeFeed, feedTeam, feedPlayer, onShareClick]);

  const handleNavigatePostCardByComment = useCallback(() => {
    const eventParams: IEventParams = {};

    if (FeedTypes.Team === activeFeed && feedTeam) {
      eventParams.button_tap_type = ButtonTapsEnum.TeamProfileFeedComment;
      eventParams.team = feedTeam.name;
    }

    if (FeedTypes.Player === activeFeed && feedPlayer) {
      eventParams.button_tap_type = ButtonTapsEnum.PlayerPostFeedComment;
      eventParams.player = `${feedPlayer.firstname} ${feedPlayer.lastname}`;
    }

    if (activeFeed && FeedTypes.Team !== activeFeed && FeedTypes.Player !== activeFeed) {
      eventParams.button_tap_type = ButtonTapsEnum.HomeFeedComment;
      eventParams.feed = activeFeed;
    }

    if (eventParams.button_tap_type) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams,
      });
    }

    if (userId) {
      navigate(cardLink, { state: { focusEditor: true } });
    }
  }, [activeFeed, cardLink, feedPlayer, feedTeam, navigate, userId]);

  const footerTitle = useMemo(
    () => (feedTeam ? feedTeam.name : postInfo.title),
    [feedTeam, postInfo.title],
  );

  const handleContextMenuClick = useCallback(
    (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'share') {
        handleShareClick();
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'bookmark') {
        handleBookmarkClick();
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'delete') {
        setIsConfirmModalOpen(true);
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'report') {
        setIsReportsPopupOpen(true);
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'user-report') {
        userReportHandler();
      }

      setIsNeedCloseTooltip(true);
    },
    [handleShareClick, handleBookmarkClick],
  );

  const handleReportsPopupClose = useCallback(() => {
    onCloseReportsPopup?.(postInfo.id);
    setIsReportsPopupOpen(false);
  }, [postInfo.id, onCloseReportsPopup]);

  useEffect(() => {
    if (visitedPostId === postInfo.id && onResetCurrentPostId) {
      setShouldScrollTo(true);

      onResetCurrentPostId(null);
    }
  }, [postInfo.id, visitedPostId, setShouldScrollTo, onResetCurrentPostId]);

  const CustomTag = useMemo(() => {
    if (isLinksClickable || postInfo.source) {
      return 'div';
    }

    return Link;
  }, [isLinksClickable, postInfo.source]);

  const isMyPost = useMemo(() => userInfo.uuid === userId, [userInfo, userId]);

  const userSourceAvatar = useMemo(() => {
    if (postInfo.source?.author) {
      return postInfo.source?.author.profileImage;
    }

    return userInfo.smallAvatarUrl;
  }, [postInfo.source, userInfo]);

  const userSourceUsername = useMemo(() => {
    if (postInfo.source?.author?.username) {
      return postInfo.source?.author.username;
    }

    return userInfo.username;
  }, [postInfo.source, userInfo]);

  const handleContentClick = useCallback(() => {
    if (onCloseBookmarks && !isDesktopPlus) {
      onCloseBookmarks();
    }
  }, [isDesktopPlus, onCloseBookmarks]);

  const handleOpenSource = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      if (postInfo.source) {
        if (isNativeApp) {
          Browser.open({ url: postInfo.source.url });
          return;
        }

        window.open(postInfo.source.url, '_blank');
      }
    },
    [isNativeApp, postInfo.source],
  );

  const handleReportSubmit = useCallback(
    async (reasonId: number) => {
      const isSuccessResponse = await onSendReport?.(postInfo.id, reasonId);

      return !!isSuccessResponse;
    },
    [postInfo.id, onSendReport],
  );

  const handleFeedClick = useCallback(
    (type: PostsFeedType, id: string) => {
      if (type === PostsFeedType.Team) {
        navigate(
          getPath(paths.TEAM_FEED, {
            [paths.TEAM_ID_PARAM]: id,
          }),
        );

        return;
      }

      if (type === PostsFeedType.Player) {
        if (userInfo.athlete) {
          navigate(
            getPath(paths.PLAYER_PROFILE_FAN_ZONE, {
              [paths.PLAYER_SLUG_PARAM]: id,
            }),
          );
        } else {
          navigate(
            getPath(paths.PLAYER_PROFILE_FEED, {
              [paths.PLAYER_SLUG_PARAM]: id,
            }),
          );
        }

        return;
      }

      navigate(paths.HOME_FEED);
    },
    [navigate, userInfo.athlete],
  );

  const postCardClasses = useMemo(
    () =>
      cn(styles.PostCard, {
        [styles['PostCard--detail-theme']]: theme === PostsTheme.Detail,
        [styles['PostCard--bookmark-theme']]: theme === PostsTheme.Bookmark,
        [styles['PostCard--side-panel-theme']]: isForSidePanel,
        [styles['PostCard--freezed']]: isPulledRefresher,
        [styles['PostCard--single-title']]: !postInfo.content,
        [styles['PostCard--first']]: isFirstPost,
        [styles['PostCard--with-actions-list']]:
          postInfo.actionsList || postInfo.athleteInteractionDescription,
      }),
    [
      theme,
      isForSidePanel,
      isPulledRefresher,
      postInfo.content,
      postInfo.actionsList,
      isFirstPost,
      postInfo.athleteInteractionDescription,
    ],
  );

  const postCardTitleClasses = useMemo(
    () =>
      cn(styles.PostCard__Title, {
        [styles['PostCard__Title--is-collapsed']]: collapsedTitle,
      }),
    [collapsedTitle],
  );

  // the callback shouldn't be memoized
  const getTextLinesLength = () => {
    if (titleRef.current) {
      const titleStyles = window.getComputedStyle(titleRef.current);
      const lineHeightValue = titleStyles.getPropertyValue('line-height');

      const entireHeight = titleRef.current.clientHeight;
      const lineHeight = parseInt(lineHeightValue, 10);

      const countLines = Math.round(entireHeight / lineHeight);

      if (countLines < MAX_RICH_TEXT_LINES) {
        return MAX_RICH_TEXT_LINES - countLines;
      }

      return 0;
    }

    return MAX_RICH_TEXT_LINES;
  };

  const handleLike = useCallback(async () => {
    setIsLikesEnabled(false);
    await onLikesClick?.(postInfo.id);
    setIsLikesEnabled(true);
  }, [postInfo, onLikesClick]);

  const [athleteCardStyles, setAthleteCardStyles] = useState({});

  useEffect(() => {
    if (userInfo.athlete && !isDesktopPlus) {
      const cardStyles = {
        color: userInfo.athlete.secondaryColorWithOpacity,
        background: `linear-gradient(to bottom right, ${userInfo.athlete.primaryColorWithOpacity}, ${userInfo.athlete.primaryColorWithZeroOpacity} 50%)`,
      };
      setAthleteCardStyles(cardStyles);
    }
  }, [isDesktopPlus, userInfo.athlete]);

  return (
    <div ref={scrollToRef} className={postCardClasses} style={athleteCardStyles}>
      {userInfo.athlete && !isDesktopPlus && (
        <img
          className={styles.AthleteBackground}
          src={athleteBackgroundImage}
          alt="athlete background"
        />
      )}
      {theme !== PostsTheme.Detail && !isForSidePanel && (
        <div
          role="button"
          aria-label="Profile"
          tabIndex={0}
          onKeyDown={handleOpenUserDetailsPopUp}
          onClick={handleOpenUserDetailsPopUp}
          className={styles.PostCard__Avatar}
        >
          <Avatar src={userSourceAvatar} size={AvatarSize.M} username={userSourceUsername} />
        </div>
      )}
      <div className={styles.PostCard__Inner} ref={contentRef}>
        <CustomTag to={cardLink} className={styles.PostCard__Wrapper} onClick={handleContentClick}>
          <PostCardHeader
            isPostDetails={isPostDetails}
            athlete={userInfo.athlete}
            theme={theme}
            feedsTheme={feedsTheme}
            isAllowedPostDelete={isCurrentUserStaff || isMyPost}
            createdDates={postInfo.createdDates}
            userSourceAvatar={userSourceAvatar}
            userSourceUsername={userSourceUsername}
            favoriteTeam={favoriteTeam}
            favoritePlayer={favoritePlayer}
            source={postInfo.source}
            isForSidePanel={isForSidePanel}
            isNeedCloseTooltip={isNeedCloseTooltip}
            feeds={postInfo.feeds}
            onContextMenuClick={handleContextMenuClick}
            setIsNeedCloseTooltip={setIsNeedCloseTooltip}
            onFeedClick={handleFeedClick}
            onTeamClick={props.onTeamClick}
            onPlayerClick={props.onPlayerClick}
            onSourceClick={handleOpenSource}
          />
          <div className={styles.PostCard__ContentWrapper}>
            {feedsTheme === PostCardFeedsTheme.List && (
              <div className={styles.PostCard__Feeds}>
                <PostCardFeeds
                  items={postInfo.feeds}
                  source={postInfo.source}
                  theme={feedsTheme}
                  onSourceClick={handleOpenSource}
                  onFeedClick={handleFeedClick}
                />
              </div>
            )}
            {postInfo.title && (
              <div ref={titleRef} className={postCardTitleClasses}>
                {postInfo.title}
              </div>
            )}
            {postInfo.content && (
              <div className={styles.PostCard__Content}>
                <RichTextPreview
                  id={postInfo.id}
                  editorType={editorType}
                  editorState={postInfo.content}
                  getTextLinesLength={getTextLinesLength}
                  setCollapsedTitle={setCollapsedTitle}
                />
              </div>
            )}
          </div>
        </CustomTag>
        {postInfo.source?.channel === 'youtube' && (
          <YouTubePlayer sourceUrl={postInfo.source.url} />
        )}
        {postInfo.embeddedLink && theme !== PostsTheme.Bookmark && (
          <EmbeddedAttachment
            sourceUrl={postInfo.embeddedLink.url}
            embeddedType={postInfo.embeddedLink.embeddedType}
          />
        )}
        {postInfo.source?.imagePreview && postInfo.source.type === PostSourceType.News && (
          <ImageWithBadge
            imagePreviewUrl={postInfo.source.imagePreview}
            sourceUrl={postInfo.source.url}
            onImageClick={handleOpenSource}
          />
        )}
        <Attachments
          entityId={postInfo.id}
          isForBookmarks={theme === PostsTheme.Bookmark}
          isAthlete={isAthlete}
          isMyPost={isMyPost}
          athlete={userInfo.athlete}
          author={{
            smallAvatarUrl: userSourceAvatar,
            username: userSourceUsername,
          }}
          attachments={postInfo.attachments}
          parentEntityId={postInfo.id}
          date={postInfo.createdDates.relativeLong}
          isSmall={isForSidePanel || (theme === PostsTheme.Bookmark && !isForSidePanel)}
          onPollVote={props.onPollVote}
          onPollUpdate={props.onPollUpdate}
          onOpenPreview={props.onOpenPreview}
          onPollExpired={props.onPollExpired}
          team={team}
          hasShowMore={!!postInfo.source?.hasShowMore}
          onOpenSource={handleOpenSource}
        />
        {postInfo.collaboration && (
          <CollaborationSection
            collaborationId={postInfo.collaboration.id}
            postId={postInfo.id}
            isPostDetails={isPostDetails}
            isAuthorised={!!userId}
            isLoading={postInfo.id === postProcessingId}
            selectedItemId={postInfo.collaboration.selectedItemId}
            media={postInfo.collaboration.media}
            textAllowed={postInfo.collaboration.textAllowed}
            items={postInfo.collaboration.items}
            convertImageItemToAttachment={props.convertImageItemToAttachment}
            convertVideoItemToAttachment={props.convertVideoItemToAttachment}
            onCreateCollaborationItem={props.onCreateCollaborationItem}
            itemsTotal={postInfo.collaboration.itemsTotal}
            collaborationMediaItem={props.collaborationMediaItem}
            isCollaborationVideoLoading={props.isCollaborationVideoLoading}
            setCollaborationMediaItem={props.setCollaborationMediaItem}
          />
        )}
        {theme !== PostsTheme.Bookmark && (
          <>
            <PostCardActionsBar
              isLikeEnabled={isLikesEnabled}
              isCommentsDisabled={isPostDetails && !!postInfo.collaboration}
              isCommentsAllowed={postInfo.isCommentsAllowed}
              isLiked={postInfo.isLiked}
              isLikedByAthlete={postInfo.isLikedByAthlete}
              isCommentedByAthlete={postInfo.isCommentedByAthlete}
              theme={theme}
              onLikesClick={handleLike}
              likes={postInfo.likes}
              isBookmarked={isBookmarked}
              bookmarksCount={postInfo.bookmarksCount}
              commentsCount={postInfo.commentsCount}
              sharesCount={postInfo.sharesCount}
              onCommentClick={handleNavigatePostCardByComment}
              onBookmarkClick={handleBookmarkClick}
              onShareClick={handleShareClick}
              isAthlete={isAthlete}
            />
            {postInfo.athleteInteractionDescription && (
              <div className={styles.PostCard__Interactions}>
                <span className={styles.PostCard__ActionList}>
                  {postInfo.athleteInteractionDescription}
                </span>
              </div>
            )}
            {postInfo.actionsList && (
              <div className={styles.PostCard__Interactions}>
                <span className={styles.PostCard__ActionList}>{postInfo.actionsList}</span>
                {ENCODED_BULLET}
                <span className={styles.PostCard__InteractionFeed}>
                  {postInfo.defaultFeedTitle}
                </span>
              </div>
            )}
          </>
        )}
        {theme === PostsTheme.Bookmark && (
          <BookmarkFooter
            type={BookmarkFooterType.Post}
            theme={BookmarkFooterTheme.Post}
            time={bookmarkDate.relativeLong}
            title={footerTitle}
          />
        )}
        {isConfirmModalOpen && (
          <ConfirmationModal
            content="Are you sure you want to delete this post? This cannot be undone."
            onSuccessCallback={handleOnConfirmDeletePost}
            onClose={handleOnCloseConfirmation}
            title="Delete Post"
            visible
            primaryButtonText="Delete"
            secondaryButtonText="Cancel"
          />
        )}
        {!isMyPost && theme !== PostsTheme.Bookmark && isReportsPopupOpen && (
          <ReportsPopup
            requestReasons={props.requestReasons}
            reasons={reportReasons}
            visible={isReportsPopupOpen}
            onClose={handleReportsPopupClose}
            onSendReport={handleReportSubmit}
          />
        )}
      </div>
    </div>
  );
});
