import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import { ConvertVideoItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaType,
  ICollaborationItemPayload,
} from 'services/collaboration/interfaces/collaboration.interface';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import {
  IGameContent,
  IPlayerContent,
} from 'stores/content-cards/interfaces/content-cards.interface';
import { POSTS_AMOUNT_BEFORE_PRE_LOAD } from 'stores/entries/constants/entries.contstants';
import { IBasePublicationAuthor } from 'stores/entries/interfaces/entries-autor.interface';
import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IHeadline } from 'stores/headlines/interfaces/headline.interface';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { IPost } from 'stores/posts/interfaces/post.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { ISharePayload } from 'containers/posts/interfaces/share-payload.interface';

import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';

import { PostsTheme } from 'components/posts/post-card/enums/posts-theme.enum';
import { PostCard } from 'components/posts/post-card/post-card.component';
import { ContentCardsGroup } from 'components/posts/posts-feed/content-cards-group/content-cards-group.component';
import { ConvertPostImageItemToAttachmentType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import { Loader } from 'components/ui/loader/loader.component';
import { IPollUpdateData, IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import { RichTextContentTypes } from 'components/ui/rich-text-preview/rich-text-preview.component';

import { PostCardFeedsTheme } from '../post-card/post-card-feeds/post-card-feeds.component';

import { FeedEmptyState } from './posts-feed-empty-state/feed-empty-state.component';
import { ShortcutHeadlines } from './shortcut-headlines/shortcut-headlines.component';
import { SHORTCUT_HEADLINES_POSITION } from './shortcut-headlines/shortcut-headlines.config';

import styles from './posts-feed.module.less';

interface IPostFeedProps {
  feedsTheme: PostCardFeedsTheme;
  headlines: IHeadline[];
  hasMore: boolean;
  team: Maybe<ITeamsStats>;
  entries: IPost[];
  fetched: boolean;
  fetching: boolean;
  isPulledRefresher: boolean;
  userId: Maybe<string>;
  isAthlete: boolean;
  visitedPostId: Maybe<string>;
  activeFeed: Maybe<FeedTypes>;
  reportReasons: Maybe<IReportReason[]>;
  gameContentCards: IGameContent[];
  playerContentCards: IPlayerContent[];
  onLoadMore: () => void;
  onDeletePost: (id: string) => void;
  onShareClick: (payload: ISharePayload) => void;
  onPollVote?: (voteData: IPollVoteData) => void;
  onPollUpdate?: (pollData: IPollUpdateData) => void;
  onPollExpired?: (postId: string) => void;
  onOpenPreview?: OpenPreviewType;
  onResetCurrentPostId?: (id: Maybe<string>) => void;
  onGetFavoriteTeam: (teamId: Maybe<number>) => Maybe<ITeamsStats>;
  onGetFavoritePlayer: (playerId: Maybe<number>) => Maybe<IPlayerStats>;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  onCloseReportsPopup: (postId: string) => void;
  onTogglePostToBookmarks: (postId: string) => void;
  onSendReport?: (postId: string, reasonId: number) => Promise<boolean>;
  onLikeClick?: (postId: string) => Promise<void>;
  onOpenUserDetailsPopUp?: (userData: IBasePublicationAuthor) => void;
  postProcessingId: Maybe<string>;
  convertImageItemToAttachment: ConvertPostImageItemToAttachmentType;
  onCreateCollaborationItem: (payload: ICollaborationItemPayload) => void;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  collaborationMediaItem: Maybe<CollaborationMediaType>;
  setCollaborationMediaItem: (value: Maybe<CollaborationMediaType>) => void;
  isCollaborationVideoLoading?: boolean;
  isCurrentUserStaff: boolean;
  requestReasons: () => void;
}

export const PostsFeed: FC<IPostFeedProps> = memo((props: IPostFeedProps) => {
  const {
    feedsTheme,
    headlines,
    userId,
    isAthlete,
    activeFeed,
    hasMore,
    entries,
    team,
    fetched,
    fetching,
    reportReasons,
    isPulledRefresher,
    gameContentCards,
    playerContentCards,
    onShareClick,
    onLoadMore,
    onGetFavoriteTeam,
    onGetFavoritePlayer,
    isCurrentUserStaff,
  } = props;

  const navigate = useNavigate();

  const isPlayerFeed = useMemo(() => activeFeed === FeedTypes.Player, [activeFeed]);
  const isYourFeed = useMemo(() => activeFeed === FeedTypes.YourFeed, [activeFeed]);

  const setIntersectionObserver = useInfiniteScroll(hasMore, onLoadMore);

  const shouldShowEmptyState = useMemo(() => {
    if (!fetched) {
      return false;
    }

    if (activeFeed === FeedTypes.GameVideos) {
      return false;
    }

    return !entries.length;
  }, [entries.length, fetched, activeFeed]);

  const hasContentCards = useMemo(
    () => !!gameContentCards.length || !!playerContentCards.length,
    [gameContentCards, playerContentCards],
  );

  const isDeafLoading = useMemo(
    () => fetching && !!entries.length && !isPulledRefresher,
    [fetching, entries, isPulledRefresher],
  );

  const isFullRefreshLoader = useMemo(
    () => !fetched && !entries.length && !hasContentCards,
    [fetched, entries, hasContentCards],
  );

  const postFeedCardsClasses = useMemo(
    () =>
      cn(styles.PostsFeed__Cards, {
        [styles['PostsFeed__Cards--is-height']]: !fetched && !hasContentCards,
        [styles['PostsFeed__Cards--player-feed']]: isPlayerFeed,
      }),
    [fetched, hasContentCards, isPlayerFeed],
  );

  const handleShowModal = useCallback(
    (payload: ISharePayload) => () => {
      onShareClick(payload);
    },
    [onShareClick],
  );

  const handleGameContentClick = useCallback(
    (gameId: string, teamsName: string, gameDate: string) => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.YourFeedLiveTeam,
          team: teamsName,
          game_date: gameDate,
        },
      });

      navigate(
        getPath(paths.ARENA_GAME, {
          [paths.GAME_ID_PARAM]: gameId,
        }),
      );
    },
    [navigate],
  );

  const handlePlayerContentClick = useCallback(
    (slug: string, gameId: string, playerName: string, gameDate: string) => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.YourFeedLivePlayer,
          player: playerName,
          game_date: gameDate,
        },
      });

      navigate(
        getPath(paths.PLAYER_PERFORMANCE, {
          [paths.PLAYER_SLUG_PARAM]: slug,
          [paths.GAME_ID_PARAM]: gameId,
        }),
      );
    },
    [navigate],
  );

  return (
    <div className={postFeedCardsClasses}>
      {!isPlayerFeed && <Loader isShow={isDeafLoading} isFeedRefresh />}
      <Loader isShow={isFullRefreshLoader} />
      <ContentCardsGroup
        activeFeed={activeFeed}
        contentGames={gameContentCards}
        contentPlayers={playerContentCards}
        onGameContentClick={handleGameContentClick}
        onPlayerContentClick={handlePlayerContentClick}
      />
      {shouldShowEmptyState ? (
        <FeedEmptyState activeFeed={activeFeed} />
      ) : (
        entries.map((post: IPost, index: number) => (
          <div
            key={post.uuid}
            ref={
              entries.length < index + POSTS_AMOUNT_BEFORE_PRE_LOAD
                ? setIntersectionObserver
                : undefined
            }
            className={styles.PostsFeed__Card}
          >
            <PostCard
              isCurrentUserStaff={isCurrentUserStaff}
              isFirstPost={index === 0}
              requestReasons={props.requestReasons}
              onOpenUserDetailsPopUp={props.onOpenUserDetailsPopUp}
              onLikesClick={props.onLikeClick}
              feedsTheme={feedsTheme}
              theme={PostsTheme.Regular}
              editorType={RichTextContentTypes.WithReadMore}
              isPulledRefresher={isPulledRefresher}
              userId={userId}
              isAthlete={isAthlete}
              team={team}
              activeFeed={activeFeed}
              isBookmarked={post.isBookmarked}
              userInfo={post.user}
              reportReasons={reportReasons}
              postInfo={{
                defaultFeedTitle: post.defaultFeedTitle,
                actionsList: post.actionsList,
                collaboration: post.collaboration || null,
                isLiked: post.isLiked,
                isLikedByAthlete: post.isLikedByAthlete,
                isCommentedByAthlete: post.isCommentedByAthlete,
                athleteInteractionDescription: post.athleteInteractionDescription,
                likes: post.likes,
                isCommentsAllowed: post.isCommentsAllowed,
                embeddedLink: post.embeddedLink,
                bookmarksCount: post.bookmarksCount,
                commentsCount: post.commentsAmount,
                id: post.uuid,
                createdDates: post.formattedDates,
                content: post.content,
                sharesCount: post.sharesCount,
                title: post.title,
                attachments: post.attachments,
                source: post.source,
                feeds: post.feeds,
              }}
              favoriteTeam={onGetFavoriteTeam(post.user.favoriteTeamId)}
              favoritePlayer={onGetFavoritePlayer(post.user.favoritePlayerId)}
              onOpenPreview={props.onOpenPreview}
              onDeletePost={props.onDeletePost}
              onTogglePostToBookmarks={props.onTogglePostToBookmarks}
              onShareClick={handleShowModal({
                postId: post.uuid,
              })}
              onPollVote={props.onPollVote}
              onPollUpdate={props.onPollUpdate}
              onPollExpired={props.onPollExpired}
              visitedPostId={props.visitedPostId}
              onResetCurrentPostId={props.onResetCurrentPostId}
              onTeamClick={props.onTeamClick}
              onPlayerClick={props.onPlayerClick}
              onSendReport={props.onSendReport}
              onCloseReportsPopup={props.onCloseReportsPopup}
              postProcessingId={props.postProcessingId}
              convertImageItemToAttachment={props.convertImageItemToAttachment}
              onCreateCollaborationItem={props.onCreateCollaborationItem}
              convertVideoItemToAttachment={props.convertVideoItemToAttachment}
              collaborationMediaItem={props.collaborationMediaItem}
              setCollaborationMediaItem={props.setCollaborationMediaItem}
              isCollaborationVideoLoading={props.isCollaborationVideoLoading}
            />
            {isYourFeed && SHORTCUT_HEADLINES_POSITION === index && (
              <ShortcutHeadlines headlines={headlines} />
            )}
          </div>
        ))
      )}
    </div>
  );
});
