import { inject, injectable } from 'inversify';
import { action, autorun, makeObservable, observable, reaction } from 'mobx';

import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';
import { IPostResponse } from 'services/posts/interfaces/posts-response.interface';
import { PostsService } from 'services/posts/posts.service';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { topPlayersAdapter } from 'stores/player/adapters/top-players.adapter';
import { ITopPlayerItem } from 'stores/player/interfaces/top-player-item.interface';
import { publicationAdapter } from 'stores/posts/adapters/publication-adapter.util';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { IPost, IPostAdapter } from 'stores/posts/interfaces/post.interface';
import { IPostsResponse } from 'stores/posts/interfaces/post-response.inerface';

import { TYPES } from 'configs/di-types.config';

import { PAGINATION_LIMIT } from './feeds.config';

@injectable()
export class YourFeedStore extends AdvancedEntriesStore<IPost, IPostResponse, IPostsResponse> {
  private readonly postsService: PostsService;

  private readonly layoutStore: LayoutStore;

  public athletesFeeds: ITopPlayerItem[];

  constructor(
    @inject(TYPES.PostsService) postsService: PostsService,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
  ) {
    super(PAGINATION_LIMIT);

    this.postsService = postsService;

    this.layoutStore = layoutStore;

    this.athletesFeeds = [];

    makeObservable(this, {
      athletesFeeds: observable,

      fetchAthletesFeeds: action.bound,
      setAthletesFeeds: action.bound,
    });

    reaction(
      () => this.layoutStore.activeFeed,
      () => this.forceFetchToRefresh(),
    );

    autorun(() => this.fetchAthletesFeeds());
  }

  public async fetchNext(): Promise<void> {
    if (this.layoutStore.activeFeed === FeedTypes.YourFeed) {
      await this.retrieveNext(this.fetchYourFeedPosts(), <IPostAdapter>publicationAdapter);
    }
  }

  public async fetchYourFeedPosts(): Promise<IResponse<IPostsResponse>> {
    return this.postsService.fetchPosts(this.pagination, true, false, null);
  }

  public async forceFetchToRefresh() {
    if (this.layoutStore.activeFeed === FeedTypes.YourFeed) {
      await this.refresh();

      await this.forceRefresh(
        this.fetchYourFeedPosts(),
        <IPostAdapter>publicationAdapter,
        this.layoutStore.setPulledRefresher,
      );
    }
  }

  public async refresh() {
    this.setFetched(false);
    this.setFetching(false);

    this.setIsLastPage(false);
    this.setCurrentPage(1);

    await this.initialise();
  }

  public setAthletesFeeds(items: ITopPlayerItem[]) {
    this.athletesFeeds = items;
  }

  public async fetchAthletesFeeds() {
    const response = await this.postsService.fetchFeeds(PostsFeedType.Player, true);

    if (response.success) {
      this.setAthletesFeeds(topPlayersAdapter(response.data.items));
    }
  }
}
