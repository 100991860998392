import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { YourFeedStore } from 'stores/feeds/your-feed.store';
import { PlayerStore } from 'stores/player/player.store';

import { TYPES } from 'configs/di-types.config';
import { IS_ENABLE_OTHER_DIGITS_PLAYERS } from 'configs/feature.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { FeedListBoard } from 'components/feed-list-board/feed-list-board.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './player-profile-feed-board.module.less';

export const PlayerProfileFeedBoardContainer = observer(() => {
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const yourFeedStore = useInjection<YourFeedStore>(TYPES.YourFeedStore);

  const navigate = useNavigate();

  const handleBoardItemClick = useCallback(
    (playerSlug: string, playerName?: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: playerSlug }));

      if (playerName && playerStore.playerDetails) {
        const { firstname, lastname } = playerStore.playerDetails;

        GoogleAnalyticService.event({
          eventName: 'button_custom_tap',
          eventParams: {
            button_tap_type: ButtonTapsEnum.PlayerProfileFeedTopPlayer,
            player: `${firstname} ${lastname}`,
            entry_player: playerName,
          },
        });
      }
    },
    [playerStore.playerDetails, navigate],
  );

  useEffect(() => {
    if (playerStore.playerDetails) {
      playerStore.fetchTopPlayers();
    }
  }, [playerStore.playerDetails, playerStore]);

  if (!playerStore.topPlayers.length || !playerStore.playerDetails) {
    return (
      <div className={styles.LoaderWrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <div className={styles.PlayerProfileFeedBoard}>
      {IS_ENABLE_OTHER_DIGITS_PLAYERS && (
        <FeedListBoard
          title="Digits Players"
          items={yourFeedStore.athletesFeeds}
          onClick={handleBoardItemClick}
        />
      )}
      <FeedListBoard
        title="View Other Players"
        expandText="See all players"
        items={playerStore.topPlayers}
        onClick={handleBoardItemClick}
      />
    </div>
  );
});
