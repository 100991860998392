import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { addDays, format } from 'date-fns';
import { gameDetailedAdapter } from 'query-hooks/scoreboard/adapters/game-detailed-adapter.util';
import { scoreboardQueryKeys } from 'query-hooks/scoreboard/constants/query-keys.constants';
import { IGameDetailed } from 'query-hooks/scoreboard/interfaces/game-detailed.interface';

import { GamesDetailedService } from 'services/games-detailed/games-detailed.service';

import { statusWeights } from 'stores/statistics/adapters/games-adapter.util';

import { DATE_FORMAT } from 'configs/date.config';
import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const CACHE_TIME = 60 * 1000; // 60 seconds

export const retrieveScoreboardData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof scoreboardQueryKeys)['gamesDetailed']>>): Promise<
  IGameDetailed[]
> => {
  const gamesDetailedService = container.get<GamesDetailedService>(TYPES.GamesDetailedService);
  const [, { timezone, startDate, endDate, teamId }] = queryKey;

  const gamesDetailedResponse = teamId
    ? await gamesDetailedService.retrieveForTeam(+teamId, startDate, timezone, endDate)
    : await gamesDetailedService.retrieve(startDate, timezone, endDate);

  if (gamesDetailedResponse.success) {
    const newGames = gamesDetailedResponse.data.map(gameDetailedAdapter);
    return newGames.sort((a, b) => statusWeights[b.status] - statusWeights[a.status]);
  }

  return Promise.reject(gamesDetailedResponse.errors);
};

export const useScoreboardDataQuery = (
  activeDate: Maybe<Date>,
  timezone: string,
  teamId?: string,
) => {
  const startDate = activeDate ? format(activeDate, DATE_FORMAT) : '';
  const endDate = activeDate ? format(addDays(activeDate, 6), DATE_FORMAT) : '';

  return useQuery({
    queryKey: scoreboardQueryKeys.gamesDetailed({ timezone, startDate, endDate, teamId }),
    queryFn: retrieveScoreboardData,
    enabled: !!activeDate,
    gcTime: CACHE_TIME,
    staleTime: CACHE_TIME,
  });
};
