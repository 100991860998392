import { memo, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { ICollaborationItem } from 'services/collaboration/interfaces/collaboration.interface';

import { IBasePublicationAuthor } from 'stores/entries/interfaces/entries-autor.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { CollaborationItemHeader } from 'components/collaboration-section/collaborattion-item/collaboration-item-header/collaboration-item-header.component';
import { PostsTheme } from 'components/posts/post-card/enums/posts-theme.enum';
import { PostCardActionsBar } from 'components/posts/post-card/post-card-actions-bar/post-card-actions-bar.component';
import {
  Video,
  VideoSize,
} from 'components/ui/images-carousel-preview/components/video/video.component';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';

import styles from './collaboration-item.module.less';

interface ICollaborationItemProps {
  item: ICollaborationItem;
  theme: PostsTheme;
  isAllowedItemDelete: boolean;
  isPulledRefresher?: boolean;
  userInfo: IBasePublicationAuthor;
  favoriteTeam: Maybe<ITeamsStats>;
  favoritePlayer: Maybe<IPlayerStats>;
  onShareClick?: () => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  onToggleBookmarks: () => void;
  onLikesClick?: () => void;
  onDeleteItem?: () => void;
  isLikeEnabled: boolean;
  isAthlete: boolean;
}

export const CollaborationItem = memo((props: ICollaborationItemProps) => {
  const {
    item,
    theme,
    isAllowedItemDelete,
    isPulledRefresher = false,
    userInfo,
    favoriteTeam,
    favoritePlayer,
    onToggleBookmarks,
    onDeleteItem,
    isLikeEnabled,
    isAthlete,
  } = props;

  const collaborationItemClasses = useMemo(
    () =>
      cn(styles.CollaborationItem, {
        [styles['CollaborationItem--freezed']]: isPulledRefresher,
        [styles['CollaborationItem--single-title']]: !item.textContent,
      }),
    [isPulledRefresher, item.textContent],
  );

  const [isNeedCloseTooltip, setIsNeedCloseTooltip] = useState(true);

  const handleContextMenuClick = useCallback(
    (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'bookmark') {
        onToggleBookmarks();
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'delete') {
        onDeleteItem?.();
      }

      setIsNeedCloseTooltip(true);
    },
    [onToggleBookmarks, onDeleteItem],
  );

  return (
    <div className={collaborationItemClasses}>
      <div className={styles.CollaborationItem__Wrapper}>
        <CollaborationItemHeader
          isAllowedItemDelete={isAllowedItemDelete}
          isNeedCloseTooltip={isNeedCloseTooltip}
          setIsNeedCloseTooltip={setIsNeedCloseTooltip}
          athlete={userInfo.athlete}
          createdDates={item.createdAt}
          userSourceAvatar={item.user.smallAvatarUrl}
          userSourceUsername={item.user.username}
          favoriteTeam={favoriteTeam}
          favoritePlayer={favoritePlayer}
          onTeamClick={props.onTeamClick}
          onPlayerClick={props.onPlayerClick}
          onContextMenuClick={handleContextMenuClick}
          theme={theme}
        />
        {item.textContent && (
          <div className={styles.CollaborationItem__TextContent}>
            <RichTextPreview
              id={item.id.toString()}
              editorType={RichTextContentTypes.FullHeight}
              editorState={item.textContent}
            />
          </div>
        )}
        {item.image && (
          <div className={styles.CollaborationItem__Image}>
            <img src={item.image.largeLogoUrl} alt="attach" />
          </div>
        )}
        {item.video && (
          <div className={styles.CollaborationItem__Video}>
            <Video size={VideoSize.FULL} isMuted isAutoplay type="" url={item.video.url} />
          </div>
        )}
      </div>
      {theme !== PostsTheme.Bookmark && (
        <PostCardActionsBar
          isAthlete={isAthlete}
          isLikeEnabled={isLikeEnabled}
          isCommentsAllowed
          isLiked={item.isLiked}
          theme={PostsTheme.Detail}
          onLikesClick={props.onLikesClick}
          likes={item.likesTotal}
          isBookmarked={item.isBookmarked}
          bookmarksCount={item.bookmarksTotal}
          commentsCount={item.commentsTotal}
          sharesCount={item.sharesTotal}
          onBookmarkClick={props.onToggleBookmarks}
          onShareClick={props.onShareClick}
        />
      )}
    </div>
  );
});
