import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IGetCommentPayload } from 'services/comments/interfaces/comments.interface';
import { ITogglePostCommentBookmarkPayload } from 'services/posts/interfaces/create-post-payload.interface';

import { BookmarkType } from 'stores/bookmarks/enums/bookmark-post-type.enum';
import { IBookmarkedCollaborationItem } from 'stores/bookmarks/interfaces/bookmarks.interface';
import { BookmarksType } from 'stores/bookmarks/types/bookmarks.type';
import { IComment } from 'stores/comments/interfaces/comments.interface';
import { POSTS_AMOUNT_BEFORE_PRE_LOAD } from 'stores/entries/constants/entries.contstants';
import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IPost } from 'stores/posts/interfaces/post.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { ISharePayload } from 'containers/posts/interfaces/share-payload.interface';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';

import { CollaborationItem } from 'components/collaboration-section/collaborattion-item/collaboration-item';
import { CommentSmall } from 'components/comment/comment-small/comment-small.component';
import { PostsTheme } from 'components/posts/post-card/enums/posts-theme.enum';
import { PostCard } from 'components/posts/post-card/post-card.component';
import { PostCardFeedsTheme } from 'components/posts/post-card/post-card-feeds/post-card-feeds.component';
import { IPollUpdateData, IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import { RichTextContentTypes } from 'components/ui/rich-text-preview/rich-text-preview.component';

import styles from './bookmarks-list.module.less';

interface IBookmarksListProps {
  isForSidePanel: boolean;
  userId: Maybe<string>;
  isAthlete: boolean;
  hasMore: boolean;
  feedsTheme: PostCardFeedsTheme;
  bookmarks: Array<BookmarksType>;
  onTogglePostBookmark: (id: string, teamId?: Maybe<number>, playerId?: Maybe<number>) => void;
  onToggleCommentBookmark: (payload: ITogglePostCommentBookmarkPayload) => void;
  onPollVote: (voteData: IPollVoteData) => void;
  onPollUpdate: (pollData: IPollUpdateData) => void;
  onPollExpired: (postId: string) => void;
  onLoadMore: () => void;
  onOpenComment: (payload: IGetCommentPayload) => void;
  onShareClick: (payload: ISharePayload) => void;
  onOpenPreview: OpenPreviewType;
  onCloseBookmarks: () => void;
  onGetFavoriteTeam: (teamId: Maybe<number>) => Maybe<ITeamsStats>;
  onGetFavoritePlayer: (playerId: Maybe<number>) => Maybe<IPlayerStats>;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  onToggleCollaborationItemBookmark: (collaborationId: number, collaborationItemId: number) => void;
}

export const BookmarkList = memo((props: IBookmarksListProps) => {
  const {
    isForSidePanel,
    userId,
    isAthlete,
    hasMore,
    feedsTheme,
    bookmarks,
    onLoadMore,
    onShareClick,
    onGetFavoriteTeam,
    onGetFavoritePlayer,
    onToggleCollaborationItemBookmark,
  } = props;

  const handleSharePostClick = useCallback(
    (postId: string, payload: IPost | IComment, commentId?: string) => () => {
      onShareClick({
        postId,
        commentId,
        publication: {
          uuid: payload.uuid,
          formattedDates: payload.formattedDates,
          user: {
            username: payload.user.username,
            name: payload.user.name,
            smallAvatarUrl: payload.user.smallAvatarUrl,
          },
        },
      });
    },
    [onShareClick],
  );

  const handleBookmarkCollaborationItem = useCallback(
    (item: IBookmarkedCollaborationItem) => () => {
      if (item.collaborationItem.collaborationParentId) {
        onToggleCollaborationItemBookmark(
          item.collaborationItem.collaborationParentId,
          item.collaborationItem.id,
        );
      }
    },
    [onToggleCollaborationItemBookmark],
  );

  const setIntersectionObserver = useInfiniteScroll(hasMore, onLoadMore);

  const wrapperClassNames = useMemo(
    () => cn(styles.Wrapper, { [styles['Wrapper--for-side-panel']]: isForSidePanel }),
    [isForSidePanel],
  );

  const renderBookmarks = () => {
    return bookmarks.map((item, index: number) => {
      if (item.type === BookmarkType.Post) {
        return (
          <div
            key={item.uuid}
            className={styles.Wrapper__Item}
            ref={
              bookmarks.length === index + POSTS_AMOUNT_BEFORE_PRE_LOAD
                ? setIntersectionObserver
                : undefined
            }
          >
            <PostCard
              feedsTheme={feedsTheme}
              theme={PostsTheme.Bookmark}
              editorType={RichTextContentTypes.Truncated}
              userId={userId}
              isAthlete={isAthlete}
              team={null}
              isForSidePanel={isForSidePanel}
              isBookmarked={item.post.isBookmarked}
              userInfo={item.post.user}
              postInfo={{
                collaboration: null,
                isLiked: item.post.isLiked,
                isLikedByAthlete: item.post.isLikedByAthlete,
                isCommentedByAthlete: item.post.isCommentedByAthlete,
                likes: item.post.likes,
                isCommentsAllowed: item.post.isCommentsAllowed,
                id: item.post.uuid,
                embeddedLink: item.post.embeddedLink,
                bookmarksCount: item.post.bookmarksCount,
                commentsCount: item.post.commentsAmount,
                createdDates: item.post.formattedDates,
                content: item.post.content,
                sharesCount: item.post.sharesCount,
                title: item.post.title,
                attachments: item.post.attachments,
                source: item.post.source,
                feeds: item.post.feeds,
              }}
              bookmarkDate={item.bookmarkDate}
              favoriteTeam={onGetFavoriteTeam(item.post.user.favoriteTeamId)}
              favoritePlayer={onGetFavoritePlayer(item.post.user.favoritePlayerId)}
              onTogglePostToBookmarks={props.onTogglePostBookmark}
              onShareClick={handleSharePostClick(item.post.uuid, item.post, undefined)}
              onPollVote={props.onPollVote}
              onPollUpdate={props.onPollUpdate}
              onPollExpired={props.onPollExpired}
              onOpenPreview={props.onOpenPreview}
              onCloseBookmarks={props.onCloseBookmarks}
              onTeamClick={props.onTeamClick}
              onPlayerClick={props.onPlayerClick}
            />
          </div>
        );
      }

      if (item.type === BookmarkType.Comment) {
        return (
          <div
            key={item.uuid}
            className={styles.Wrapper__Item}
            ref={
              bookmarks.length === index + POSTS_AMOUNT_BEFORE_PRE_LOAD
                ? setIntersectionObserver
                : undefined
            }
          >
            <CommentSmall
              onOpenComment={props.onOpenComment}
              postTitle={item.postTitle}
              key={item.uuid}
              id={item.comment.uuid}
              isBookmarked
              postId={item.postId}
              bookmarkDate={item.bookmarkDate}
              commentAuthor={{
                uuid: item.comment.user.uuid,
                username: item.comment.user.username,
                favoriteTeam: onGetFavoriteTeam(item.comment.user.favoriteTeamId),
                favoritePlayer: onGetFavoritePlayer(item.comment.user.favoritePlayerId),
                smallAvatarUrl: item.comment.user.smallAvatarUrl,
              }}
              commentData={{
                comment: item.comment.content,
                createAt: isForSidePanel
                  ? item.comment.formattedDates.relativeShort
                  : item.comment.formattedDates.relativeLong,
              }}
              onBookmarkClick={props.onToggleCommentBookmark}
              attachments={item.comment.attachments}
              onPollVote={props.onPollVote}
              onPollExpired={props.onPollExpired}
              onShareClick={handleSharePostClick(item.postId, item.comment, item.comment.uuid)}
              onOpenPreview={props.onOpenPreview}
              onCloseBookmarks={props.onCloseBookmarks}
              onTeamClick={props.onTeamClick}
              onPlayerClick={props.onPlayerClick}
            />
          </div>
        );
      }

      if (item.type === BookmarkType.CollaborationItem) {
        return (
          <div
            key={item.uuid}
            className={styles.Wrapper__Item}
            ref={
              bookmarks.length === index + POSTS_AMOUNT_BEFORE_PRE_LOAD
                ? setIntersectionObserver
                : undefined
            }
          >
            <CollaborationItem
              isAthlete={isAthlete}
              isLikeEnabled={false}
              isAllowedItemDelete={false}
              item={item.collaborationItem}
              theme={PostsTheme.Bookmark}
              userInfo={item.collaborationItem.user}
              favoriteTeam={null}
              favoritePlayer={null}
              onTeamClick={props.onTeamClick}
              onPlayerClick={props.onPlayerClick}
              onToggleBookmarks={handleBookmarkCollaborationItem(item)}
            />
          </div>
        );
      }

      return null;
    });
  };

  return bookmarks.length ? <div className={wrapperClassNames}>{renderBookmarks()}</div> : null;
});
