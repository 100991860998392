import { useCallback } from 'react';

import { ConvertVideoItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaType,
  ICollaborationItemPayload,
} from 'services/collaboration/interfaces/collaboration.interface';
import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IPost } from 'stores/posts/interfaces/post.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { IAuthor } from 'components/modals/share-modal/share-modal.component';
import { PostsTheme } from 'components/posts/post-card/enums/posts-theme.enum';
import { PostCard } from 'components/posts/post-card/post-card.component';
import { ConvertPostImageItemToAttachmentType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import { IPollUpdateData, IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import { RichTextContentTypes } from 'components/ui/rich-text-preview/rich-text-preview.component';

import { IFormattedDates } from '../post-card/interfaces/post-info.interface';
import { PostCardFeedsTheme } from '../post-card/post-card-feeds/post-card-feeds.component';

export interface ISharePublicationData {
  user: IAuthor;
  formattedDates: IFormattedDates;
  uuid?: string;
}

interface IPostDetailedProps {
  isCurrentUserStaff: boolean;
  isPostDetails: boolean;
  feedsTheme: PostCardFeedsTheme;
  team: Maybe<ITeamsStats>;
  post: IPost;
  favoriteTeam: Maybe<ITeamsStats>;
  favoritePlayer: Maybe<IPlayerStats>;
  reportReasons: Maybe<IReportReason[]>;
  userId: Maybe<string>;
  isAthlete: boolean;
  onTogglePostToBookmarks: (id: string) => void;
  onShareClick: (comment?: ISharePublicationData) => void;
  onPollVote: (voteData: IPollVoteData) => void;
  onPollUpdate: (pollData: IPollUpdateData) => void;
  onPollExpired: (postId: string) => void;
  onOpenPreview: OpenPreviewType;
  onDeletePost: (id: string) => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  onCloseReportsPopup: (postId: string) => void;
  onSendReport?: (
    postId: string,
    reasonId: number,
    teamId?: number,
    playerId?: number,
  ) => Promise<boolean>;
  onLikesClick: (
    postIdParam: string,
    teamIdParam?: number,
    playerIdParam?: number,
  ) => Promise<void>;
  postProcessingId?: Maybe<string>;
  convertImageItemToAttachment?: ConvertPostImageItemToAttachmentType;
  onCreateCollaborationItem?: (payload: ICollaborationItemPayload) => void;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  collaborationMediaItem: Maybe<CollaborationMediaType>;
  setCollaborationMediaItem: (value: Maybe<CollaborationMediaType>) => void;
  isCollaborationVideoLoading: boolean;
  requestReasons: () => void;
}

export const PostDetailed = (props: IPostDetailedProps) => {
  const {
    feedsTheme,
    userId,
    isAthlete,
    team,
    post,
    favoriteTeam,
    favoritePlayer,
    reportReasons,
    onShareClick,
    isPostDetails,
    postProcessingId,
    isCurrentUserStaff,
  } = props;

  const handleShowModal = useCallback(
    (comment?: ISharePublicationData) => () => {
      onShareClick(comment);
    },
    [onShareClick],
  );

  return (
    <PostCard
      isCurrentUserStaff={isCurrentUserStaff}
      isCollaborationVideoLoading={props.isCollaborationVideoLoading}
      postProcessingId={postProcessingId}
      convertVideoItemToAttachment={props.convertVideoItemToAttachment}
      convertImageItemToAttachment={props.convertImageItemToAttachment}
      onCreateCollaborationItem={props.onCreateCollaborationItem}
      isPostDetails={isPostDetails}
      feedsTheme={feedsTheme}
      theme={PostsTheme.Detail}
      editorType={RichTextContentTypes.FullHeight}
      isLinksClickable
      userId={userId}
      isAthlete={isAthlete}
      team={team}
      isBookmarked={post.isBookmarked}
      userInfo={post.user}
      favoriteTeam={favoriteTeam}
      favoritePlayer={favoritePlayer}
      reportReasons={reportReasons}
      postInfo={{
        isLikedByAthlete: post.isLikedByAthlete,
        isCommentedByAthlete: post.isCommentedByAthlete,
        athleteInteractionDescription: post.athleteInteractionDescription,
        collaboration: post.collaboration || null,
        isLiked: post.isLiked,
        likes: post.likes,
        isCommentsAllowed: post.isCommentsAllowed,
        embeddedLink: post.embeddedLink,
        bookmarksCount: post.bookmarksCount,
        commentsCount: post.commentsAmount,
        id: post.uuid,
        createdDates: post.formattedDates,
        content: post.content,
        sharesCount: post.sharesCount,
        title: post.title,
        attachments: post.attachments,
        source: post.source,
        feeds: post.feeds,
      }}
      onLikesClick={props.onLikesClick}
      onDeletePost={props.onDeletePost}
      onOpenPreview={props.onOpenPreview}
      onTogglePostToBookmarks={props.onTogglePostToBookmarks}
      onShareClick={handleShowModal()}
      onPollVote={props.onPollVote}
      onPollUpdate={props.onPollUpdate}
      onPollExpired={props.onPollExpired}
      onTeamClick={props.onTeamClick}
      onPlayerClick={props.onPlayerClick}
      onSendReport={props.onSendReport}
      onCloseReportsPopup={props.onCloseReportsPopup}
      collaborationMediaItem={props.collaborationMediaItem}
      setCollaborationMediaItem={props.setCollaborationMediaItem}
      requestReasons={props.requestReasons}
    />
  );
};
