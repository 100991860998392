import { memo, useEffect, useRef } from 'react';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';

import {
  ConvertImageItemToAttachmentType,
  ConvertVideoItemToAttachmentType,
} from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaEnum,
  CollaborationMediaType,
} from 'services/collaboration/interfaces/collaboration.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { editorLogger } from 'loggers/editor.logger';

import { IHookFormInput } from 'components/ui/form-fields/hook-form-input.interface';

import {
  BaseEditor,
  EditorDataType,
  EditorLoadDataType,
  EditorTheme,
  EditorType,
} from './components/base-editor/base-editor.component';
import NodeList from './nodes/nodes.list';
import editorTheme from './themes/basic-theme';

import styles from './editor.module.less';

interface IEditorProps extends IHookFormInput<EditorDataType> {
  editorType?: EditorType;
  postId?: string;
  disableAttachments?: boolean;
  placeholder?: string;
  isFullHeight?: boolean;
  isEnabledAutoScrolling?: boolean;
  isHighlighted?: boolean;
  isExpand?: boolean;
  isActive?: boolean;
  isTreeViewEnabled?: boolean;
  isSendHidden?: boolean;
  theme?: EditorTheme;
  loadingImages?: string[];
  isNeedForceExpand?: boolean;
  isVideoLoading?: boolean;
  postVideo?: Maybe<IVideoResponse>;
  setLoadingImages?: (value: string[]) => void;
  onEditorFocus?: () => void;
  onBlur?: () => void;
  onLoad?: (editor: EditorLoadDataType) => void;
  onUploadVideo?: (video: File) => void;
  onRemoveVideo?: () => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  mediaType?: Maybe<CollaborationMediaEnum>;
  collaborationMediaItem?: Maybe<CollaborationMediaType>;
  setCollaborationMediaItem?: (value: Maybe<CollaborationMediaType>) => void;
  isCollaborationVideoLoading?: boolean;
  isCollaborationImageLoading?: boolean;
}

function onError(error: Error) {
  editorLogger.error({ msg: error.message });
}

export const Editor = memo((props: IEditorProps) => {
  const {
    editorType,
    theme = EditorTheme.Light,
    disabled = false,
    isSendHidden = false,
    isEnabledAutoScrolling = false,
    isFullHeight = false,
    isHighlighted = false,
    isExpand = false,
    isActive = false,
    isTreeViewEnabled = false,
    isNeedForceExpand = false,
    postVideo = null,
    isVideoLoading = false,
    disableAttachments = false,
    placeholder,
    error,
    value,
    mediaType,
    collaborationMediaItem,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDesktopPlus && isActive && isEnabledAutoScrolling) {
      editorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isActive, isExpand, isEnabledAutoScrolling, isDesktopPlus]);

  const initialContent = value?.content?.value ?? null;
  const lexicalInitialConfig: InitialConfigType = {
    namespace: 'Base Editor',
    nodes: [...NodeList],
    theme: editorTheme,
    onError,
    ...(initialContent && { editorState: initialContent }),
  };

  return (
    <div ref={editorRef} className={styles.Editor}>
      <LexicalComposer initialConfig={lexicalInitialConfig}>
        <BaseEditor
          editorType={editorType}
          postId={props.postId}
          disableAttachments={disableAttachments}
          theme={theme}
          editorData={value}
          loadingImages={props.loadingImages}
          postVideo={postVideo}
          isVideoLoading={isVideoLoading}
          disabled={disabled}
          isNeedForceExpand={isNeedForceExpand}
          placeholder={placeholder}
          isFullHeight={isFullHeight}
          isHighlighted={isHighlighted}
          isExpand={isExpand}
          isActive={isActive}
          isSendHidden={isSendHidden}
          isTreeViewEnabled={isTreeViewEnabled}
          onBlur={props.onBlur}
          onLoad={props.onLoad}
          onChange={props.onChange}
          onEditorFocus={props.onEditorFocus}
          onUploadVideo={props.onUploadVideo}
          onRemoveVideo={props.onRemoveVideo}
          setLoadingImages={props.setLoadingImages}
          onVideoError={props.onVideoError}
          convertImageItemToAttachment={props.convertImageItemToAttachment}
          convertVideoItemToAttachment={props.convertVideoItemToAttachment}
          mediaType={mediaType}
          collaborationMediaItem={collaborationMediaItem}
          setCollaborationMediaItem={props.setCollaborationMediaItem}
          isCollaborationVideoLoading={props.isCollaborationVideoLoading}
          isCollaborationImageLoading={props.isCollaborationImageLoading}
        />
      </LexicalComposer>
      {error && <div className={styles.Editor__Error}>{error}</div>}
    </div>
  );
});
