import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { ContentCardsService } from 'services/content-cards/content-cards.service';
import { ContentCardsType } from 'services/content-cards/enums/content-cards-type.enum';
import {
  IGameContentDataResponse,
  IGameContentResponse,
  IPlayerContentDataResponse,
  IPlayerContentResponse,
} from 'services/content-cards/interfaces/content-cards-response.interface';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { AuthStore } from 'stores/auth/auth.store';
import { FollowStore } from 'stores/follow/follow.store';
import { IoMessage } from 'stores/io/enums/io-message.enum';
import { IoStore } from 'stores/io/io.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { PlayerStore } from 'stores/player/player.store';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { TeamsStore } from 'stores/teams/teams.store';

import { TYPES } from 'configs/di-types.config';

import {
  gameContentAdapter,
  gameContentCardsAdapter,
  playerContentAdapter,
  playerContentCardsAdapter,
} from './adapters/content-cards-adapter.util';
import { IGameContent, IPlayerContent } from './interfaces/content-cards.interface';

const GLOBAL_FEED_PLAYERS_PAGINATION_LIMIT = 3;
const PLAYER_FEED_PAGINATION_LIMIT = 1;
const GLOBAL_FEED_FAVORITES_PLAYERS_PAGINATION_LIMIT = 20;
const GLOBAL_FEED_FAVORITES_GAME_PAGINATION_LIMIT = 10;

@injectable()
export class ContentCardsStore extends ApiConnectedStore {
  private readonly ioStore: IoStore;

  private readonly contentCardsService: ContentCardsService;

  private readonly layoutStore: LayoutStore;

  private readonly teamsStore: TeamsStore;

  private readonly playerStore: PlayerStore;

  private readonly authStore: AuthStore;

  private readonly followStore: FollowStore;

  public gameContentCards: IGameContent[];

  public playerContentCards: IPlayerContent[];

  public isEnabledRealtimeConnections: boolean;

  constructor(
    @inject<IoStore>(TYPES.IoStore) ioStore: IoStore,
    @inject(TYPES.ContentCardsService) contentCardsService: ContentCardsService,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
    @inject(TYPES.PlayerStore) playerStore: PlayerStore,
    @inject(TYPES.TeamsStore) teamsStore: TeamsStore,
    @inject(TYPES.AuthStore) authStore: AuthStore,
    @inject<FollowStore>(TYPES.FollowStore) followStore: FollowStore,
  ) {
    super();

    this.ioStore = ioStore;

    this.playerStore = playerStore;

    this.teamsStore = teamsStore;

    this.contentCardsService = contentCardsService;

    this.layoutStore = layoutStore;

    this.authStore = authStore;

    this.followStore = followStore;

    this.gameContentCards = [];

    this.playerContentCards = [];

    this.isEnabledRealtimeConnections = true;

    makeObservable(this, {
      isEnabledRealtimeConnections: observable,
      gameContentCards: observable,
      playerContentCards: observable,

      setEnabledRealtimeConnections: action.bound,
      setGameContentCards: action.bound,
      setPlayerContentCards: action.bound,
    });

    reaction(
      () => [
        this.layoutStore.activeFeed,
        this.playerStore.playerDetails,
        this.playerStore.playerSlug,
        this.teamsStore.teamId,
        this.ioStore.socket,
        this.authStore.isAuthorised,
        this.authStore.isAuthorisedAnonymously,
        this.followStore.follow,
      ],
      this.refreshContentCards,
    );

    reaction(() => this.isEnabledRealtimeConnections, this.updateRealtimeConnections);
  }

  public setEnabledRealtimeConnections(isEnabledRealtimeConnections: boolean) {
    this.isEnabledRealtimeConnections = isEnabledRealtimeConnections;
  }

  public setGameContentCards(gameContentCards: IGameContent[]) {
    this.gameContentCards = gameContentCards;
  }

  public setPlayerContentCards(playerContentCards: IPlayerContent[]) {
    this.playerContentCards = playerContentCards;
  }

  private joinToGameContentCardsRoom() {
    const gameIds = this.gameContentCards.map((content) => content.gameId);

    gameIds.forEach((gameId) => {
      const roomName = `games:id:${gameId}:content-card`;

      this.ioStore.emit(IoMessage.Join, { room: roomName });

      this.ioStore.socket.on(roomName, (response: IGameContentDataResponse) => {
        this.updateGameContentCards(response, this.gameContentCards);
      });
    });
  }

  private joinToPlayerContentCardsRoom() {
    const items = this.playerContentCards.map((content) => ({
      gameId: content.gameId,
      playerSlug: content.slug,
    }));

    items.forEach((item) => {
      const roomName = `games:id:${item.gameId}:players:slug:${item.playerSlug}:content-card`;

      this.ioStore.emit(IoMessage.Join, { room: roomName });

      this.ioStore.socket.on(roomName, (response: IPlayerContentDataResponse) => {
        this.updatePlayerContentCards(response, this.playerContentCards);
      });
    });
  }

  private updateGameContentCards(
    gameContentCardResponse: IGameContentDataResponse,
    gameContentCards: IGameContent[],
  ) {
    const enteringGameContentCard = gameContentAdapter(gameContentCardResponse);

    const updatedGameContentCards = gameContentCards.map((item) => {
      if (enteringGameContentCard.gameId === item.gameId) {
        return enteringGameContentCard;
      }

      return item;
    });

    this.setGameContentCards(updatedGameContentCards);
  }

  private updatePlayerContentCards(
    playerContentCardResponse: IPlayerContentDataResponse,
    playerContentCards: IPlayerContent[],
  ) {
    const enteringPlayerContentCard = playerContentAdapter(playerContentCardResponse);

    const updatedPlayerContentCards = playerContentCards.map((item) => {
      if (enteringPlayerContentCard.playerId === item.playerId) {
        return enteringPlayerContentCard;
      }

      return item;
    });

    this.setPlayerContentCards(updatedPlayerContentCards);
  }

  private async fetchContentCards() {
    const { activeFeed } = this.layoutStore;

    if (
      FeedTypes.YourFeed === activeFeed ||
      FeedTypes.Player === activeFeed ||
      FeedTypes.GlobalFavorites === activeFeed
    ) {
      this.fetchPlayerContentCards();
      this.fetchGameContentCards();
    }

    if (FeedTypes.Team === activeFeed) {
      this.fetchGameContentCards();
    }
  }

  public refreshContentCards = async () => {
    if (this.authStore.isAuthorised || this.authStore.isAuthorisedAnonymously) {
      this.leaveFromGameContentCardsRoom();
      this.leaveFromPlayerContentCardsRoom();

      await this.fetchContentCards();

      this.joinToGameContentCardsRoom();
      this.joinToPlayerContentCardsRoom();
    }
  };

  public async fetchGameContentCards() {
    let response;

    if (
      this.layoutStore.activeFeed === FeedTypes.YourFeed ||
      this.layoutStore.activeFeed === FeedTypes.GlobalFavorites
    ) {
      response = await this.contentCardsService.fetchFavoritesContentCards<IGameContentResponse[]>(
        {
          limit: GLOBAL_FEED_FAVORITES_GAME_PAGINATION_LIMIT,
          page: 1,
        },
        ContentCardsType.Game,
      );
    }

    if (
      this.layoutStore.activeFeed === FeedTypes.Player &&
      this.playerStore.playerDetails?.team?.id
    ) {
      response = await this.contentCardsService.fetchContentCards<IGameContentResponse[]>(
        {
          limit: PLAYER_FEED_PAGINATION_LIMIT,
          page: 1,
        },
        ContentCardsType.Game,
        null,
        this.playerStore.playerDetails.team.id,
      );
    }

    if (this.layoutStore.activeFeed === FeedTypes.Team && this.teamsStore.teamId) {
      response = await this.contentCardsService.fetchContentCards<IGameContentResponse[]>(
        {
          limit: PLAYER_FEED_PAGINATION_LIMIT,
          page: 1,
        },
        ContentCardsType.Game,
        null,
        this.teamsStore.teamId,
      );
    }

    if (response?.success) {
      this.setGameContentCards(gameContentCardsAdapter(response.data.items));
    } else {
      this.setGameContentCards([]);
    }
  }

  public async fetchPlayerContentCards() {
    let response;
    if (this.layoutStore.activeFeed === FeedTypes.YourFeed) {
      response = await this.contentCardsService.fetchContentCards<IPlayerContentResponse[]>(
        {
          limit: GLOBAL_FEED_PLAYERS_PAGINATION_LIMIT,
          page: 1,
        },
        ContentCardsType.Player,
        null,
        null,
      );
    }

    if (this.layoutStore.activeFeed === FeedTypes.GlobalFavorites) {
      response = await this.contentCardsService.fetchFavoritesContentCards<
        IPlayerContentResponse[]
      >(
        {
          limit: GLOBAL_FEED_FAVORITES_PLAYERS_PAGINATION_LIMIT,
          page: 1,
        },
        ContentCardsType.Player,
      );
    }

    if (this.layoutStore.activeFeed === FeedTypes.Player && this.playerStore.playerSlug) {
      response = await this.contentCardsService.fetchContentCards<IPlayerContentResponse[]>(
        {
          limit: PLAYER_FEED_PAGINATION_LIMIT,
          page: 1,
        },
        ContentCardsType.Player,
        this.playerStore.playerSlug,
        null,
      );
    }

    if (response?.success) {
      this.setPlayerContentCards(playerContentCardsAdapter(response.data.items));
    }
  }

  private leaveFromGameContentCardsRoom() {
    const gameIds = this.gameContentCards.map((content) => content.gameId);

    gameIds.forEach((gameId) => {
      const roomName = `games:id:${gameId}:content-card`;

      this.ioStore.socket.emit('leave', { room: roomName });
    });
  }

  private leaveFromPlayerContentCardsRoom() {
    const items = this.playerContentCards.map((content) => ({
      gameId: content.gameId,
      playerSlug: content.slug,
    }));

    items.forEach((item) => {
      const roomName = `games:id:${item.gameId}:players:slug:${item.playerSlug}:content-card`;

      this.ioStore.socket.emit('leave', { room: roomName });
    });
  }

  private updateRealtimeConnections = () => {
    if (this.isEnabledRealtimeConnections) {
      this.joinToGameContentCardsRoom();
      this.joinToPlayerContentCardsRoom();
    } else {
      this.leaveFromGameContentCardsRoom();
      this.leaveFromPlayerContentCardsRoom();
    }
  };

  public reset() {
    this.leaveFromGameContentCardsRoom();
    this.leaveFromPlayerContentCardsRoom();
  }
}
