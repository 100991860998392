import { memo, useMemo } from 'react';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatBigNumbers } from 'helpers/format/format-big-numbers.utils';

import { useResponsive } from 'hooks/use-responsive';

import { AuthTooltip } from 'components/auth/auth-tooltip/auth-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import customLikeFilled from 'assets/images/svg/like-filled.svg';

import { PostsTheme } from '../enums/posts-theme.enum';

import styles from './post-card-actions-bar.module.less';

export interface IPostCardActionsBarProps {
  theme: PostsTheme;
  likes: number;
  isLiked: boolean;
  isCommentsDisabled?: boolean;
  isBookmarked: boolean;
  commentsCount: number;
  bookmarksCount: number;
  sharesCount: number;
  onBookmarkClick?: () => void;
  onLikesClick?: () => void;
  onCommentClick?: () => void;
  onShareClick?: () => void;
  isCommentsAllowed: boolean;
  isLikedByAthlete?: boolean;
  isCommentedByAthlete?: boolean;
  isLikeEnabled: boolean;
  isAthlete: boolean;
}

const ACTIVE_ICON_COLOR = '#fcb825';

export const PostCardActionsBar = memo((props: IPostCardActionsBarProps) => {
  const {
    theme,
    isLiked,
    isCommentsAllowed,
    isBookmarked,
    commentsCount,
    bookmarksCount,
    sharesCount,
    likes,
    isCommentsDisabled = false,
    isLikedByAthlete,
    isCommentedByAthlete,
    isLikeEnabled,
    isAthlete,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const actionIconSize = useMemo(() => {
    if (theme === PostsTheme.Detail || isDesktopPlus) {
      return IconFontSize.Big;
    }

    return IconFontSize.Small;
  }, [theme, isDesktopPlus]);

  const commentsCountClasses = useMemo(
    () =>
      cn(styles.PostCardActionsBar__Number, {
        [styles['PostCardActionsBar__Number--disabled']]: isCommentsDisabled,
      }),
    [isCommentsDisabled],
  );

  const userLikedClasses = useMemo(
    () =>
      cn(styles.UserLiked, {
        [styles['UserLiked--disabled']]: !isLikeEnabled,
      }),
    [isLikeEnabled],
  );

  const isShowUserLike = useMemo(() => {
    return !isAthlete ? isLiked : false;
  }, [isLiked, isAthlete]);

  return (
    <div className={styles.PostCardActionsBar}>
      <div className={styles.PostCardActionsBar__Actions}>
        <div className={styles.PostCardActionsBar__Item}>
          <AuthTooltip alignLeft={!isDesktopPlus && theme === PostsTheme.Detail}>
            <div
              className={styles.LikeWrapper}
              role="button"
              tabIndex={0}
              onClick={props.onLikesClick}
              onKeyDown={props.onLikesClick}
            >
              {isLikedByAthlete && (
                <IconButton
                  disabled={!isLikeEnabled}
                  iconName={IconFontName.LikeFiled}
                  theme={IconButtonTheme.Transparent}
                  iconSize={actionIconSize}
                  customColor={ACTIVE_ICON_COLOR}
                />
              )}
              {!isLikedByAthlete && !isShowUserLike && (
                <IconButton
                  disabled={!isLikeEnabled}
                  iconName={IconFontName.Like}
                  theme={IconButtonTheme.Transparent}
                  iconSize={actionIconSize}
                />
              )}
              {!isLikedByAthlete && isShowUserLike && (
                <IconButton
                  disabled={!isLikeEnabled}
                  iconName={IconFontName.LikeFiled}
                  theme={IconButtonTheme.Transparent}
                  iconSize={actionIconSize}
                  active
                />
              )}
              {isShowUserLike && isLikedByAthlete && (
                <img className={userLikedClasses} src={customLikeFilled} alt="CustomLike" />
              )}
            </div>
          </AuthTooltip>
          {!!likes && (
            <span
              className={cn(styles.PostCardActionsBar__Number, {
                [styles['PostCardActionsBar__Number--athlete-like']]:
                  isLikedByAthlete && isShowUserLike,
              })}
            >
              {formatBigNumbers(likes)}
            </span>
          )}
        </div>
        {isCommentsAllowed && (
          <div className={styles.PostCardActionsBar__Item}>
            <AuthTooltip>
              <IconButton
                onClick={props.onCommentClick}
                iconName={isCommentedByAthlete ? IconFontName.ChatFilled : IconFontName.Chat}
                theme={IconButtonTheme.Transparent}
                iconSize={actionIconSize}
                disabled={isCommentsDisabled}
                customColor={isCommentedByAthlete ? ACTIVE_ICON_COLOR : undefined}
              />
            </AuthTooltip>
            {!!commentsCount && (
              <span className={commentsCountClasses}>{formatBigNumbers(commentsCount)}</span>
            )}
          </div>
        )}

        <div className={styles.PostCardActionsBar__Item}>
          <AuthTooltip>
            {isBookmarked ? (
              <IconButton
                iconName={IconFontName.BookmarkFilled}
                theme={IconButtonTheme.Transparent}
                onClick={props.onBookmarkClick}
                active={isBookmarked}
                iconSize={actionIconSize}
              />
            ) : (
              <IconButton
                iconName={IconFontName.Bookmark}
                theme={IconButtonTheme.Transparent}
                onClick={props.onBookmarkClick}
                iconSize={actionIconSize}
              />
            )}
          </AuthTooltip>
          {!!bookmarksCount && (
            <span className={styles.PostCardActionsBar__Number}>
              {formatBigNumbers(bookmarksCount)}
            </span>
          )}
        </div>
        <div className={styles.PostCardActionsBar__Item}>
          <IconButton
            onClick={props.onShareClick}
            iconName={IconFontName.Share}
            theme={IconButtonTheme.Transparent}
            iconSize={actionIconSize}
          />
          {!!sharesCount && (
            <span className={styles.PostCardActionsBar__Number}>
              {formatBigNumbers(sharesCount)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
});
