import { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';

import { useMainProvider } from 'hooks/use-main-provider';

import styles from './video.module.less';

interface IVideoProps {
  url: string;
  type: string;
  isAutoplay?: boolean;
  isMuted?: boolean;
  size: VideoSize;
}

export enum VideoSize {
  S,
  M,
  L,
  FULL,
}
export const Video = (props: IVideoProps) => {
  const { size, url, type, isMuted = true, isAutoplay = false } = props;
  const videoRef = useRef<HTMLVideoElement>(null);

  const { isNativeApp } = useMainProvider();

  useEffect(() => {
    const options = {
      rootMargin: '0px',
      threshold: [0.25, 0.75],
    };

    const handlePlay = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (!videoRef?.current) return;

        if (entry.isIntersecting && videoRef.current.readyState === 4) {
          videoRef.current.play();

          if (isNativeApp) {
            videoRef.current.controls = false;
          }
        } else {
          if (videoRef.current.paused) return;

          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handlePlay, options);

    if (videoRef?.current) {
      observer.observe(videoRef?.current);
    }
  }, [isNativeApp]);

  useEffect(() => {
    const video = videoRef?.current;

    const listener = () => {
      if (videoRef.current) {
        videoRef.current.controls = true;
      }
    };

    if (video && isNativeApp) {
      video.addEventListener('click', listener);
    }

    return () => {
      if (video && isNativeApp) {
        video.removeEventListener('click', listener);
      }
    };
  }, [isNativeApp]);

  const videoClassNames = useMemo(
    () =>
      cn(styles.Video, {
        [styles['Video--small']]: size === VideoSize.S,
        [styles['Video--medium']]: size === VideoSize.M,
        [styles['Video--large']]: size === VideoSize.L,
        [styles['Video--full']]: size === VideoSize.FULL,
      }),
    [size],
  );

  return (
    <video
      // because of need using unknown-property
      /* eslint-disable-next-line react/no-unknown-property */
      webkit-playsinline={1}
      playsInline
      ref={videoRef}
      muted={isMuted}
      autoPlay={isAutoplay}
      controls={!isNativeApp}
      preload="metadata"
      className={videoClassNames}
      src={`${url}#t=0.01`}
      loop
      tabIndex={0}
    >
      <source type={type} />
      <track src={url} kind="captions" />
    </video>
  );
};
