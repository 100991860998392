import { memo, useMemo } from 'react';
import { addDays, format, isSameDay } from 'date-fns';
import { IGameDetailed } from 'query-hooks/scoreboard/interfaces/game-detailed.interface';

import { FULL_DATE_FORMAT } from 'configs/date.config';

import { GameDetailsTabs } from '../game/game.config';

import { ScoreboardDay } from './scoreboard-day/scoreboard-day.component';

import styles from './scoreboard.module.less';

interface IScoreboardProps {
  activeDate: Maybe<Date>;
  games: IGameDetailed[];
  timezone: string;
  onPlayerClick: (slug: string, gameId: string) => void;
  onTeamClick: (teamId: number) => void;
  onGameClick: (gameId: number, tabQuery: Maybe<GameDetailsTabs>) => void;
}

export const Scoreboard = memo((props: IScoreboardProps) => {
  const { activeDate, games, timezone } = props;

  const formattedDates = useMemo(() => {
    if (!activeDate) {
      return [];
    }

    return [
      activeDate.toISOString(),
      addDays(activeDate, 1).toISOString(),
      addDays(activeDate, 2).toISOString(),
      addDays(activeDate, 3).toISOString(),
      addDays(activeDate, 4).toISOString(),
      addDays(activeDate, 5).toISOString(),
      addDays(activeDate, 6).toISOString(),
    ].map((item) => format(new Date(item), FULL_DATE_FORMAT));
  }, [activeDate]);

  return (
    <div className={styles.Scoreboard}>
      {formattedDates.map((date) => {
        const filteredGames = games.filter((game) =>
          isSameDay(new Date(game.dateStart), new Date(date)),
        );

        if (!filteredGames.length) {
          return null;
        }

        return (
          <ScoreboardDay
            formattedSelectedDate={date}
            games={filteredGames}
            key={date}
            timezone={timezone}
            onPlayerClick={props.onPlayerClick}
            onTeamClick={props.onTeamClick}
            onGameClick={props.onGameClick}
          />
        );
      })}
    </div>
  );
});
