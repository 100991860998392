import { z } from 'zod';

const textAllowedSchema = z.boolean();

const mediaTypeSchema = z.enum(['image', 'video']).nullable();

export const groupPostResponseSchema = z
  .object({
    textAllowed: textAllowedSchema,
    media: mediaTypeSchema,
  })
  .refine(
    (data) => data.textAllowed || data.media, // At least one must be true
    {
      message: "At least one of 'Media' or 'Text' must be selected\n", // Error message
      path: ['textAllowed'], // Associate error with a specific field
    },
  );
