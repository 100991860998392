import { useCallback, useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { format as formatTz } from 'date-fns-tz';
import { observer } from 'mobx-react-lite';
import { useGameScoreboardSubscription } from 'query-hooks/scoreboard/use-game-scoreboard.subscription';
import { useScoreboardDataQuery } from 'query-hooks/scoreboard/use-scoreboard-data.query';
import { useCurrentDateQuery } from 'query-hooks/scoreboard/user-current-date.query';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { GamesDetailedSelectTeamContainer } from 'containers/games-detailed-select-team/games-detailed-select-team.container';

import { TIME_ZONE_FORMAT } from 'configs/date.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';
import { useResponsive } from 'hooks/use-responsive';
import { useTeamClick } from 'hooks/use-team-click';
import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { GameDetailsTabs } from 'components/game/game.config';
import { Scoreboard } from 'components/scoreboard/scoreboard.component';
import { Loader } from 'components/ui/loader/loader.component';
import { WeekBar } from 'components/ui/week-bar/week-bar.component';

import styles from './scoreboard.module.less';

export const ScoreboardContainer = observer(() => {
  const timezone = formatTz(new Date(), TIME_ZONE_FORMAT);

  const [selectedDate, setSelectedDate] = useState<Maybe<Date>>(null);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { teamId } = useParams<{
    [paths.TEAM_ID_PARAM]: string;
  }>();

  const { data: currentDateData } = useCurrentDateQuery(timezone);

  const {
    isLoading,
    isFetched,
    data: games,
  } = useScoreboardDataQuery(selectedDate, timezone, teamId);

  useGameScoreboardSubscription();

  const navigate = useNavigate();
  const { googleAnalyticsHomeFeed, googleAnalyticsTeamFeed } = useGaScreenCustomView();

  useToggleIgnoredTouchClass(!isFetched);

  const handlePlayerClick = useCallback(
    (slug: string, gameId: string) => {
      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerPerformance,
          player: slug,
          entry_point: ScreenNamesEnum.HomeScores,
        },
      });

      navigate(
        getPath(paths.PLAYER_PERFORMANCE, {
          [paths.PLAYER_SLUG_PARAM]: slug,
          [paths.GAME_ID_PARAM]: gameId,
        }),
      );
    },
    [navigate],
  );

  const handleTeamClick = useTeamClick();

  const handleGameClick = useCallback(
    (gameId: number, tabQuery: Maybe<GameDetailsTabs>) => {
      const path = getPath(paths.ARENA_GAME, { [paths.GAME_ID_PARAM]: String(gameId) });

      if (tabQuery) {
        navigate({
          pathname: path,
          search: `${createSearchParams({ tab: tabQuery })}`,
        });
      } else {
        navigate(path);
      }
    },
    [navigate],
  );

  const handleDayClick = useCallback((date: Maybe<Date>) => {
    if (!date) {
      return;
    }

    setSelectedDate(date);
  }, []);

  useEffect(() => {
    if (teamId) {
      googleAnalyticsTeamFeed(ScreenNamesEnum.TeamProfileScores);
      return;
    }

    googleAnalyticsHomeFeed(ScreenNamesEnum.HomeScores);
  }, [teamId, googleAnalyticsHomeFeed, googleAnalyticsTeamFeed]);

  useEffect(() => {
    const { date } = currentDateData || {};

    if (!date) {
      return;
    }

    setSelectedDate(new Date(date));
  }, [currentDateData, setSelectedDate]);

  return (
    <div className={styles.Scoreboard}>
      <div className={styles.Scoreboard__Header}>
        {isDesktopPlus && <h3 className={styles.Scores__Title}>NBA Scoreboard</h3>}
        <GamesDetailedSelectTeamContainer />
      </div>
      {selectedDate && (
        <div className={styles.Scoreboard__Weekbar}>
          <WeekBar disabled={isLoading} activeDate={selectedDate} onDateClick={handleDayClick} />
        </div>
      )}
      <div className={styles.Scoreboard__Games}>
        {isLoading ? (
          <Loader isShow withBackground />
        ) : (
          <Scoreboard
            timezone={timezone}
            activeDate={selectedDate}
            games={games ?? []}
            onPlayerClick={handlePlayerClick}
            onTeamClick={handleTeamClick}
            onGameClick={handleGameClick}
          />
        )}
      </div>
    </div>
  );
});
