import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useInjection } from 'inversify-react';
import { gameDetailedAdapter } from 'query-hooks/scoreboard/adapters/game-detailed-adapter.util';
import { scoreboardQueryKeys } from 'query-hooks/scoreboard/constants/query-keys.constants';
import { IGameDetailed } from 'query-hooks/scoreboard/interfaces/game-detailed.interface';

import { IGameDetailedResponse } from 'services/games-detailed/interfaces/game-detailed-response.interface';

import { IoMessage } from 'stores/io/enums/io-message.enum';
import { IoStore } from 'stores/io/io.store';

import { TYPES } from 'configs/di-types.config';

export const useGameScoreboardSubscription = () => {
  const ioStore = useInjection<IoStore>(TYPES.IoStore);
  const queryClient = useQueryClient();

  useEffect(() => {
    ioStore.socket.on(
      IoMessage.GameStatisticsDetailedUpdated,
      (rawScoreboardGame: IGameDetailedResponse) => {
        const newGame = gameDetailedAdapter(rawScoreboardGame);
        queryClient.setQueriesData(
          { queryKey: scoreboardQueryKeys.prefix },
          (previous: IGameDetailed[]) =>
            previous?.map((game: IGameDetailed) => {
              return game.id === newGame.id ? newGame : game;
            }),
        );
      },
    );
  }, [ioStore.socket, queryClient]);
};
