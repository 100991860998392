import { inject, injectable } from 'inversify';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { FollowService } from 'services/follow/follow.service';
import {
  IFollowItemResponse,
  IFollowPayload,
} from 'services/follow/interfaces/followings-response.interface';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { AuthStore } from 'stores/auth/auth.store';
import { followBuilder } from 'stores/follow/adapters/follow-builder.util';
import { IFollow } from 'stores/follow/interfaces/follow.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

@injectable()
export class FollowStore extends ApiConnectedStore {
  private readonly authStore: AuthStore;

  private readonly teamsStatsStore: TeamsStatsStore;

  private readonly followService: FollowService;

  public follow: IFollow[];

  constructor(
    @inject(TYPES.AuthStore) authStore: AuthStore,
    @inject(TYPES.TeamsStatsStore) teamsStatsStore: TeamsStatsStore,
    @inject(TYPES.FollowService) followService: FollowService,
  ) {
    super();

    this.authStore = authStore;

    this.followService = followService;

    this.teamsStatsStore = teamsStatsStore;

    this.follow = [];

    makeObservable(this, {
      follow: observable,

      followingPlayers: computed,
      followingTeams: computed,
      playersFollowAbbrevs: computed,
      teamsFollowAbbrevs: computed,
      sortedFollowings: computed,

      setFollow: action.bound,
    });

    reaction(() => [this.teamsStatsStore.players, this.teamsStatsStore.teams], this.loadFollowings);

    reaction(
      () => [this.authStore.isAuthorised, this.authStore.isAuthorisedAnonymously],
      this.handleAuthChange,
    );
  }

  private handleAuthChange = async () => {
    this.setFollow([]);

    // If user is not authorised, load anonymous followings, otherwise load user followings
    if (this.authStore.isAuthorisedAnonymously || this.authStore.isAuthorised) {
      await this.loadFollowings();
    }
  };

  public setFollow(value: IFollow[]) {
    this.follow = value;
  }

  public loadFollowings = async () => {
    this.setFetched(false);

    if (!this.teamsStatsStore.teams.length || !this.teamsStatsStore.players.length) {
      return;
    }

    const response = await this.followService.loadFollowings();

    if (response.success) {
      this.prepareFollowings(response.data);
    }

    this.setFetched(true);
  };

  public get followingPlayers(): IFollow[] {
    return this.follow.filter((item) => item.isFollow && item.type === 'player');
  }

  public get followingTeams(): IFollow[] {
    return this.follow.filter((item) => item.isFollow && item.type === 'team');
  }

  public get playersFollowAbbrevs(): string[] {
    return this.followingPlayers.map((player) => {
      const firstNameCharacters = player.firstName.slice(0, 2);
      return `${firstNameCharacters}${player.lastName}`;
    });
  }

  public get teamsFollowAbbrevs(): string[] {
    return this.followingTeams.map((team) => {
      const splittedTeam = team.city.concat(' ', team.label).split(' ');
      const firstCharacters = splittedTeam.map((teamItem) =>
        teamItem.charAt(0).toLocaleUpperCase(),
      );

      return firstCharacters.join('');
    });
  }

  public get sortedFollowings() {
    return [
      ...this.followingPlayers.sort((a, b) => b.followDate - a.followDate),
      ...this.followingTeams.sort((a, b) => b.followDate - a.followDate),
    ];
  }

  public prepareFollowings(followings: IFollowItemResponse[]) {
    this.setFollow(
      followBuilder(followings, this.teamsStatsStore.teams, this.teamsStatsStore.players),
    );
  }

  private googleAnalyticsButtonSubscribeFollowTap() {
    const numPlayersFollow = this.followingPlayers.length;
    const numTeamsFollow = this.followingTeams.length;
    const playersFollow = this.playersFollowAbbrevs.toString().slice(0, 100);
    const teamsFollow = this.teamsFollowAbbrevs.toString().slice(0, 100);

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.SaveFavoriteTeamsPlayers,
        num_players_follow: this.authStore.isAuthorised ? numPlayersFollow : 'null',
        num_teams_follow: this.authStore.isAuthorised ? numTeamsFollow : 'null',
        players_follow: this.authStore.isAuthorised ? playersFollow : 'null',
        teams_follow: this.authStore.isAuthorised ? teamsFollow : 'null',
      },
    });
  }

  public async subscribeFollow(payload: IFollowPayload): Promise<boolean> {
    this.setFetching(true);

    this.googleAnalyticsButtonSubscribeFollowTap();

    const response = await this.followService.updateFollowings({
      players: {
        follow: payload.followPlayers,
        unfollow: payload.unFollowPlayers,
      },
      teams: {
        follow: payload.followTeams,
        unfollow: payload.unFollowTeams,
      },
    });

    this.setFetching(false);

    if (response.success) {
      await this.loadFollowings();
    }

    return response.success;
  }

  public async followPlayer(playerId: number) {
    this.setFetching(true);

    const response = await this.followService.followPlayer(playerId);

    if (response.success) {
      await this.loadFollowings();
    } else {
      this.setErrors(response.errors);
    }

    this.setFetching(false);
  }

  public async unfollowPlayer(playerId: number) {
    const response = await this.followService.unfollowPlayer(playerId);

    if (response.success) {
      await this.loadFollowings();
    } else {
      this.setErrors(response.errors);
    }
  }
}
