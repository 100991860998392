import { GameQuarter } from 'query-hooks/scoreboard/types/game-quarter.type';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

export const getFormattedPeriod = (status: GameStatus, currentPeriod: number | GameQuarter) => {
  if (status === GameStatus.Halftime) {
    return 'Halftime';
  }

  if (currentPeriod) {
    if (currentPeriod <= 4) {
      return `Q${currentPeriod}`;
    }

    if (currentPeriod === 5) {
      return `OT`;
    }

    if (currentPeriod > 5) {
      return `${currentPeriod - 4}OT`;
    }
  }

  return '';
};
