import { FC, useCallback, useEffect, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { SplashScreen as NativeSplashScreen } from '@capacitor/splash-screen';
import { IonApp, setupIonicReact } from '@ionic/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { MainProvider } from 'providers/main-provider';
import { handleQueryNotificationsErrors } from 'query-hooks/query-notifications-error.util';

import { StorageField } from 'services/storage/enum/storage-field.enum';
import { StorageService } from 'services/storage/storage.service';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { AuthMode } from 'stores/auth/enums/auth-mode.enum';

import { TYPES } from 'configs/di-types.config';
import { router } from 'routes/router';

import { useKeyboardClose } from 'hooks/use-keyboard-close';
import { usePlatformNameClass } from 'hooks/use-platform-name-class';
import { useRetrieveSafeAreas } from 'hooks/use-retrieve-safe-areas';

import { ErrorBoundary } from 'components/errors/error-boundary/error-boundary.component';
import { SplashScreen } from 'components/ui/splash-screen/splash-screen.component';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: handleQueryNotificationsErrors,
  }),
});

export const App: FC = observer(() => {
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const storageService = useInjection<StorageService>(TYPES.StorageService);

  const updateAppVersion = useCallback(async () => {
    const latestKnownVersion = await storageService.get(StorageField.CurrentAppVersion);

    if (latestKnownVersion) {
      if (latestKnownVersion !== VERSION) {
        storageService.set(StorageField.CurrentAppVersion, VERSION);
        storageService.set(StorageField.IsAppVersionChangeHandled, true);
      }
    } else {
      storageService.set(StorageField.CurrentAppVersion, VERSION);
    }
  }, [storageService]);

  useEffect(() => {
    updateAppVersion();
  }, [updateAppVersion]);

  setupIonicReact({ mode: 'ios' });
  usePlatformNameClass(applicationStore.platformName);
  useRetrieveSafeAreas();
  useKeyboardClose(applicationStore.isNativeApp);

  const isFetchesCompleted = useMemo(
    () => applicationStore.fetched && authStore.fetched,
    [applicationStore.fetched, authStore.fetched],
  );

  const initialise = useCallback(async () => {
    await Promise.all([applicationStore.initialise(), authStore.initialise()]);
  }, [applicationStore, authStore]);

  const enableSignUpMode = useCallback(() => {
    authStore.setAuthMode(AuthMode.SignUp);
  }, [authStore]);

  useEffect(() => {
    (async () => {
      await initialise();
    })();
  }, [initialise]);

  useEffect(() => {
    if (isFetchesCompleted) {
      NativeSplashScreen.hide();
    }
  }, [isFetchesCompleted]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`VERSION: ${VERSION}`);
  }, []);

  if (!isFetchesCompleted) {
    return <SplashScreen />;
  }

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <IonApp>
          <MainProvider
            isNativeApp={applicationStore.isNativeApp}
            isNativeAndroidApp={applicationStore.isNativeAndroidApp}
            isAuthorised={authStore.isAuthorised}
            enableSignUpMode={enableSignUpMode}
          >
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </MainProvider>
        </IonApp>
      </HelmetProvider>
    </ErrorBoundary>
  );
});
