import { useEffect } from 'react';
import { json } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { TeamsStore } from 'stores/teams/teams.store';

import { TYPES } from 'configs/di-types.config';

import { useLayoutEntity } from 'hooks/use-layout-entity';

import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const TeamContainer = observer(() => {
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);

  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  useEffect(() => {
    layoutStore.setActiveFeed(FeedTypes.Team);
  }, [layoutStore]);

  useEffect(() => {
    if (teamsStore.isIdInvalid) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw json('Not Found', { status: 404 });
    }
  }, [teamsStore.isIdInvalid]);

  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });
  return null;
});
