import { inject, injectable } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import AppsFlyerService from 'services/apps-flyer/apps-flyer.service';
import { AuthService } from 'services/auth/auth.service';
import { IAnonymousUserMeResponse } from 'services/auth/interfaces/anonymous-user-me-response.interface';
import { IContactUsRequestPayload } from 'services/auth/interfaces/contact-us-request-payload.interface';
import { IContactUsResponse } from 'services/auth/interfaces/contact-us-response.interface';
import {
  ILoginResponse,
  ILoginWithThirdPartyResponse,
} from 'services/auth/interfaces/login-response.interface';
import { IPolicyResponse } from 'services/auth/interfaces/policy-response.interface';
import { ISessionResponse } from 'services/auth/interfaces/session-response.interface';
import { IUserMeResponse } from 'services/auth/interfaces/user-me-response.interface';
import CrashlyticsService from 'services/crashlytics/crashlytics.service';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { GAEventNameType } from 'services/google-analytic/interfaces/event-payload.interface';
import { LoginWithAppleService } from 'services/login-with-apple/login-with-apple.service';
import { LoginWithGoogleService } from 'services/login-with-google/login-with-google.service';
import { PushNotificationService } from 'services/push-notification/push-notification.service';
import { StorageField } from 'services/storage/enum/storage-field.enum';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { ApplicationStore } from 'stores/application/application.store';
import { anonymousUserMeAdapter } from 'stores/auth/adapters/anonymous-user-me-adapter.util';
import { policyAdapter } from 'stores/auth/adapters/policy-adapter.util';
import { userMeAdapter } from 'stores/auth/adapters/user-me-adapter.util';
import { userMeUpdatePayloadBuilder } from 'stores/auth/adapters/user-me-update-payload-builder.util';
import { IAnonymousUserMeInterface } from 'stores/auth/interfaces/anonymous-user-me.interface';
import { ICountdownTime } from 'stores/auth/interfaces/countdown-time.interface';
import {
  IForgotPasswordPreserved,
  IForgotPasswordRequestParams,
  IResetPasswordParams,
  IResetPasswordVerificationParams,
} from 'stores/auth/interfaces/forgot-password.interface';
import { ILoginByPasswordParams } from 'stores/auth/interfaces/login-by-password-params.interface';
import { ISignUpWithEmailPreserved } from 'stores/auth/interfaces/sign-up-preserved.interface';
import { ISignUpVerificationConfirmParams } from 'stores/auth/interfaces/sign-up-verification-confirm-params.interface';
import { ISignUpVerificationParams } from 'stores/auth/interfaces/sign-up-verification-params.interface';
import { ISignUpWithPasswordParams } from 'stores/auth/interfaces/sign-up-with-password-params.interface';
import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { IUserMe } from 'stores/auth/interfaces/user-me.interface';
import { UserRoleEnum } from 'stores/auth/types/user-role.type';

import { TYPES } from 'configs/di-types.config';

import { authLogger } from 'loggers/auth.logger';

import { SharedType } from '../share/enums/share-type.enum';

import { AuthMode } from './enums/auth-mode.enum';
import { IPolicy } from './interfaces/policy.interface';

@injectable()
export class AuthStore extends ApiConnectedStore {
  private readonly applicationStore: ApplicationStore;

  private readonly authService: AuthService;

  private readonly pushNotificationService: PushNotificationService;

  private readonly loginWithAppleService: LoginWithAppleService;

  private readonly loginWithGoogleService: LoginWithGoogleService;

  public isFetchedSession: boolean;

  public authMode: Maybe<AuthMode>;

  public session: Maybe<ISessionResponse>;

  public anonymousSession: Maybe<ISessionResponse>;

  public signUpWithEmailPreserved: Maybe<ISignUpWithEmailPreserved>;

  public countdownTimeSignUp: Maybe<ICountdownTime>;

  public countdownTimeForgotPassword: Maybe<ICountdownTime>;

  public forgotPasswordPreserved: Maybe<IForgotPasswordPreserved>;

  public userMe: Maybe<IUserMe>;

  public anonymousUserMe: Maybe<IAnonymousUserMeInterface>;

  public shouldApplyFavoriteTeamBackground: boolean;

  public termsOfUse: Maybe<IPolicy>;

  public privacyPolicy: Maybe<IPolicy>;

  public userAgreement: Maybe<IPolicy>;

  public isAthlete: boolean;

  public redirectUserId: Maybe<string>;

  constructor(
    @inject(TYPES.ApplicationStore) applicationStore: ApplicationStore,
    @inject(TYPES.AuthService) authService: AuthService,
    @inject(TYPES.PushNotificationService) pushNotificationService: PushNotificationService,
    @inject(TYPES.LoginWithAppleService) loginWithAppleService: LoginWithAppleService,
    @inject(TYPES.LoginWithGoogleService) loginWithGoogleService: LoginWithGoogleService,
  ) {
    authLogger.info({ msg: 'auth store constructor (START)' });

    super();

    this.applicationStore = applicationStore;

    this.authService = authService;

    this.pushNotificationService = pushNotificationService;

    this.loginWithAppleService = loginWithAppleService;

    this.loginWithGoogleService = loginWithGoogleService;

    this.authMode = null;

    this.session = null;

    this.redirectUserId = null;

    this.anonymousSession = null;

    this.signUpWithEmailPreserved = null;

    this.countdownTimeSignUp = null;

    this.countdownTimeForgotPassword = null;

    this.forgotPasswordPreserved = null;

    this.userMe = null;

    this.anonymousUserMe = null;

    this.shouldApplyFavoriteTeamBackground = true;

    this.termsOfUse = null;

    this.privacyPolicy = null;

    this.userAgreement = null;

    this.isFetchedSession = false;

    this.isAthlete = false;

    window.addEventListener('storage', this.handleStorageChange);

    makeObservable(this, {
      authMode: observable,
      isAthlete: observable,
      session: observable,
      anonymousSession: observable,
      signUpWithEmailPreserved: observable,
      userMe: observable,
      anonymousUserMe: observable,
      countdownTimeSignUp: observable,
      countdownTimeForgotPassword: observable,
      shouldApplyFavoriteTeamBackground: observable,
      termsOfUse: observable,
      privacyPolicy: observable,
      userAgreement: observable,
      isFetchedSession: observable,
      redirectUserId: observable,

      isAuthorised: computed,
      isAuthorisedAnonymously: computed,
      isModalVisible: computed,
      isAuthorisedBySession: computed,
      activeSession: computed,

      deleteAccount: action.bound,
      setAuthMode: action.bound,
      setSignUpWithEmailPreserved: action.bound,
      setCountdownTimeSignUp: action.bound,
      setCountdownTimeForgotPassword: action.bound,
      setSession: action.bound,
      setAnonymousSession: action.bound,
      setIsAthlete: action.bound,
      setShouldApplyFavoriteTeamBackground: action.bound,
      setTermsOfUse: action.bound,
      setPrivacyPolicy: action.bound,
      setUserAgreement: action.bound,
      setFetchedSession: action.bound,
      setRedirectUserId: action.bound,
      updateUserMe: action.bound,
      setUserMe: action.bound,
      setAnonymousUserMe: action.bound,
      retrieveUserMe: action.bound,
      triggerAuthorisationCheck: action.bound,
    });

    autorun(async () => {
      authLogger.info({ msg: 'trigger session change autorun' });

      await this.handleSessionChange();

      this.handleInitializeGoogleSignInService();
    });

    reaction(() => this.isAuthorised, this.assignUserIdForThirdServices);

    authLogger.info({ msg: 'auth store constructor (END)' });
  }

  private assignUserIdForThirdServices = () => {
    if (this.userMe) {
      GoogleAnalyticService.setCommonParams(this.userMe.id);
      CrashlyticsService.setUserId(this.userMe.id);
      AppsFlyerService.setCustomerUserId(this.userMe.id);
    } else {
      GoogleAnalyticService.setCommonParams(null);
    }
  };

  private async retrieveSession() {
    authLogger.info({ msg: 'retrieve session' });

    const session = await this.authService.getSession();

    authLogger.debug({ msg: 'got session', session });

    if (session) {
      authLogger.debug({ msg: 'session is presented' });

      this.setSession(session);
    } else {
      authLogger.debug({ msg: 'session is not found, handle anonymous session' });
      await this.handleAnonymousSession();
    }
  }

  private async handleAnonymousSession() {
    authLogger.info({ msg: 'retrieve anonymous session' });

    const session = await this.authService.getAnonymousSession();

    authLogger.debug({ msg: 'got anonymous session', session });

    if (session) {
      authLogger.debug({ msg: 'anonymous session is presented' });

      this.setAnonymousSession(session);
    } else {
      await this.signInAnonymously();
    }
  }

  private handleStorageChange = async (storageEvent: StorageEvent) => {
    authLogger.debug({ msg: 'handle storage change', storageEvent });

    if (storageEvent.key === StorageField.Session) {
      const updatedSession = await this.authService.getSession();

      authLogger.debug({ msg: 'handle session field change', updatedSession });

      if (!updatedSession) {
        await this.logout();
      }
    }
  };

  private handleSessionChange = async () => {
    authLogger.debug({ msg: 'handle session change', session: this.session });

    if (this.session) {
      await this.retrieveUserMe();
    } else {
      this.setUserMe(null);
    }

    if (this.anonymousSession) {
      await this.retrieveAnonymousUserMe();
    } else {
      this.setAnonymousUserMe(null);
    }

    if (this.isFetchedSession) {
      this.setFetched(true);
    }
  };

  private handleInitializeGoogleSignInService() {
    this.loginWithGoogleService.initialize();
  }

  public get isAuthorised(): boolean {
    return !!this.userMe;
  }

  public get isAuthorisedAnonymously(): boolean {
    return !!this.anonymousUserMe;
  }

  public get isAuthorisedBySession(): boolean {
    return !!this.session;
  }

  public get activeSession(): Maybe<ISessionResponse> {
    return this.session || this.anonymousSession;
  }

  public get isModalVisible(): boolean {
    return this.authMode !== null;
  }

  public setAuthMode(authMode: Maybe<AuthMode>): void {
    authLogger.debug({ msg: 'set auth mode', prevAuthMode: this.authMode, authMode });

    this.authMode = authMode;
  }

  public setSignUpWithEmailPreserved(signUpPreserved: Maybe<ISignUpWithEmailPreserved>) {
    this.signUpWithEmailPreserved = signUpPreserved;
  }

  public setForgotPasswordPreserved(forgotPasswordPreserved: Maybe<IForgotPasswordPreserved>) {
    this.forgotPasswordPreserved = forgotPasswordPreserved;
  }

  public setIsAthlete(isAthlete: boolean) {
    this.isAthlete = isAthlete;
  }

  public setRedirectUserId(value: Maybe<string>) {
    this.redirectUserId = value;
  }

  public setSession(session: Maybe<ISessionResponse>): void {
    authLogger.debug({ msg: 'set session', prevSession: this.session, session });

    this.session = session;
  }

  public setAnonymousSession(anonymousSession: Maybe<ISessionResponse>): void {
    authLogger.debug({
      msg: 'set anonymous session',
      prevSession: this.anonymousSession,
      anonymousSession,
    });

    this.anonymousSession = anonymousSession;
  }

  public setUserMe(userMe: Maybe<IUserMe>) {
    authLogger.debug({ msg: 'set userMe', prevUserMe: this.userMe, userMe });

    this.userMe = userMe;
  }

  public setAnonymousUserMe(anonymousUserMe: Maybe<IAnonymousUserMeInterface>) {
    authLogger.debug({ msg: 'set anonymousUserMe', prevUserMe: this.userMe, anonymousUserMe });

    this.anonymousUserMe = anonymousUserMe;
  }

  public setShouldApplyFavoriteTeamBackground(shouldApplyFavoriteTeamBackground: boolean) {
    authLogger.debug({
      msg: 'set shouldApplyFavoriteTeamBackground',
      prevShouldApplyFavoriteTeamBackground: this.shouldApplyFavoriteTeamBackground,
      shouldApplyFavoriteTeamBackground,
    });

    this.shouldApplyFavoriteTeamBackground = shouldApplyFavoriteTeamBackground;
  }

  public setTermsOfUse(policy: Maybe<IPolicy>) {
    this.termsOfUse = policy;
  }

  public setFetchedSession(isFetchedSession: boolean) {
    this.isFetchedSession = isFetchedSession;
  }

  public async deleteAccount() {
    const deleteResult = await this.authService.deleteUser();

    if (!deleteResult.success) {
      this.setErrors(deleteResult.errors);
    }

    return deleteResult.success;
  }

  public setPrivacyPolicy(policy: Maybe<IPolicy>) {
    this.privacyPolicy = policy;
  }

  public setUserAgreement(policy: Maybe<IPolicy>) {
    this.userAgreement = policy;
  }

  public async initialise() {
    authLogger.info({ msg: 'initialise (START)' });

    await this.loadPreserved();
    await this.retrieveSession();
    await this.loadCountdownTimes();

    this.setFetchedSession(true);

    authLogger.info({ msg: 'initialise (END)' });
  }

  public async loadPreserved() {
    authLogger.info({ msg: 'load sign up and forgot password preserved data' });

    const signUpPreserved = await this.authService.getSignUpPreserved();
    this.setSignUpWithEmailPreserved(signUpPreserved);

    const forgotPasswordPreserved = await this.authService.getForgotPasswordPreserved();
    this.setForgotPasswordPreserved(forgotPasswordPreserved);
  }

  public async loadCountdownTimes() {
    authLogger.info({ msg: 'load countdown times' });

    const countdownTimeSignUp = await this.authService.getCountdownTimeSignUp();
    const countdownTimeForgotPassword = await this.authService.getCountdownTimeForgotPassword();

    this.setCountdownTimeSignUp(countdownTimeSignUp);
    this.setCountdownTimeForgotPassword(countdownTimeForgotPassword);
  }

  public async fetchRefreshedSession(session: ISessionResponse) {
    authLogger.info({ msg: 'refresh token' });

    this.setFetching(true);
    this.resetErrors();

    const refreshSessionResponse: IResponse<ISessionResponse> =
      await this.authService.fetchRefreshedSession(session);

    authLogger.debug({ msg: 'refresh session response', refreshSessionResponse });

    if (refreshSessionResponse.success) {
      const refreshedSession = refreshSessionResponse.data;

      if (this.session) {
        this.saveSession(refreshedSession);
      }

      if (this.anonymousSession) {
        this.saveAnonymousSession(refreshedSession);
      }
    } else {
      authLogger.error({
        msg: 'refresh session request failed',
        code: refreshSessionResponse.code,
        errors: refreshSessionResponse.errors,
      });

      this.setErrors(refreshSessionResponse.errors);
    }

    this.setFetching(false);
  }

  public async loginByPassword(params: ILoginByPasswordParams): Promise<void> {
    authLogger.info({ msg: 'login by password' });

    this.setFetching(true);
    this.resetErrors();

    const loginResponse: IResponse<ILoginResponse> = await this.authService.loginByPassword(params);

    authLogger.debug({ msg: 'login with password request response', loginResponse });

    if (loginResponse.success) {
      this.setAuthMode(null);
      this.sendGoogleAnalyticsSignIn('email');
      this.saveSession(loginResponse.data);
      this.logoutFromNotificationService();
      this.resetAnonymousSession();
    } else {
      authLogger.error({
        msg: 'update user me request failed',
        code: loginResponse.code,
        errors: loginResponse.errors,
      });

      this.setErrors(loginResponse.errors);
    }

    this.setFetching(false);
  }

  public async signInAnonymously(): Promise<void> {
    authLogger.info({ msg: 'login anonymously' });

    this.setFetching(true);
    this.resetErrors();

    const params = { app_token: this.applicationStore.appId };
    const signInResponse: IResponse<ILoginResponse> = await this.authService.signUpAnonymously(
      params,
    );

    if (signInResponse.success) {
      this.saveAnonymousSession(signInResponse.data);
    } else {
      authLogger.error({
        msg: 'sign in anonymously request failed',
        code: signInResponse.code,
        errors: signInResponse.errors,
      });

      this.setErrors(signInResponse.errors);
    }

    this.setFetching(false);
  }

  public async logout(): Promise<void> {
    authLogger.info({ msg: 'make logout' });

    this.resetSession();
    this.setIsAthlete(false);
    await this.loginWithGoogleService.signOut();
    await this.handleAnonymousSession();
    this.logoutFromNotificationService();
  }

  private logoutFromNotificationService() {
    if (this.pushNotificationService.hasOneSignalSubscription) {
      this.pushNotificationService.logoutUser();
    }
  }

  public async retrieveUserMe(): Promise<void> {
    authLogger.info({ msg: 'retrieve user me' });

    this.setFetching(true);
    this.resetErrors();

    const userMeResponse: IResponse<IUserMeResponse> = await this.authService.fetchUserMe();

    authLogger.debug({ msg: 'user me response fetched', userMeResponse });

    if (userMeResponse.success) {
      const profile = userMeAdapter(userMeResponse.data);
      this.setIsAthlete(profile.roles.includes(UserRoleEnum.ATHLETE));
      this.setUserMe(profile);
    } else {
      authLogger.error({
        msg: 'user me request is failed',
        code: userMeResponse.code,
        errors: userMeResponse.errors,
      });

      this.setErrors(userMeResponse.errors);
    }

    this.setFetching(false);
    this.setFetched(true);
  }

  public async retrieveAnonymousUserMe(): Promise<void> {
    authLogger.info({ msg: 'retrieve user me' });

    this.setFetching(true);
    this.resetErrors();

    const anonymousUserMeResponse: IResponse<IAnonymousUserMeResponse> =
      await this.authService.fetchAnonymousUserMe();

    if (anonymousUserMeResponse.success) {
      const profile = anonymousUserMeAdapter(anonymousUserMeResponse.data);
      this.setAnonymousUserMe(profile);
    } else {
      authLogger.error({
        msg: 'anonymous user me request is failed',
        code: anonymousUserMeResponse.code,
        errors: anonymousUserMeResponse.errors,
      });

      this.setErrors(anonymousUserMeResponse.errors);
      await this.signInAnonymously();
    }

    this.setFetching(false);
    this.setFetched(true);
  }

  public async updateUserMe(params: IUpdateUserParams): Promise<void> {
    authLogger.info({ msg: 'update user me' });

    this.setFetching(true);
    this.resetErrors();

    const successMessages: INotificationMessage[] = [];

    const payload = userMeUpdatePayloadBuilder(params, this.shouldApplyFavoriteTeamBackground);

    if (payload.avatar) {
      successMessages.push({ message: 'Avatar was saved!' });
    }

    if (payload.thumbnail) {
      successMessages.push({ message: 'Background was saved!' });
    }

    const userMeResponse: IResponse<IUserMeResponse> = await this.authService.updateUser(payload);

    authLogger.debug({ msg: 'update user me request response', userMeResponse });

    if (userMeResponse.success) {
      this.setUserMe(userMeAdapter(userMeResponse.data));
      this.setSuccesses(successMessages);

      if (payload.username) {
        this.setRedirectUserId(userMeResponse.data.username);
      }
    } else {
      authLogger.error({
        msg: 'update user me request failed',
        code: userMeResponse.code,
        errors: userMeResponse.errors,
      });

      this.setErrors(userMeResponse.errors);
    }

    this.setFetching(false);
  }

  public async signUp(params: ISignUpWithPasswordParams): Promise<void> {
    authLogger.info({ msg: 'signup with password' });

    this.setFetching(true);
    this.resetErrors();

    const { email, password } = params;

    const signUpResponse: IResponse<ISessionResponse> = await this.authService.signUpWithEmail({
      email,
      password,
      app_token: this.applicationStore.appId,
    });

    authLogger.debug({ msg: 'sign up request response', signUpResponse });

    if (signUpResponse.success) {
      this.setAuthMode(null);
      this.sendGoogleAnalyticSignUp('email_registered');
      this.resetSignUpPreserved();
      this.saveSession(signUpResponse.data);
      this.logoutFromNotificationService();
      this.resetAnonymousSession();
    } else {
      authLogger.error({
        msg: 'sign up request failed',
        code: signUpResponse.code,
        errors: signUpResponse.errors,
      });

      this.setErrors(signUpResponse.errors);
    }

    this.setFetching(false);
  }

  public async requestSignUpVerification(params: ISignUpVerificationParams): Promise<void> {
    authLogger.info({ msg: 'request sign up verification' });

    this.setFetching(true);
    this.resetErrors();

    const { email } = params;

    const verificationRequestResponse = await this.authService.verificationRequest({
      email,
      app_token: this.applicationStore.appId,
    });

    authLogger.debug({ msg: 'sign up verification request response', verificationRequestResponse });

    if (verificationRequestResponse.success) {
      this.saveSignUpWithEmailPreserved({ email, confirmed: false });
    } else {
      authLogger.error({
        msg: 'sign up verification request failed',
        code: verificationRequestResponse.code,
        errors: verificationRequestResponse.errors,
      });

      this.setErrors(verificationRequestResponse.errors);
    }

    this.setFetching(false);
  }

  public async confirmSignUpVerification(params: ISignUpVerificationConfirmParams): Promise<void> {
    authLogger.info({ msg: 'confirm sign up verification' });

    this.setFetching(true);
    this.resetErrors();

    const { code, email } = params;

    const verificationConfirmResponse = await this.authService.verificationConfirm({
      code,
      email,
      app_token: this.applicationStore.appId,
    });

    authLogger.debug({
      msg: 'confirm sign up verification request response',
      verificationConfirmResponse,
    });

    if (verificationConfirmResponse.success) {
      this.saveSignUpWithEmailPreserved({ email, confirmed: true });
    } else {
      authLogger.error({
        msg: 'confirm sign up verification request failed',
        code: verificationConfirmResponse.code,
        errors: verificationConfirmResponse.errors,
      });

      this.setErrors(verificationConfirmResponse.errors);
    }

    this.setFetching(false);
  }

  public reset(): void {
    authLogger.info({ msg: 'reset authStore' });

    super.reset();

    this.setSignUpWithEmailPreserved(null);
    this.setCountdownTimeSignUp(null);
    this.setSession(null);
    this.setAnonymousSession(null);
    this.setUserMe(null);
  }

  public resetSession(): void {
    authLogger.info({ msg: 'reset session in authStore' });

    this.authService.resetSession();

    this.setSession(null);
  }

  public resetAnonymousSession(): void {
    authLogger.info({ msg: 'reset anonymous session in authStore' });

    this.authService.resetAnonymousSession();

    this.setAnonymousSession(null);
  }

  public resetSignUpPreserved(): void {
    authLogger.info({ msg: 'reset sign up preserved in authStore' });

    this.setSignUpWithEmailPreserved(null);

    this.authService.resetSignUpWithEmailPreserved();
  }

  public cleanResetPasswordPreserved(): void {
    authLogger.info({ msg: 'clean reset password preserved in authStore' });

    this.setForgotPasswordPreserved(null);

    this.authService.cleanForgotPasswordPreserved();
  }

  public saveSession(session: ISessionResponse): void {
    authLogger.info({ msg: 'save session in authStore' });

    this.authService.saveSession(session);

    this.setSession(session);
  }

  public saveAnonymousSession(anonymousSession: ISessionResponse): void {
    authLogger.info({ msg: 'save anonymous session in authStore' });

    this.authService.saveAnonymousSession(anonymousSession);

    this.setAnonymousSession(anonymousSession);
  }

  public saveSignUpWithEmailPreserved(signUpWithEmailPreserved: ISignUpWithEmailPreserved) {
    authLogger.info({ msg: 'save sign up with email in authStore' });

    this.setSignUpWithEmailPreserved(signUpWithEmailPreserved);

    this.authService.saveSignUpWithEmailPreserved(signUpWithEmailPreserved);
  }

  public triggerAuthorisationCheck(): boolean {
    authLogger.info({ msg: 'trigger authorisation check' });

    if (this.isAuthorised) {
      return true;
    }

    this.setAuthMode(AuthMode.SignUp);

    return false;
  }

  public saveForgotPasswordPreserved(forgotPasswordPreserved: IForgotPasswordPreserved) {
    authLogger.info({ msg: 'save forgot password preserved in authStore' });

    this.setForgotPasswordPreserved(forgotPasswordPreserved);

    this.authService.saveForgotPasswordPreserved(forgotPasswordPreserved);
  }

  public setCountdownTimeSignUp(countdownTimeSignUp: Maybe<ICountdownTime>) {
    authLogger.debug({
      msg: 'set countdownTimeSignUp',
      prevCountdownTimeSignUp: this.countdownTimeSignUp,
      countdownTimeSignUp,
    });

    this.countdownTimeSignUp = countdownTimeSignUp;
  }

  public storeCountdownTimeSignUp(countdownTimeSignUp: Maybe<ICountdownTime>) {
    authLogger.debug({
      msg: 'store countdownTimeSignUp in authService',
      prevCountdownTimeSignUp: this.countdownTimeSignUp,
      countdownTimeSignUp,
    });

    this.setCountdownTimeSignUp(countdownTimeSignUp);

    this.authService.saveCountdownTimeSignUp(countdownTimeSignUp);
  }

  public setCountdownTimeForgotPassword(countdownTimeForgotPassword: Maybe<ICountdownTime>) {
    authLogger.debug({
      msg: 'set countdownTimeForgotPassword',
      prevCountdownTimeForgotPassword: this.countdownTimeForgotPassword,
      countdownTimeForgotPassword,
    });

    this.countdownTimeForgotPassword = countdownTimeForgotPassword;
  }

  public storeCountdownTimeForgotPassword(countdownTimeForgotPassword: Maybe<ICountdownTime>) {
    authLogger.debug({
      msg: 'store countdownTimeForgotPassword in authService',
      prevCountdownTimeForgotPassword: this.countdownTimeForgotPassword,
      countdownTimeForgotPassword,
    });

    this.setCountdownTimeForgotPassword(countdownTimeForgotPassword);

    this.authService.saveCountdownTimeForgotPassword(countdownTimeForgotPassword);
  }

  public async requestForgotPasswordVerification(
    params: IForgotPasswordRequestParams,
  ): Promise<void> {
    authLogger.debug({ msg: 'request forgot password verification' });

    this.setFetching(true);
    this.resetErrors();

    const { email } = params;

    const verificationRequestResponse = await this.authService.forgotPasswordVerificationRequest({
      email,
      app_token: this.applicationStore.appId,
    });

    authLogger.debug({
      msg: 'request forgot password verification request response',
      verificationRequestResponse,
    });

    if (verificationRequestResponse.success) {
      this.saveForgotPasswordPreserved({ email, confirmed: false, resetToken: null });
    } else {
      authLogger.error({
        msg: 'request forgot password verification request failed',
        code: verificationRequestResponse.code,
        errors: verificationRequestResponse.errors,
      });

      this.setErrors(verificationRequestResponse.errors);
    }

    this.setFetching(false);
  }

  public async confirmResetPasswordVerification(
    params: IResetPasswordVerificationParams,
  ): Promise<void> {
    authLogger.info({ msg: 'confirm reset password verification' });

    this.setFetching(true);
    this.resetErrors();

    const { code, email } = params;

    const verificationConfirmResponse = await this.authService.resetPasswordVerificationConfirm({
      code,
      email,
      app_token: this.applicationStore.appId,
    });

    authLogger.debug({
      msg: 'confirm reset password verification request response',
      verificationConfirmResponse,
    });

    if (verificationConfirmResponse.success) {
      this.saveForgotPasswordPreserved({
        email,
        confirmed: true,
        resetToken: verificationConfirmResponse.data.reset_token,
      });
    } else {
      authLogger.error({
        msg: 'confirm reset password verification request failed',
        code: verificationConfirmResponse.code,
        errors: verificationConfirmResponse.errors,
      });

      this.setErrors(verificationConfirmResponse.errors);
    }

    this.setFetching(false);
  }

  public async setNewPassword(params: IResetPasswordParams): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    const { email, password } = params;

    if (this.forgotPasswordPreserved && this.forgotPasswordPreserved.resetToken) {
      const resetPasswordResponse: IResponse<ISessionResponse> =
        await this.authService.setNewPassword({
          email,
          password,
          app_token: this.applicationStore.appId,
          reset_token: this.forgotPasswordPreserved.resetToken,
        });

      if (resetPasswordResponse.success) {
        this.resetAnonymousSession();
        this.setAuthMode(null);
        this.cleanResetPasswordPreserved();
        this.saveSession(resetPasswordResponse.data);
      } else {
        this.setErrors(resetPasswordResponse.errors);
      }
    }

    this.setFetching(false);
  }

  public async retrievePrivacyPolicy(): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    const privacyPolicyResponse: IResponse<IPolicyResponse> =
      await this.authService.fetchPrivacyPolicy();

    if (privacyPolicyResponse.success) {
      this.setPrivacyPolicy(policyAdapter(privacyPolicyResponse.data));
    } else {
      this.setErrors(privacyPolicyResponse.errors);
    }

    this.setFetching(false);
    this.setFetched(true);
  }

  public async retrieveTermsOfUse(): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    const termsOfUseResponse: IResponse<IPolicyResponse> = await this.authService.fetchTermsOfUse();

    if (termsOfUseResponse.success) {
      this.setTermsOfUse(policyAdapter(termsOfUseResponse.data));
    } else {
      this.setErrors(termsOfUseResponse.errors);
    }

    this.setFetching(false);
    this.setFetched(true);
  }

  public async retrieveUserAgreement(): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    const userAgreementResponse: IResponse<IPolicyResponse> =
      await this.authService.fetchUserAgreement();

    if (userAgreementResponse.success) {
      this.setUserAgreement(policyAdapter(userAgreementResponse.data));
    } else {
      this.setErrors(userAgreementResponse.errors);
    }

    this.setFetching(false);
    this.setFetched(true);
  }

  public sendGoogleAnalyticFollowedLink(
    sharedType: Maybe<SharedType>,
    sharedUsername: Maybe<string>,
    sharedUserId: Maybe<string>,
  ) {
    GoogleAnalyticService.event({
      eventName: 'shared_click',
      eventParams: {
        username_shared: sharedUsername || '',
        user_id_shared: sharedUserId || '',
        type: sharedType || '',
      },
    });
  }

  public sendGoogleAnalyticSignUp(eventName: GAEventNameType) {
    GoogleAnalyticService.event({
      eventName,
      eventParams: {},
    });
  }

  public sendGoogleAnalyticsSignIn(loginType: 'email' | 'apple' | 'google') {
    GoogleAnalyticService.event({
      eventName: 'logged_in',
      eventParams: {
        login_type: loginType,
      },
    });
  }

  public async contactUsRequest(payload: IContactUsRequestPayload) {
    this.setFetching(true);
    this.resetErrors();

    const contactUsResponse: IResponse<IContactUsResponse> =
      await this.authService.contactUsRequest(payload);

    if (contactUsResponse.success) {
      this.setSuccesses([{ message: 'Your request was successfully sent' }]);
    } else {
      this.setErrors(contactUsResponse.errors);
    }

    this.setFetching(false);

    return contactUsResponse.success;
  }

  public async appleSignIn() {
    authLogger.info({ msg: 'sign in with Apple in authStore' });

    this.resetErrors();

    const idToken = await this.loginWithAppleService.authorize();

    authLogger.debug({ msg: 'apple id token is received', idToken });

    this.setFetching(true);

    if (idToken) {
      const loginResponse: IResponse<ILoginWithThirdPartyResponse> =
        await this.authService.loginByApple({
          id_token: idToken,
          app_token: this.applicationStore.appId,
        });

      authLogger.debug({ msg: 'sign in with Apple login request response', loginResponse });

      if (loginResponse.success) {
        this.setAuthMode(null);

        if (loginResponse.data.is_new_user) {
          this.sendGoogleAnalyticSignUp('apple_registered');
        } else {
          this.sendGoogleAnalyticsSignIn('apple');
        }

        this.saveSession(loginResponse.data);
        this.logoutFromNotificationService();
        this.resetAnonymousSession();
      } else {
        authLogger.error({
          msg: 'sign in with Google login request failed',
          code: loginResponse.code,
          errors: loginResponse.errors,
        });

        this.setErrors(loginResponse.errors);
      }
    }

    this.setFetching(false);
  }

  public async googleSignIn() {
    authLogger.info({ msg: 'sign in with Google in authStore' });

    this.resetErrors();

    const idToken = await this.loginWithGoogleService.signIn();

    authLogger.debug({ msg: 'google id token is received', idToken });

    this.setFetching(true);

    if (idToken) {
      const loginResponse: IResponse<ILoginWithThirdPartyResponse> =
        await this.authService.loginByGoogle({
          id_token: idToken,
          app_token: this.applicationStore.appId,
        });

      authLogger.debug({ msg: 'sign in with Google login request response', loginResponse });

      if (loginResponse.success) {
        this.setAuthMode(null);

        if (loginResponse.data.is_new_user) {
          this.sendGoogleAnalyticSignUp('g_registered');
        } else {
          this.sendGoogleAnalyticsSignIn('google');
        }

        this.saveSession(loginResponse.data);
        this.logoutFromNotificationService();
        this.resetAnonymousSession();
      } else {
        authLogger.error({
          msg: 'sign in with Google login request failed',
          code: loginResponse.code,
          errors: loginResponse.errors,
        });

        this.setErrors(loginResponse.errors);
      }
    }

    this.setFetching(false);
  }
}
