import { ICollaborationResponse } from 'services/collaboration/interfaces/collaboration.interface';

import { IAthleteResponse } from 'stores/auth/interfaces/athlete.interface';

import { PostsFeedType } from '../enums/posts-feed-type.enum';

import { IAttachmentsResponse } from './post-attachments-response.interface';
import { IPostSourceResponse } from './post-source-response.interface';

export interface IHomeFeedResponse {
  type: PostsFeedType.Home;
}

export interface IPlayerFeedResponse {
  type: PostsFeedType.Player;
  player_id: number;
  firstname: string;
  lastname: string;
  slug: string;
  small_logo_url: Maybe<string>;
  medium_logo_url: Maybe<string>;
}

export interface ITeamFeedResponse {
  type: PostsFeedType.Team;
  team_id: number;
  name: string;
  code: string;
  city_name: string;
  small_logo_url: Maybe<string>;
  medium_logo_url: Maybe<string>;
}

interface IFeedsResponse<T> {
  id: number;
  data?: T;
  is_default: boolean;
}

export type FeedsResponseType = IFeedsResponse<
  IHomeFeedResponse | IPlayerFeedResponse | ITeamFeedResponse
>[];

export interface IUserResponse {
  uuid: string;
  avatar_url: string;
  thumbnail_url: string;
  small_avatar_url: Maybe<string>;
  small_thumbnail_url: Maybe<string>;
  thumbnail_nickname_url: Maybe<string>;
  thumbnail_logo_url: Maybe<string>;
  real_name: string;
  username: string;
  favorite_team_id: Maybe<number>;
  favorite_player_id: Maybe<number>;
  athlete: Maybe<IAthleteResponse>;
}

export interface IPublicationResponse {
  uuid: string;
  title: string;
  date_created: string;
  bookmarks_count: number;
  shares_count: number;
  is_bookmarked: boolean;
  comments_amount: number;
  user: IUserResponse;
  content: string;
  attachments: IAttachmentsResponse;
  comments: ICommentResponse[];
  feeds: FeedsResponseType;
  likes_total: number;
  is_liked: boolean;
  comments_allowed: boolean;
}

interface ICommentResponse extends IPublicationResponse {
  replying_uuid?: string;
  post_title: string;
  post_uuid: string;
}

export interface IPostResponse extends IPublicationResponse {
  source?: Maybe<IPostSourceResponse>;
  collaboration?: ICollaborationResponse;
  athlete_interactions?: IAthleteInteractionsResponse[];
}

export interface IAthleteInteractionsGroup {
  name: string;
  types: AthleteInteractionType[];
}
export interface IAthleteInteractionsResponse {
  name: string;
  type: AthleteInteractionType;
}

export enum AthleteInteractionType {
  LIKE = 'like',
  COMMENT = 'comment',
}

export type PostInteractionTypeValues =
  (typeof PostInteractionType)[keyof typeof PostInteractionType];

export enum PostInteractionType {
  created = 'created',
  liked = 'liked',
  voted = 'voted',
  discussed = 'discussed',
  participated = 'participated',
}

export interface IInteractedPostResponse extends IPostResponse {
  actions: PostInteractionType[];
  post: IPostResponse;
}

export interface ITogglePostBookmarkResponse {
  bookmarks_count: number;
  is_bookmarked: true;
}

export interface ILikesResult {
  likes: number;
  isLiked: boolean;
}

export interface ILikesResponse {
  likes_total: number;
  is_liked: boolean;
}

export interface IDeletePostResponse {
  uuid: string;
}

export interface IAttachmentsCountResponse {
  files_count: number;
  media_count: number;
}

export interface IVideoResponse {
  uuid: string;
  url: string;
}
