import { FC } from 'react';

import { userReportHandler as handleUserBlockClick } from 'helpers/user-report-handler.util';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './comment-tooltip.module.less';

interface ICommentToolTip {
  isBookmarked: boolean;
  isCurrentUserComment: boolean;
  isAllowedCommentDelete: boolean;
  onBookmarkClick: () => void;
  onShareClick: () => void;
  onDeleteClick?: () => void;
  onReportsPopupOpen?: () => void;
}

export const CommentToolTip: FC<ICommentToolTip> = (props) => {
  const { isBookmarked, isAllowedCommentDelete, isCurrentUserComment } = props;

  return (
    <div className={styles.PostToolTip}>
      {isBookmarked ? (
        <IconButton
          iconName={IconFontName.BookmarkFilled}
          theme={IconButtonTheme.Secondary}
          onClick={props.onBookmarkClick}
          active={isBookmarked}
        />
      ) : (
        <IconButton
          iconName={IconFontName.Bookmark}
          theme={IconButtonTheme.Transparent}
          onClick={props.onBookmarkClick}
        />
      )}
      {props.onDeleteClick && isAllowedCommentDelete && (
        <IconButton
          iconName={IconFontName.Remove}
          theme={IconButtonTheme.Secondary}
          onClick={props.onDeleteClick}
        />
      )}
      <IconButton
        iconName={IconFontName.Share}
        theme={IconButtonTheme.Transparent}
        onClick={props.onShareClick}
      />
      {!isCurrentUserComment && (
        <IconButton
          iconName={IconFontName.Report}
          theme={IconButtonTheme.Transparent}
          onClick={props.onReportsPopupOpen}
        />
      )}
      {!isCurrentUserComment && (
        <IconButton
          iconName={IconFontName.ProfileBlocked}
          theme={IconButtonTheme.Transparent}
          onClick={handleUserBlockClick}
        />
      )}
    </div>
  );
};
