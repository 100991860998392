import { Container } from 'inversify';

import { ApplicationService } from 'services/application/application.service';
import { AuthService } from 'services/auth/auth.service';
import { BookmarksService } from 'services/bookmarks/bookmarks.service';
import { BucketsService } from 'services/buckets/buckets.service';
import { CollaborationService } from 'services/collaboration/collaboration.service';
import { CommentsService } from 'services/comments/comments.service';
import { ContentCardsService } from 'services/content-cards/content-cards.service';
import { EventsService } from 'services/events/events.service';
import { FollowService } from 'services/follow/follow.service';
import { GameService } from 'services/game/game.service';
import { GameChatService } from 'services/game-chat/game-chat.service';
import { GamesDetailedService } from 'services/games-detailed/games-detailed.service';
import { HeadlinesService } from 'services/headlines/headlines.service';
import { HttpService } from 'services/http/http.service';
import { LocalDatabaseService } from 'services/local-database/local-database.service';
import { LoginWithAppleService } from 'services/login-with-apple/login-with-apple.service';
import { LoginWithGoogleService } from 'services/login-with-google/login-with-google.service';
import { MetaTagsService } from 'services/meta-tags/meta-tags.service';
import { PlayerService } from 'services/player/player.service';
import { PlayerRankingsService } from 'services/player-rankings/player-rankings.service';
import { PostsService } from 'services/posts/posts.service';
import { PushNotificationService } from 'services/push-notification/push-notification.service';
import { ReportsService } from 'services/reports/reports.service';
import { ReputationPointsService } from 'services/reputation-points/reputationPointsService';
import { SearchService } from 'services/search/search.service';
import { SeasonsService } from 'services/seasons/seasons.service';
import { SettingsService } from 'services/settings/settings.service';
import { StandingsService } from 'services/standings/standings.service';
import { StatisticsService } from 'services/statistics/statistics.service';
import { StorageService } from 'services/storage/storage.service';
import { TeamsStatsService } from 'services/team-stats/team-stats.service';
import { TeamsService } from 'services/teams/teams.service';
import { UserPublicService } from 'services/user-public/user-public.service';

import { AchievementsStore } from 'stores/achievements/achievements.store';
import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { BucketsStore } from 'stores/buckets/buckets.store';
import { CollaborationStore } from 'stores/collaboration/collaboration.store';
import { CollaborationCommentsStore } from 'stores/collaboration-comments/collaboration-comments.store';
import { CommentsStore } from 'stores/comments/comments.store';
import { ContentCardsStore } from 'stores/content-cards/content-cards.store';
import { FavoritesFeedStore } from 'stores/feeds/favorites-feed.store';
import { GameVideosFeedStore } from 'stores/feeds/game-videos-feed.store';
import { GlobalFanZoneFavoritesFeedStore } from 'stores/feeds/global-fan-zone-favorites-feed.store';
import { GlobalFanZoneFeedStore } from 'stores/feeds/global-fan-zone-feed.store';
import { PlayerFanZoneFavoritesFeedStore } from 'stores/feeds/player-fan-zone-favorites-feed.store';
import { PlayerFanZoneFeedStore } from 'stores/feeds/player-fan-zone-feed.store';
import { PlayerFeedStore } from 'stores/feeds/player-feed.store';
import { PublicUserFeedStore } from 'stores/feeds/public-user-feed.store';
import { TeamFanZoneFeedStore } from 'stores/feeds/team-fan-zone.store';
import { TeamFeedStore } from 'stores/feeds/team-feed.store';
import { YourFeedStore } from 'stores/feeds/your-feed.store';
import { FollowStore } from 'stores/follow/follow.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { GameStore } from 'stores/game/game.store';
import { GameChatStore } from 'stores/game-chat/game-chat.store';
import { GameEventsStore } from 'stores/game-events/game-events.store';
import { GlobalStatsStore } from 'stores/global-stats/global-stats.store';
import { HeadlinesStore } from 'stores/headlines/headlines.store';
import { IoStore } from 'stores/io/io.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { LeaderboardStore } from 'stores/leaderboard/leaderboard.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { NotificationsStore } from 'stores/notifications/notifications.store';
import { PlayerStore } from 'stores/player/player.store';
import { PlayerPerformanceStore } from 'stores/player-performance/player-performance.store';
import { PlayerRankingsStore } from 'stores/player-rankings/player-rankings.store';
import { PostsStore } from 'stores/posts/posts.store';
import { ReputationTransactionsStore } from 'stores/reputation-transactions/reputation-transactions.store';
import { ReputationsPointsStore } from 'stores/reputations-points/reputations-points.store';
import { SearchStore } from 'stores/search/search.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';
import { SettingsStore } from 'stores/settings/settings.store';
import { ShareStore } from 'stores/share/share.store';
import { SplitsGamesStore } from 'stores/splits-games/splits-games.store';
import { StandingsStore } from 'stores/standings/standings.store';
import { StatisticsStore } from 'stores/statistics/statistics.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';
import { TransactionBucketsStore } from 'stores/transaction-buckets/transaction-buckets.store';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from './di-types.config';

const container = new Container({
  defaultScope: 'Singleton',
});

container.bind<ApiConnectedStore>(TYPES.ApiConnectedStore).to(ApiConnectedStore);
container.bind<ApplicationStore>(TYPES.ApplicationStore).to(ApplicationStore);
container.bind<ApplicationService>(TYPES.ApplicationService).to(ApplicationService);
container.bind<AuthService>(TYPES.AuthService).to(AuthService);
container.bind<AuthStore>(TYPES.AuthStore).to(AuthStore);
container.bind<BookmarksStore>(TYPES.BookmarksStore).to(BookmarksStore);
container.bind<BookmarksService>(TYPES.BookmarksService).to(BookmarksService);
container.bind<CollaborationStore>(TYPES.CollaborationStore).to(CollaborationStore);
container.bind<CollaborationService>(TYPES.CollaborationService).to(CollaborationService);
container
  .bind<CollaborationCommentsStore>(TYPES.CollaborationCommentsStore)
  .to(CollaborationCommentsStore);
container.bind<CommentsStore>(TYPES.CommentsStore).to(CommentsStore);
container.bind<CommentsService>(TYPES.CommentsService).to(CommentsService);
container.bind<ContentCardsStore>(TYPES.ContentCardsStore).to(ContentCardsStore);
container.bind<ContentCardsService>(TYPES.ContentCardsService).to(ContentCardsService);
container.bind<EventsService>(TYPES.EventsService).to(EventsService);
container.bind<FavoritesFeedStore>(TYPES.FavoritesFeedStore).to(FavoritesFeedStore);
container.bind<FollowStore>(TYPES.FollowStore).to(FollowStore);
container.bind<FollowService>(TYPES.FollowService).to(FollowService);
container.bind<GalleryStore>(TYPES.GalleryStore).to(GalleryStore);
container.bind<GameStore>(TYPES.GameStore).to(GameStore);
container.bind<GameService>(TYPES.GameService).to(GameService);
container.bind<GameVideosFeedStore>(TYPES.GameVideosFeedStore).to(GameVideosFeedStore);
container.bind<GlobalStatsStore>(TYPES.GlobalStatsStore).to(GlobalStatsStore);
container
  .bind<GlobalFanZoneFavoritesFeedStore>(TYPES.GlobalFanZoneFavoritesFeedStore)
  .to(GlobalFanZoneFavoritesFeedStore);
container.bind<GlobalFanZoneFeedStore>(TYPES.GlobalFanZoneFeedStore).to(GlobalFanZoneFeedStore);
container.bind<GameChatStore>(TYPES.GameChatStore).to(GameChatStore);
container.bind<GameEventsStore>(TYPES.GameEventsStore).to(GameEventsStore);
container.bind<GameChatService>(TYPES.GameChatService).to(GameChatService);
container.bind<GamesDetailedService>(TYPES.GamesDetailedService).to(GamesDetailedService);
container.bind<HttpService>(TYPES.HttpService).to(HttpService);
container.bind<YourFeedStore>(TYPES.YourFeedStore).to(YourFeedStore);
container.bind<HeadlinesService>(TYPES.HeadlinesService).to(HeadlinesService);
container.bind<HeadlinesStore>(TYPES.HeadlinesStore).to(HeadlinesStore);
container.bind<IoStore>(TYPES.IoStore).to(IoStore);
container.bind<LocalDatabaseService>(TYPES.LocalDatabaseService).to(LocalDatabaseService);
container.bind<LayoutStore>(TYPES.LayoutStore).to(LayoutStore);
container.bind<LoginWithAppleService>(TYPES.LoginWithAppleService).to(LoginWithAppleService);
container.bind<LoginWithGoogleService>(TYPES.LoginWithGoogleService).to(LoginWithGoogleService);
container.bind<MetaTagsService>(TYPES.MetaTagsService).to(MetaTagsService);
container.bind<MetaTagsStore>(TYPES.MetaTagsStore).to(MetaTagsStore);
container.bind<PlayerPerformanceStore>(TYPES.PlayerPerformanceStore).to(PlayerPerformanceStore);
container.bind<PlayerStore>(TYPES.PlayerStore).to(PlayerStore);
container.bind<PlayerService>(TYPES.PlayerService).to(PlayerService);
container.bind<PlayerFeedStore>(TYPES.PlayerFeedStore).to(PlayerFeedStore);
container.bind<PlayerFanZoneFeedStore>(TYPES.PlayerFanZoneFeedStore).to(PlayerFanZoneFeedStore);
container
  .bind<PlayerFanZoneFavoritesFeedStore>(TYPES.PlayerFanZoneFavoritesFeedStore)
  .to(PlayerFanZoneFavoritesFeedStore);
container.bind<PostsStore>(TYPES.PostsStore).to(PostsStore);
container.bind<PostsService>(TYPES.PostsService).to(PostsService);
container.bind<PushNotificationService>(TYPES.PushNotificationService).to(PushNotificationService);
container.bind<ReportsService>(TYPES.ReportsService).to(ReportsService);
container.bind<PlayerRankingsStore>(TYPES.PlayerRankingsStore).to(PlayerRankingsStore);
container.bind<PlayerRankingsService>(TYPES.PlayerRankingsService).to(PlayerRankingsService);
container.bind<PublicUserFeedStore>(TYPES.PublicUserFeedStore).to(PublicUserFeedStore);
container.bind<ShareStore>(TYPES.ShareStore).to(ShareStore);
container.bind<SearchStore>(TYPES.SearchStore).to(SearchStore);
container.bind<SearchService>(TYPES.SearchService).to(SearchService);
container.bind<SeasonsService>(TYPES.SeasonsService).to(SeasonsService);
container.bind<SeasonsStore>(TYPES.SeasonsStore).to(SeasonsStore);
container.bind<SettingsService>(TYPES.SettingsService).to(SettingsService);
container.bind<SettingsStore>(TYPES.SettingsStore).to(SettingsStore);
container.bind<SplitsGamesStore>(TYPES.SplitsGamesStore).to(SplitsGamesStore);
container.bind<StandingsService>(TYPES.StandingsService).to(StandingsService);
container.bind<StandingsStore>(TYPES.StandingsStore).to(StandingsStore);
container.bind<StatisticsService>(TYPES.StatisticsService).to(StatisticsService);
container.bind<StatisticsStore>(TYPES.StatisticsStore).to(StatisticsStore);
container.bind<StorageService>(TYPES.StorageService).to(StorageService);
container.bind<TeamFeedStore>(TYPES.TeamFeedStore).to(TeamFeedStore);
container.bind<TeamFanZoneFeedStore>(TYPES.TeamFanZoneFeedStore).to(TeamFanZoneFeedStore);
container.bind<TeamsStore>(TYPES.TeamsStore).to(TeamsStore);
container.bind<TeamsService>(TYPES.TeamsService).to(TeamsService);
container.bind<TeamsStatsStore>(TYPES.TeamsStatsStore).to(TeamsStatsStore);
container
  .bind<ReputationTransactionsStore>(TYPES.ReputationTransactionsStore)
  .to(ReputationTransactionsStore);
container.bind<TeamsStatsService>(TYPES.TeamsStatsService).to(TeamsStatsService);
container.bind<TransactionBucketsStore>(TYPES.TransactionBucketsStore).to(TransactionBucketsStore);
container.bind<LeaderboardStore>(TYPES.LeaderboardStore).to(LeaderboardStore);
container.bind<ReputationPointsService>(TYPES.ReputationPointsService).to(ReputationPointsService);
container.bind<ReputationsPointsStore>(TYPES.ReputationsPointsStore).to(ReputationsPointsStore);
container.bind<UserPublicStore>(TYPES.UserPublicStore).to(UserPublicStore);
container.bind<UserPublicService>(TYPES.UserPublicService).to(UserPublicService);
container.bind<BucketsStore>(TYPES.BucketsStore).to(BucketsStore);
container.bind<BucketsService>(TYPES.BucketsService).to(BucketsService);
container.bind<AchievementsStore>(TYPES.AchievementsStore).to(AchievementsStore);
container.bind<NotificationsStore>(TYPES.NotificationsStore).to(NotificationsStore);

export { container };
