import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { reputationQueryKeys } from 'query-hooks/reputation/contastants/query-keys.constants';

import { IPostCommentBookmarkPayload } from 'services/comments/interfaces/comments.interface';
import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';
import { ICommentCreatePayload } from 'services/posts/interfaces/create-comment-payload.interface';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { CommentsStore } from 'stores/comments/comments.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { isSortValue, SortEnum } from 'stores/posts/posts.config';
import { PostsStore } from 'stores/posts/posts.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { COMMENTS_SORT_TABS_ACTIONS } from 'containers/top-level-comments/top-level-comments.config';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { checkIsUserStaff } from 'helpers/check-is-user-staff.util';
import { getIdFromCommentHash } from 'helpers/get-id-from-comment-hash';
import { getPath } from 'helpers/get-path.util';
import { trimEditorContent } from 'helpers/trim-editor-content.util';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';
import { useTeamClick } from 'hooks/use-team-click';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { CommentEditorBottomSheet } from 'components/bottom-sheet/editor-bottom-sheet/comment-editor-bottom-sheet.component';
import { Comment } from 'components/comment/comment.component';
import { EmptyState } from 'components/empty-state/empty-state.component';
import {
  CommentCreateDataType,
  CommentCreateForm,
} from 'components/forms/comment-create/comment-create-form.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { IAuthor } from 'components/modals/share-modal/share-modal.component';
import { SingleCommentModal } from 'components/modals/single-comment-modal/single-comment-modal.component';
import { ISharePublicationData } from 'components/posts/post-detailed/post-detailed.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { FloatingButton } from 'components/ui/floating-button/floating-button.component';
import { Loader } from 'components/ui/loader/loader.component';
import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import { Tabs } from 'components/ui/tabs/tabs.component';

import noCommentsSrc from 'assets/images/svg/no-comments.svg';

import styles from './top-level-comments.module.less';

interface ITopLevelCommentsContainerProps {
  postId: string;
  onShareClick: (comment?: ISharePublicationData) => void;
  onUpdatePostCommentsCount: (count: number) => void;
}

interface ICommentDeletePayload {
  postId: string;
  commentId: string;
}

export const TopLevelCommentsContainer = observer((props: ITopLevelCommentsContainerProps) => {
  const { postId, onUpdatePostCommentsCount, onShareClick } = props;

  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const bookmarksStore = useInjection<BookmarksStore>(TYPES.BookmarksStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const commentsStore = useInjection<CommentsStore>(TYPES.CommentsStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);

  const params = useParams<{
    [paths.TEAM_ID_PARAM]: string;
  }>();

  const { teamId } = params;

  const location = useLocation();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [sort, setSort] = useState(SortEnum.NEW);
  const [activeCommentEditorId, setActiveCommentEditorId] = useState('');
  const [prevActiveEditorId, setPrevActiveEditorId] = useState('');
  const [commentDeletePayload, setCommentDeletePayload] =
    useState<Maybe<ICommentDeletePayload>>(null);

  const [isCreateCommentSuccess, setIsCreateCommentSuccess] = useState(false);
  const [isNeedActivateEditor, setIsNeedActivateEditor] = useState(false);
  const [isEditorExpand, setIsEditorExpand] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCommentEditorShown, setIsCommentEditorShown] = useState(false);

  const getTeamId = useMemo(() => {
    if (teamId) {
      return Number(teamId);
    }

    return null;
  }, [teamId]);

  const playerId = useMemo(() => {
    if (postsStore.post?.feeds.feed?.type === PostsFeedType.Player) {
      return postsStore.post.feeds.feed.playerId;
    }

    return null;
  }, [postsStore.post]);

  const handleReplyClick = useCallback(
    (id: string) => {
      if (!authStore.isAuthorised) return;

      if (id === prevActiveEditorId) {
        setActiveCommentEditorId('');
        setPrevActiveEditorId('');
      } else {
        setActiveCommentEditorId(id);
        setPrevActiveEditorId(id);
      }
    },
    [authStore, prevActiveEditorId],
  );

  const handleAddCommentClick = useCallback(() => {
    if (!authStore.isAuthorised) return;

    if (isDesktopPlus) {
      setIsNeedActivateEditor(true);
    } else {
      setIsCommentEditorShown(true);
    }
  }, [authStore.isAuthorised, isDesktopPlus]);

  const handleCommentsSortClick = useCallback(
    (action: BarActionType) => {
      if (action.type === BarAction.Click && action.payload && isSortValue(action.payload)) {
        setSort(action.payload);
        commentsStore.setIsSortingComments(true);
      }
    },
    [commentsStore],
  );

  const handleShowModal = useCallback(
    (comment?: ISharePublicationData) => () => {
      onShareClick(comment);
    },
    [onShareClick],
  );

  const resetEditor = useCallback(() => {
    setActiveCommentEditorId('');
    setPrevActiveEditorId('');
    setIsCreateCommentSuccess(true);
    setIsCommentEditorShown(false);
  }, []);

  const handleResetScrollCommentId = useCallback(() => {
    commentsStore.resetScrollCommentId();
  }, [commentsStore]);

  const handleSubmitComment = useCallback(
    (commentId?: string) =>
      async ({ editor }: CommentCreateDataType) => {
        if (!authStore.isAuthorised || !postId) {
          return;
        }

        if (getTeamId && !teamsStore.isFollowedTeam) {
          teamsStore.setJoinModalVisible(true);

          return;
        }

        const comment: ICommentCreatePayload = {
          attachments: {
            videos: [],
          },
        };

        if (editor.content?.length && editor.content.length > 0) {
          comment.content = editor.content.value;

          const editorState = JSON.parse(editor?.content?.value);
          const content = editorState?.root?.children;

          const newEditorState = {
            ...editorState,
            root: {
              ...editorState.root,
              children: trimEditorContent(content),
            },
          };

          comment.content = JSON.stringify(newEditorState);
        }

        if (editor.attachments?.images) {
          comment.attachments.images = editor.attachments.images.map((attach) => attach.uuid);
        }

        if (editor.attachments?.videos) {
          comment.attachments.videos = editor.attachments.videos;
        }

        if (editor.attachments?.gifs) {
          comment.attachments.gifs = editor.attachments.gifs;
        }

        const updatedPost = await commentsStore.createComment(
          postId,
          comment,
          commentId || null,
          getTeamId,
          playerId,
          sort,
        );

        if (updatedPost) {
          onUpdatePostCommentsCount(updatedPost.postComments);
          resetEditor();
          queryClient.invalidateQueries({
            queryKey: reputationQueryKeys.userBalance(authStore.userMe?.username),
          });
        }
      },
    [
      sort,
      postId,
      playerId,
      getTeamId,
      authStore,
      teamsStore,
      commentsStore,
      resetEditor,
      onUpdatePostCommentsCount,
      queryClient,
    ],
  );

  const handleTogglePostCommentBookmark = useCallback(
    async (payload: IPostCommentBookmarkPayload) => {
      if (!authStore.triggerAuthorisationCheck()) return;

      await commentsStore.togglePostCommentBookmark(payload);
      await bookmarksStore.refresh();
    },
    [authStore, bookmarksStore, commentsStore],
  );

  const handlePollVote = useCallback(
    async (pollData: IPollVoteData) => {
      if (!authStore.isAuthorised || !postId) {
        return;
      }

      if (teamId && !teamsStore.isFollowedTeam) {
        teamsStore.setJoinModalVisible(true);

        return;
      }

      await commentsStore.commentPollVote(pollData);
      await bookmarksStore.refresh();
    },
    [teamId, postId, authStore, bookmarksStore, teamsStore, commentsStore],
  );

  const handlePollExpired = useCallback(
    async (currentPostId: string, commentId: Maybe<string>) => {
      if (!authStore.isAuthorised) return;

      if (commentId) {
        await commentsStore.fetchSingleComment({
          postId: currentPostId,
          isNeedUpdateNestedComments: false,
          commentId,
        });
      }

      await bookmarksStore.refresh();
    },
    [authStore, bookmarksStore, commentsStore],
  );

  const handlePostCommentLike = useCallback(
    async (postIdParam: string, commentId: string) => {
      if (!authStore.isAuthorised) return;

      const payload = {
        postId: postIdParam,
        commentId,
      };

      await commentsStore.postCommentLikes(payload);
    },
    [authStore, commentsStore],
  );

  const handleOpenPreview = useCallback(
    (author: IAuthor, slides: IInteractiveMediaAttachment[], date: string, index: number) => {
      const watermarkEntity = teamsStatsStore.team
        ? { name: teamsStatsStore.team.nickname, logoUrl: teamsStatsStore.team.mediumLogoUrl }
        : null;

      galleryStore.setGalleryAttachments(slides);
      galleryStore.setCurrentAttachmentId(index);
      galleryStore.setAuthor(author);
      galleryStore.setAttachmentDate(date);
      galleryStore.setIsGalleryOpen(true);
      galleryStore.setWatermarkEntity(watermarkEntity);
    },
    [galleryStore, teamsStatsStore],
  );

  const handleUploadVideo = useCallback(
    (file: File) => {
      commentsStore.uploadCommentVideo(file);
    },
    [commentsStore],
  );

  const handleRemoveVideo = useCallback(() => {
    commentsStore.setCommentVideo(null);
  }, [commentsStore]);

  const handleRemoveReplyVideo = useCallback(() => {
    commentsStore.setReplyVideo(null);
  }, [commentsStore]);

  const handleUploadReplyVideo = useCallback(
    (file: File) => {
      commentsStore.uploadReplyVideo(file);
    },
    [commentsStore],
  );

  const handleGetReplies = useCallback(
    (commentId: string, page: number) => {
      commentsStore.fetchNextReplies(commentId, page, sort);
    },
    [commentsStore, sort],
  );

  const handleShowMoreComments = useCallback(() => {
    commentsStore.fetchNextComments(postId, sort);
  }, [commentsStore, sort, postId]);

  const handleHideCommentModal = useCallback(() => {
    commentsStore.setBookmarkedComment(null);
    commentsStore.setIsShowBookmarkedCommentModal(false);
  }, [commentsStore]);

  const getFavoriteTeam = useCallback(
    (id: Maybe<number>): Maybe<ITeamsStats> => {
      return teamsStatsStore.findTeamById(id);
    },
    [teamsStatsStore],
  );

  const getFavoritePlayer = useCallback(
    (playerIdParam: Maybe<number>): Maybe<IPlayerStats> => {
      return teamsStatsStore.findPlayerById(playerIdParam);
    },
    [teamsStatsStore],
  );

  const handleCommentDelete = useCallback(
    async (postIdParam: string, commentId: string) => {
      if (!authStore.triggerAuthorisationCheck()) return;

      setCommentDeletePayload({
        postId: postIdParam,
        commentId,
      });

      setIsConfirmModalOpen(true);
    },
    [authStore],
  );

  const handleOnConfirmDeleteComment = useCallback(async () => {
    if (commentDeletePayload) {
      const deleteResult = await commentsStore.deleteComment(commentDeletePayload);

      if (deleteResult) {
        onUpdatePostCommentsCount(deleteResult.postComments);
      }

      setCommentDeletePayload(null);
      setIsConfirmModalOpen(false);
    }
  }, [commentDeletePayload, commentsStore, onUpdatePostCommentsCount]);

  const handleOnCloseConfirmation = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, []);

  const handleVideoError = useCallback(
    (message: string) => {
      commentsStore.setErrors([
        {
          message,
        },
      ]);
    },
    [commentsStore],
  );

  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const handleRemoveCommentFromEntries = useCallback(
    (commentId: string) => {
      if (!commentsStore.isSubmittedContentReport) {
        return;
      }

      commentsStore.setSubmittedContentReport(false);
      commentsStore.removeCommentFromEntries(commentId);
    },
    [commentsStore],
  );

  const handleReportSubmit = useCallback(
    async (postIdParam: string, commentId: string, reasonId: number) => {
      const response = await commentsStore.sendPostCommentReport(postIdParam, commentId, reasonId);

      return response;
    },
    [commentsStore],
  );

  const handleOpenEditorBottomSheet = useCallback(() => {
    if (authStore.triggerAuthorisationCheck()) {
      setIsCommentEditorShown(true);
    }
  }, [authStore]);

  const handleCloseEditorBottomSheet = useCallback(() => {
    setIsCommentEditorShown(false);
  }, []);

  useEffect(() => {
    if (!location.state?.focusEditor) {
      return;
    }

    if (isDesktopPlus) {
      setIsNeedActivateEditor(true);
      navigate(location.pathname, {
        replace: true,
      });

      return;
    }

    setIsCommentEditorShown(true);
  }, [location, isDesktopPlus, navigate]);

  useEffect(() => {
    if (!teamsStore.fetching) {
      commentsStore.initialiseTopLevelComments(postId, sort);
    }
  }, [teamsStore.fetching, postId, sort, authStore.isAuthorised, commentsStore]);

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      setSort(SortEnum.NEW);
      commentsStore.fetchByPullToRefresh(postId, SortEnum.NEW);
    }
  }, [commentsStore, layoutStore.isPulledRefresher, postId]);

  useEffect(() => {
    if (hash) {
      commentsStore.openBookmarkedComment({
        postId,
        commentId: getIdFromCommentHash(hash),
        isNeedUpdateNestedComments: false,
      });
    }
  }, [hash, postId, commentsStore]);

  return (
    <>
      <div className={styles.CommentsWrapper}>
        <Tabs
          activeTab={sort}
          tabs={COMMENTS_SORT_TABS_ACTIONS}
          onClick={handleCommentsSortClick}
        />
        {(!commentsStore.fetched && commentsStore.fetching) || commentsStore.isSortingComments ? (
          <div className={styles.CommentLoaderWrapper}>
            <Loader isShow />
          </div>
        ) : commentsStore.comments.length ? (
          <>
            {commentsStore.comments.map(
              ({
                uuid,
                user,
                isLiked,
                formattedDates,
                content,
                attachments,
                isBookmarked,
                likes,
                commentsAmount,
                comments: subComments,
                page,
              }) => (
                <Comment
                  isCurrentUserStaff={checkIsUserStaff(authStore.userMe?.roles)}
                  requestReasons={applicationStore.retrieveReportReasons}
                  isLiked={isLiked}
                  onPostCommentLike={handlePostCommentLike}
                  key={uuid}
                  convertImageItemToAttachment={applicationStore.convertImageItemToAttachment}
                  page={page}
                  commentsAmount={commentsAmount}
                  reportReasons={applicationStore.reportReasons}
                  isBookmarked={isBookmarked}
                  userId={authStore?.userMe?.id || null}
                  commentAuthor={{
                    uuid: user.uuid,
                    name: user.name,
                    username: user.username,
                    favoriteTeam: getFavoriteTeam(user.favoriteTeamId),
                    favoritePlayer: getFavoritePlayer(user.favoritePlayerId),
                    smallAvatarUrl: user.smallAvatarUrl,
                  }}
                  likes={likes}
                  formattedDates={formattedDates}
                  commentData={{
                    comment: content,
                    createAt: isDesktopPlus
                      ? formattedDates.relativeLong
                      : formattedDates.relativeShort,
                  }}
                  attachments={attachments}
                  activeCommentEditorId={activeCommentEditorId}
                  scrollCommentId={commentsStore.scrollCommentId}
                  id={uuid}
                  postId={postId}
                  playerId={playerId}
                  isVideoLoading={commentsStore.isReplyVideoLoading}
                  postVideo={commentsStore.commentVideo}
                  processing={commentsStore.fetchingCreateReply}
                  replyVideo={commentsStore.replyVideo}
                  team={teamsStatsStore.team}
                  comments={subComments}
                  onReplyClick={handleReplyClick}
                  onReplySubmit={handleSubmitComment}
                  onOpenPreview={handleOpenPreview}
                  getFavoriteTeam={getFavoriteTeam}
                  getFavoritePlayer={getFavoritePlayer}
                  onBookmarkClick={handleTogglePostCommentBookmark}
                  onDeleteClick={handleCommentDelete}
                  onPollVote={handlePollVote}
                  onPollExpired={handlePollExpired}
                  onShareSubComment={props.onShareClick}
                  onResetScrollCommentId={handleResetScrollCommentId}
                  onUploadVideo={handleUploadVideo}
                  onRemoveVideo={handleRemoveVideo}
                  onUploadReplyVideo={handleUploadReplyVideo}
                  onRemoveReplyVideo={handleRemoveReplyVideo}
                  onGetReplies={handleGetReplies}
                  onVideoError={handleVideoError}
                  onTeamClick={handleTeamClick}
                  onPlayerClick={handlePlayerClick}
                  onSendReport={handleReportSubmit}
                  onCloseReportsPopup={handleRemoveCommentFromEntries}
                  onShareClick={handleShowModal({
                    uuid,
                    user,
                    formattedDates,
                  })}
                  triggerAuthorisationCheck={authStore.triggerAuthorisationCheck}
                />
              ),
            )}
          </>
        ) : (
          <div className={styles.CommentsWrapper__EmptyState}>
            <EmptyState
              title="No Comments Yet"
              message="Be the first to say what you think"
              imgSrc={noCommentsSrc}
              btnText="Add Comment"
              onBtnClick={handleAddCommentClick}
            />
          </div>
        )}
      </div>
      {!!commentsStore.comments.length && !commentsStore.isLastPage && (
        <div className={styles.CommentsWrapper__ShowMore}>
          <Button
            fluid
            onClick={handleShowMoreComments}
            size={ButtonSize.SmallSecondary}
            theme={ButtonTheme.Tertiary}
            name="showMoreComment"
          >
            Show more comments
          </Button>
        </div>
      )}
      {!!(commentsStore.isShowBookmarkedCommentModal && commentsStore.bookmarkedComment) && (
        <SingleCommentModal
          postId={postId}
          visible={commentsStore.isShowBookmarkedCommentModal}
          comment={commentsStore.bookmarkedComment}
          favoriteTeam={getFavoriteTeam(commentsStore.bookmarkedComment.user.favoriteTeamId)}
          favoritePlayer={getFavoritePlayer(commentsStore.bookmarkedComment.user.favoritePlayerId)}
          onPollVote={handlePollVote}
          onPollExpired={handlePollExpired}
          onOpenPreview={handleOpenPreview}
          onTeamClick={handleTeamClick}
          onPlayerClick={handlePlayerClick}
          onClose={handleHideCommentModal}
        />
      )}
      {isDesktopPlus && (
        <div className={styles.CommentCreate}>
          <CommentCreateForm
            isFixedPosition
            isCreateCommentSuccess={isCreateCommentSuccess}
            isNeedActivateEditor={isNeedActivateEditor}
            isEditorExpand={isEditorExpand}
            processing={commentsStore.fetchingCreateComment}
            postVideo={commentsStore.commentVideo}
            isVideoLoading={commentsStore.isCommentVideoLoading}
            convertImageItemToAttachment={applicationStore.convertImageItemToAttachment}
            setIsCreateCommentSuccess={setIsCreateCommentSuccess}
            setIsNeedActivateEditor={setIsNeedActivateEditor}
            setIsEditorExpand={setIsEditorExpand}
            onVideoError={handleVideoError}
            onUploadVideo={handleUploadVideo}
            onRemoveVideo={handleRemoveVideo}
            onSubmit={handleSubmitComment()}
          />
        </div>
      )}
      {isConfirmModalOpen && (
        <ConfirmationModal
          content="Are you sure you want to delete this comment? This cannot be undone."
          onSuccessCallback={handleOnConfirmDeleteComment}
          onClose={handleOnCloseConfirmation}
          title="Delete Comment"
          visible
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
        />
      )}
      {!isDesktopPlus && (
        <FloatingButton
          hasSmartBanner={layoutStore.isDisplayedSmartBanner}
          onClick={handleOpenEditorBottomSheet}
        />
      )}
      {!isDesktopPlus && (
        <CommentEditorBottomSheet
          visible={isCommentEditorShown}
          isVideoLoading={commentsStore.isCommentVideoLoading}
          postVideo={commentsStore.commentVideo}
          processing={commentsStore.fetchingCreateComment}
          onCloseBottomSheet={handleCloseEditorBottomSheet}
          convertImageItemToAttachment={applicationStore.convertImageItemToAttachment}
          onVideoError={handleVideoError}
          onUploadVideo={handleUploadVideo}
          onRemoveVideo={handleRemoveVideo}
          onSubmit={handleSubmitComment()}
        />
      )}
    </>
  );
});
