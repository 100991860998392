import { useCallback, useEffect, useMemo, useState } from 'react';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListItemNode,
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListItemNode,
  ListNode,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createQuoteNode } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import cn from 'classnames';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';

import { LISTS_GROUPS } from 'configs/context-menu-groups.config';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { FloatingLinkEditor } from 'components/editor/plugins/toolbar/components/floating-link-editor/floating-link-editor.component';
import { getSelectedNode } from 'components/editor/utils/get-selected-node';
import { replaceListItemNode } from 'components/editor/utils/replace-list-item-node.util';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { IS_APPLE } from '../../environment/env';

import { BlockTypes } from './types/block-types.type';

import styles from './toolbar.module.less';

export const TopToolbarPlugin = (): JSX.Element => {
  const [editor] = useLexicalComposerContext();
  const [isLink, setIsLink] = useState(false);
  const [isLinkVisible, setIsLinkVisible] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [blockType, setBlockType] = useState<BlockTypes>('paragraph');

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();

      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();

              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
        setIsLinkVisible(true);
      } else {
        setIsLink(false);
        setIsLinkVisible(false);
      }

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          setBlockType(type);
        } else if (element.getType() === 'quote') {
          setBlockType('quote');
        } else {
          setBlockType('paragraph');
        }
      }
    }
  }, [editor]);

  const replaceListItem = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        const nodes = selection.getNodes();
        if (nodes.length === 1) {
          const anchorNode = selection.anchor.getNode();

          const lastListItem = $getNearestNodeOfType<ListItemNode>(anchorNode, ListItemNode);

          if (lastListItem) {
            replaceListItemNode(lastListItem, selection.format);
          }
        } else {
          nodes.forEach((node) => {
            if ($isListItemNode(node)) {
              replaceListItemNode(node, selection.format);
            }
          });
        }
      }
    });
  }, [editor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateToolbar();

        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
    );
  }, [editor, updateToolbar]);

  const handleFormatBulletList = useCallback(() => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      replaceListItem();
    }
  }, [blockType, editor, replaceListItem]);

  const handleFormatNumberedList = useCallback(() => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      replaceListItem();
    }
  }, [blockType, editor, replaceListItem]);

  const handleFormatQuote = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        if (blockType !== 'quote') {
          $setBlocksType(selection, () => $createQuoteNode());
        } else {
          $setBlocksType(selection, () => $createParagraphNode());
          setBlockType('paragraph');
        }
      }
    });
  }, [blockType, editor]);

  const handleInsertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, '');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const handleBold = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
  }, [editor]);

  const handleItalic = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
  }, [editor]);

  const handleStrikethrough = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
  }, [editor]);

  const handleListClick = useCallback(
    (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click) {
        if (barAction.payload === 'bullet') {
          handleFormatBulletList();
        } else {
          handleFormatNumberedList();
        }
      }
    },
    [handleFormatBulletList, handleFormatNumberedList],
  );

  const toolbarClasses = useMemo(() => cn(styles.Toolbar, styles['Toolbar--top']), []);

  return (
    <div className={toolbarClasses}>
      {isLink && isLinkVisible && <FloatingLinkEditor editorProp={editor} />}
      <IconButton
        theme={IconButtonTheme.Secondary}
        disabled={!isEditable}
        selected={isBold}
        iconName={IconFontName.Bold}
        textLabel={IS_APPLE ? 'Bold (⌘B)' : 'Bold (Ctrl+B)'}
        onClick={handleBold}
      />
      <IconButton
        theme={IconButtonTheme.Secondary}
        iconName={IconFontName.Italic}
        selected={isItalic}
        disabled={!isEditable}
        textLabel={IS_APPLE ? 'Italic (⌘I)' : 'Italic (Ctrl+I)'}
        onClick={handleItalic}
      />
      <IconButton
        theme={IconButtonTheme.Secondary}
        iconName={IconFontName.Strikethrough}
        selected={isStrikethrough}
        onClick={handleStrikethrough}
      />
      <ContextMenuTooltip
        toggleOnClick
        tooltipProps={{ placement: 'top', tooltipOffset: 12, closeOnContentClick: true }}
        groups={LISTS_GROUPS}
        selectedItem={blockType}
        onItemClick={handleListClick}
      >
        <IconButton
          theme={IconButtonTheme.Secondary}
          iconName={IconFontName.ListAll}
          selected={blockType === 'number' || blockType === 'bullet'}
        />
      </ContextMenuTooltip>
      <IconButton
        theme={IconButtonTheme.Secondary}
        iconName={IconFontName.Quote}
        selected={blockType === 'quote'}
        onClick={handleFormatQuote}
      />
      <IconButton
        theme={IconButtonTheme.Secondary}
        iconName={IconFontName.Link}
        selected={isLink}
        onClick={handleInsertLink}
      />
    </div>
  );
};
