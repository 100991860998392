import { memo, useMemo } from 'react';
import cn from 'classnames';
import { GameLineScoreType } from 'query-hooks/scoreboard/types/game-line-score.type';
import { GameQuarter } from 'query-hooks/scoreboard/types/game-quarter.type';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import scoreboardGridStyles from '../scoreboard-grid.module.less';
import styles from './scoreboard-line-score-row.module.less';

const NO_SCORE = '-';
const NO_SCORE_CURRENT_QUARTER = '0';

interface IScoreboardLineScoreRowProps {
  isWinner: boolean;
  total: Maybe<number>;
  lineScore: GameLineScoreType;
  currentQuarter: Maybe<GameQuarter>;
}

export const ScoreboardLineScoreRow = memo((props: IScoreboardLineScoreRowProps) => {
  const { isWinner, total, lineScore, currentQuarter } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const lineScorePrepared = useMemo<{ score: string; quarterSlug: string }[]>(
    () =>
      lineScore.map((score, index) => {
        const noScore = currentQuarter === index + 1 ? NO_SCORE_CURRENT_QUARTER : NO_SCORE;

        if (currentQuarter && currentQuarter <= 3) {
          return {
            quarterSlug: `Q${index + 1}`,
            score: score !== null ? String(score) : noScore,
          };
        }

        if (index === 4) {
          return {
            quarterSlug: `OT`,
            score: score !== null ? String(score) : noScore,
          };
        }

        return {
          quarterSlug: `${index - 3}OT`,
          score: score !== null ? String(score) : noScore,
        };
      }),
    [currentQuarter, lineScore],
  );

  const classNames = useMemo<string>(
    () =>
      cn(styles.ScoreboardLineScoreRow, scoreboardGridStyles.ScoreboardGrid, {
        [styles['ScoreboardLineScoreRow--is-winner']]: isWinner,
      }),
    [isWinner],
  );

  const quarterClassNames = useMemo(
    () => cn(styles.ScoreboardLineScoreRow__Quarter, scoreboardGridStyles.ScoreboardGrid__Column),
    [],
  );

  const totalClassNames = useMemo(
    () => cn(styles.ScoreboardLineScoreRow__Total, scoreboardGridStyles.ScoreboardGrid__Column),
    [],
  );

  return (
    <div className={classNames}>
      {isDesktopPlus &&
        lineScorePrepared.map(({ quarterSlug, score }) => (
          <div key={quarterSlug} className={quarterClassNames}>
            {score}
          </div>
        ))}
      <div className={totalClassNames}>
        {total === null ? NO_SCORE : total}
        {isWinner && (
          <div className={styles.ScoreboardLineScoreRow__Arrow}>
            <IconFont name={IconFontName.MarkerCollapsed} size={IconFontSize.Small} />
          </div>
        )}
      </div>
    </div>
  );
});
