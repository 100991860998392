import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { parse } from 'date-fns';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { ICommentCreatePayload } from 'services/posts/interfaces/create-comment-payload.interface';
import { IPublicationPayload } from 'services/posts/interfaces/publication-payload.interface';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { GameStore } from 'stores/game/game.store';
import { GameChatStore } from 'stores/game-chat/game-chat.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import { trimEditorContent } from 'helpers/trim-editor-content.util';
import * as paths from 'routes/paths.constants';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';
import { useResponsive } from 'hooks/use-responsive';
import { useTeamClick } from 'hooks/use-team-click';

import { TextCommentEditorBottomSheet } from 'components/bottom-sheet/editor-bottom-sheet/text-comment-editor-bottom-sheet.component';
import { EmptyState } from 'components/empty-state/empty-state.component';
import { CommentCreateDataType } from 'components/forms/comment-create/comment-create-form.component';
import { TextCommentCreateForm } from 'components/forms/text-comment-create/text-comment-create-form.component';
import { GameChatComment } from 'components/game/game-chat-message/game-chat-message.component';
import { Countdown } from 'components/game/game-countdown/game-countdown.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { IAuthor } from 'components/modals/share-modal/share-modal.component';
import { FloatingButton } from 'components/ui/floating-button/floating-button.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './game-chat.module.less';

const GAME_CHAT_MESSAGES_AMOUNT_BEFORE_PRE_LOAD = 10;

export const GameChatContainer: FC = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const gameStore = useInjection<GameStore>(TYPES.GameStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [commentDeleteId, setCommentDeleteId] = useState<Maybe<string>>(null);
  const gameChatStore = useInjection<GameChatStore>(TYPES.GameChatStore);

  const navigate = useNavigate();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [isCreateCommentSuccess, setIsCreateCommentSuccess] = useState(false);
  const [isNeedActivateEditor, setIsNeedActivateEditor] = useState(false);
  const [isEditorExpand, setIsEditorExpand] = useState(false);
  const [isCreateBottomSheetShown, setIsCreateBottomSheetShown] = useState(false);

  const handleResetScrollCommentId = useCallback(() => {
    gameChatStore.resetScrollCommentId();
  }, [gameChatStore]);

  const handleCommentCreate = useCallback(
    async (comment: IPublicationPayload) => {
      if (authStore.isAuthorised && gameStore.id) {
        const updatedPost = await gameChatStore.createGameChatMessage(gameStore.id, comment);

        if (updatedPost) {
          setIsCreateCommentSuccess(true);
          setIsCreateBottomSheetShown(false);
        }
      }
    },
    [authStore, gameChatStore, gameStore.id],
  );

  const handleCommentDelete = useCallback((commentId: string) => {
    setCommentDeleteId(commentId);
    setIsConfirmModalOpen(true);
  }, []);

  const handleOnConfirmDeleteComment = useCallback(async () => {
    if (gameStore.id && commentDeleteId) {
      await gameChatStore.deleteGameChatMessage(gameStore.id, commentDeleteId);
      setCommentDeleteId(null);
      setIsConfirmModalOpen(false);
    }
  }, [gameChatStore, gameStore.id, commentDeleteId]);

  const handleOnCloseConfirmation = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, []);

  const handleSubmitComment = useCallback(
    () =>
      ({ editor }: CommentCreateDataType) => {
        const comment: ICommentCreatePayload = {
          attachments: {},
        };

        if (editor.content?.length && editor.content.length > 0) {
          comment.content = editor.content.value;
          const editorState = JSON.parse(editor?.content?.value);
          const content = editorState?.root?.children;

          const newEditorState = {
            ...editorState,
            root: {
              ...editorState.root,
              children: trimEditorContent(content),
            },
          };

          comment.content = JSON.stringify(newEditorState);
        }

        handleCommentCreate(comment);
      },
    [handleCommentCreate],
  );

  const handleLikes = useCallback(
    async (messageId: string) => {
      if (authStore.isAuthorised && gameStore.id) {
        const payload = {
          gameId: gameStore.id,
          messageId,
        };

        await gameChatStore.gameChatMessageLike(payload);
        gameChatStore.setScrollCommentId(messageId);
      }
    },
    [authStore, gameChatStore, gameStore.id],
  );

  const handleOpenPreview = useCallback(
    (author: IAuthor, slides: IInteractiveMediaAttachment[], date: string, index: number) => {
      galleryStore.setGalleryAttachments(slides);
      galleryStore.setCurrentAttachmentId(index);
      galleryStore.setAuthor(author);
      galleryStore.setAttachmentDate(date);
      galleryStore.setIsGalleryOpen(true);
      galleryStore.setWatermarkEntity(null);
    },
    [galleryStore],
  );

  const handleLoadMore = useCallback(() => {
    if (gameStore.id) {
      gameChatStore.fetchNextGameChatMessages(gameStore.id);
    }
  }, [gameChatStore, gameStore]);

  const handleAddMessageClick = useCallback(() => {
    if (!authStore.isAuthorised) return;

    if (isDesktopPlus) {
      setIsNeedActivateEditor(true);

      return;
    }

    setIsCreateBottomSheetShown(true);
  }, [isDesktopPlus, authStore]);

  const getFavoritePlayer = useCallback(
    (playerId: Maybe<number>): Maybe<IPlayerStats> => {
      return teamsStatsStore.findPlayerById(playerId);
    },
    [teamsStatsStore],
  );

  const getFavoriteTeam = useCallback(
    (teamId: Maybe<number>): Maybe<ITeamsStats> => {
      return teamsStatsStore.findTeamById(teamId);
    },
    [teamsStatsStore],
  );

  const gameStartTimestamp = useMemo(() => {
    const gameDate = gameStore.entry?.gameDateTimeStart || '';
    const dateObject = parse(gameDate, 'h:mm a, MMMM dd, yyyy', new Date());

    return dateObject.getTime();
  }, [gameStore.entry]);

  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const handleRemoveGameChatItemFromEntries = useCallback(
    (gameChatItemId: string) => {
      if (!gameChatStore.isSubmittedContentReport) {
        return;
      }

      gameChatStore.setSubmittedContentReport(false);
      gameChatStore.removeGameChatItemFromEntries(gameChatItemId);
    },
    [gameChatStore],
  );

  const handleGameChatItemReportSubmit = useCallback(
    async (gameChatItemId: string, gameId: number, reasonId: number) => {
      return gameChatStore.sendGameChatItemReport(gameChatItemId, gameId, reasonId);
    },
    [gameChatStore],
  );

  const handleOpenEditorBottomSheet = useCallback(() => {
    if (authStore.triggerAuthorisationCheck()) {
      setIsCreateBottomSheetShown(true);
    }
  }, [authStore]);

  const handleCloseEditorBottomSheet = useCallback(() => {
    setIsCreateBottomSheetShown(false);
  }, []);

  const hasMore = useMemo(
    () => !gameChatStore.fetching && !gameChatStore.isLastPage,
    [gameChatStore.fetching, gameChatStore.isLastPage],
  );

  const setIntersectionObserver = useInfiniteScroll(hasMore, handleLoadMore);

  return (
    <>
      {gameStore.entry?.status === GameStatus.Scheduled && (
        <Countdown
          gameStatus={gameStore.entry?.status}
          label="Game starts in"
          targetDate={gameStartTimestamp}
        />
      )}
      <div className={styles.CommentsWrapper}>
        {gameChatStore.fetched && !gameChatStore.entries.length && (
          <EmptyState
            isBlackBackground
            title="No comments yet"
            message="Be the first to say what you think"
            btnText="Add Message"
            onBtnClick={handleAddMessageClick}
          />
        )}
        {!!gameChatStore.entries.length && (
          <div className={styles.CommentsList}>
            {gameStore.entry &&
              gameChatStore.entries.map((item, index) => (
                <div
                  ref={
                    gameChatStore.entries.length < index + GAME_CHAT_MESSAGES_AMOUNT_BEFORE_PRE_LOAD
                      ? setIntersectionObserver
                      : undefined
                  }
                  key={item.uuid}
                >
                  <GameChatComment
                    requestReasons={applicationStore.retrieveReportReasons}
                    isLiked={item.isLiked}
                    reportReasons={applicationStore.reportReasons}
                    isMyComment={item.user.uuid === authStore?.userMe?.id}
                    onOpenPreview={handleOpenPreview}
                    key={item.uuid}
                    author={{
                      uuid: item.user.uuid,
                      name: item.user.name,
                      username: item.user.username,
                      favoriteTeam: getFavoriteTeam(item.user.favoriteTeamId),
                      favoritePlayer: getFavoritePlayer(item.user.favoritePlayerId),
                      smallAvatarUrl: item.user.smallAvatarUrl,
                    }}
                    likes={item.likes}
                    commentData={{
                      comment: item.content,
                      createAt: isDesktopPlus
                        ? item.formattedDates.relativeLong
                        : item.formattedDates.relativeShort,
                    }}
                    attachments={item.attachments}
                    onLikes={handleLikes}
                    onDeleteComment={handleCommentDelete}
                    onResetScrollCommentId={handleResetScrollCommentId}
                    scrollCommentId={gameChatStore.scrollCommentId}
                    messageId={item.uuid}
                    gameId={gameStore.id || 0}
                    onTeamClick={handleTeamClick}
                    onPlayerClick={handlePlayerClick}
                    onSendGameChatItemReport={handleGameChatItemReportSubmit}
                    onCloseReportsPopup={handleRemoveGameChatItemFromEntries}
                  />
                </div>
              ))}
          </div>
        )}
        {gameChatStore.fetching && (
          <div className={styles.CommentLoaderWrapper}>
            <Loader isShow />
          </div>
        )}
      </div>
      {isDesktopPlus && (
        <div className={styles.CommentCreate}>
          <TextCommentCreateForm
            placeholder="Add comment"
            isFixedPosition
            isCreateCommentSuccess={isCreateCommentSuccess}
            isNeedActivateEditor={isNeedActivateEditor}
            isEditorExpand={isEditorExpand}
            processing={gameChatStore.processingCreateComment}
            setIsCreateCommentSuccess={setIsCreateCommentSuccess}
            setIsNeedActivateEditor={setIsNeedActivateEditor}
            setIsEditorExpand={setIsEditorExpand}
            onSubmit={handleSubmitComment()}
          />
        </div>
      )}
      {!isDesktopPlus && (
        <FloatingButton
          hasSmartBanner={layoutStore.isDisplayedSmartBanner}
          onClick={handleOpenEditorBottomSheet}
        />
      )}
      {!isDesktopPlus && (
        <TextCommentEditorBottomSheet
          visible={isCreateBottomSheetShown}
          processing={gameChatStore.processingCreateComment}
          onSubmit={handleSubmitComment()}
          onCloseBottomSheet={handleCloseEditorBottomSheet}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmationModal
          content="Are you sure you want to delete this comment? This cannot be undone."
          onSuccessCallback={handleOnConfirmDeleteComment}
          onClose={handleOnCloseConfirmation}
          title="Delete Comment"
          visible
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
        />
      )}
    </>
  );
});
