import { NotificationsStore } from 'stores/notifications/notifications.store';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

export const handleQueryNotificationsErrors = (error: Error | INotificationMessage[]) => {
  const notificationStore = container.get<NotificationsStore>(TYPES.NotificationsStore);

  if (Array.isArray(error)) {
    notificationStore.setErrors(error);
  }
};
