import { memo } from 'react';
import { IGameDetailedRecord } from 'query-hooks/scoreboard/interfaces/game-detailed-record.interface';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { SeasonType } from 'services/seasons/enums/season-type.enum';
import { IGamePeriods } from 'services/statistics/interface/game.interface';

import { GameDetailsTabs } from 'components/game/game.config';
import { VerticalRowsTable } from 'components/scoreboard/scoreboard-table/vertical-rows-table/vertical-rows-table.component';

import styles from './scoreboard-table-details.module.less';

interface IScoreboardTableDetailsProps {
  status: GameStatus;
  home: IGameDetailedRecord;
  visitors: IGameDetailedRecord;
  periods: IGamePeriods;
  clock: Maybe<string>;
  seasonType: SeasonType;
  onPlayerClick: (slug: string) => void;
  onTeamClick: (teamId: number) => void;
  onGameClick: (tabQuery: Maybe<GameDetailsTabs>) => void;
}

export const ScoreboardTableDetails = memo((props: IScoreboardTableDetailsProps) => {
  const { status, home, visitors, periods, clock, seasonType } = props;

  return (
    <div className={styles.ScoreboardTableDetails} role="row">
      <div role="cell" className={styles.ScoreboardTableDetails__Game}>
        <VerticalRowsTable
          total={periods.total}
          home={home}
          visitors={visitors}
          status={status}
          currentQuarter={periods.current}
          clock={clock}
          seasonType={seasonType}
          onPlayerClick={props.onPlayerClick}
          onTeamClick={props.onTeamClick}
          onGameClick={props.onGameClick}
        />
      </div>
    </div>
  );
});
