import { FC, useMemo } from 'react';

import { POSTS_AMOUNT_BEFORE_PRE_LOAD } from 'stores/entries/constants/entries.contstants';
import {
  IPreparedSearchItem,
  ISearchPlayerItem,
  ISearchTeamItem,
  SearchResultType,
} from 'stores/search/interfaces/search.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';
import { useResponsive } from 'hooks/use-responsive';

import { BarActionType } from 'components/bars/bar-action.type';
import { EmptyState } from 'components/empty-state/empty-state.component';
import { SEARCH_TABS, SearchFilters } from 'components/global-search/global-search.config';
import { GlobalSearchItem } from 'components/global-search/global-search-item/global-search-item.component';
import { Loader, LoaderSizeEnum } from 'components/ui/loader/loader.component';
import { Tabs } from 'components/ui/tabs/tabs.component';

import styles from './search-results.module.less';

interface ISearchResultProps {
  searchValue: string;
  items: IPreparedSearchItem[];
  filter: SearchFilters;
  onFollowClick?: (resultId: number, type: SearchResultType, entityId: number) => Promise<void>;
  onChangeFilter: (action: BarActionType) => void;
  allCount: number;
  teamsCount: number;
  playersCount: number;
  hasMore: boolean;
  loading: boolean;
  fetched: boolean;
  onLoadMore: () => void;
  onItemClick: (options: ISearchTeamItem | ISearchPlayerItem) => void;
}

export const SearchResult: FC<ISearchResultProps> = (props) => {
  const {
    items,
    filter,
    loading,
    fetched,
    allCount,
    teamsCount,
    playersCount,
    searchValue,
    onLoadMore,
    hasMore,
  } = props;

  const setIntersectionObserver = useInfiniteScroll(hasMore, onLoadMore);
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const tabs = useMemo(() => {
    return SEARCH_TABS.map((item) => {
      if (item.id === SearchFilters.ALL) {
        return {
          ...item,
          title: `${item.title} (${allCount})`,
        };
      }

      if (item.id === SearchFilters.TEAM) {
        return {
          ...item,
          title: `${item.title} (${teamsCount})`,
        };
      }

      if (item.id === SearchFilters.PLAYER) {
        return {
          ...item,
          title: `${item.title} (${playersCount})`,
        };
      }
      return item;
    });
  }, [allCount, playersCount, teamsCount]);

  return (
    <div className={styles.SearchResult}>
      <Tabs fullWidth activeTab={filter} tabs={tabs} onClick={props.onChangeFilter} />
      <div className={styles.SearchResult__Items}>
        {!items.length && !loading && (
          <EmptyState
            isNoImage={isDesktopPlus}
            isBlackBackground
            message={`No results for '${searchValue}' found`}
          />
        )}

        {items.map((item, index: number) => {
          return (
            <div
              ref={
                items.length < index + POSTS_AMOUNT_BEFORE_PRE_LOAD
                  ? setIntersectionObserver
                  : undefined
              }
              key={item.id}
              className={styles.PostsFeed__Cards__Item}
            >
              <GlobalSearchItem
                key={item.id}
                item={item}
                isShowFollowButton
                onFollowClick={props.onFollowClick}
                onItemClick={props.onItemClick}
              />
            </div>
          );
        })}

        {!fetched && loading && (
          <div className={styles.LoaderWrapper}>
            <Loader size={LoaderSizeEnum.M} isShow />
          </div>
        )}
      </div>
    </div>
  );
};
