import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { scoreboardQueryKeys } from 'query-hooks/scoreboard/constants/query-keys.constants';

import { GamesDetailedService } from 'services/games-detailed/games-detailed.service';
import { INearestDateResponse } from 'services/games-detailed/interfaces/game-detailed-response.interface';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const CACHE_TIME = 3 * 60 * 1000; // 3 minutes

export const retrieveCurrentDate = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof scoreboardQueryKeys)['currentDate']>
>): Promise<INearestDateResponse | null | void> => {
  const gamesDetailedService = container.get<GamesDetailedService>(TYPES.GamesDetailedService);
  const [, , { timezone }] = queryKey;

  const response = await gamesDetailedService.fetchNearestDate(timezone);

  if (response.success) {
    return response.data ?? null;
  }

  return Promise.reject(response.errors);
};

export const useCurrentDateQuery = (timezone: string) => {
  return useQuery({
    queryKey: scoreboardQueryKeys.currentDate(timezone),
    queryFn: retrieveCurrentDate,
    gcTime: CACHE_TIME,
    staleTime: CACHE_TIME,
  });
};
