import { useCallback, useEffect, useRef } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { SearchScreen, SearchStore } from 'stores/search/search.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { SearchForm } from 'components/forms/search-form/search-form.component';
import { SearchFilters } from 'components/global-search/global-search.config';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './global-search-input.module.less';

interface IGlobalSearchInputContainerProps {
  isEnableSearchButton: boolean;
  onFocusCallback?: () => void;
  onBlurCallback?: () => void;
}

export const GlobalSearchInputContainer = observer((props: IGlobalSearchInputContainerProps) => {
  const { isEnableSearchButton, onBlurCallback } = props;

  const searchStore = useInjection<SearchStore>(TYPES.SearchStore);

  const isSearchInitRef = useRef(false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const searchPhrase = searchParams.get(paths.SEARCH_PARAM) || '';

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEffect(() => {
    if (searchPhrase && !isSearchInitRef.current) {
      searchStore.setQuery({ searchPhrase });
      isSearchInitRef.current = true;
    }
  }, [searchStore, searchPhrase]);

  useEffect(() => {
    if (!searchStore.query.searchPhrase.length) {
      searchStore.setScreen(SearchScreen.RecentHistory);
    }

    if (searchStore.query.searchPhrase.length > 1) {
      searchStore.setScreen(SearchScreen.Suggestion);
    }
  }, [searchStore.query.searchPhrase, isDesktopPlus, searchStore]);

  const handleResetSearch = useCallback(() => {
    searchStore.setScreen(SearchScreen.RecentHistory);
    searchStore.setQuery({ searchPhrase: '' });
  }, [searchStore]);

  const handleBlur = useCallback(() => {
    onBlurCallback?.();
  }, [onBlurCallback]);

  const handleSearchChange = useCallback(
    (value: string) => {
      searchStore.setQuery({ searchPhrase: value });
    },
    [searchStore],
  );

  const handleSearchButtonClick = useCallback(() => {
    searchStore.setFilter(SearchFilters.ALL);
    searchStore.setScreen(SearchScreen.SearchResults);
    searchStore.createNewRecentItem();
  }, [searchStore]);

  const handleSubmitSearch = useCallback(() => {
    if (isDesktopPlus) {
      searchStore.refresh();

      const queryParams = createSearchParams({
        [paths.SEARCH_PARAM]: searchStore.query.searchPhrase,
      });

      navigate({
        pathname: paths.SEARCH,
        search: queryParams.toString(),
      });
    }

    handleSearchButtonClick();
  }, [searchStore, isDesktopPlus, handleSearchButtonClick, navigate]);

  return (
    <div className={styles.SearchInput}>
      <SearchForm
        disableFocusByDefault={false}
        isNeedForceInputFocus={searchStore.isNeedForceInputFocus}
        setIsNeedForceInputFocus={searchStore.setIsNeedForceInputFocus}
        initialValues={searchStore.query}
        value={searchStore.query}
        onSubmit={handleSubmitSearch}
        onBlur={handleBlur}
        onChange={handleSearchChange}
        rightIconName={IconFontName.Close}
        onRightIconClick={handleResetSearch}
        onFocus={props.onFocusCallback}
        placeholder="Search Teams or Players"
      />
      {isEnableSearchButton && (
        <Button
          disabled={searchStore.query.searchPhrase.length < 2}
          onClick={handleSearchButtonClick}
          size={ButtonSize.Big}
          theme={ButtonTheme.Text}
          isRandomId
        >
          Search
        </Button>
      )}
    </div>
  );
});
