import { IUserPublicResponse } from 'services/user-public/interfaces/user-public-response.interface';

import { publicLinkAdapterUtil } from 'stores/auth/adapters/public-link-adapter.util';
import { userProfileAthleteAdapter } from 'stores/auth/adapters/user-profile-athlete-adapter.util';
import { LinkType } from 'stores/auth/interfaces/custom-link.interface';

import { IUserPublic } from '../interfaces/user-public.interface';

export function userPublicAdapter(userMe: IUserPublicResponse): IUserPublic {
  const { email, username, phone, profile, id, athlete, links } = userMe;

  const allLInks = links?.map(publicLinkAdapterUtil).sort((a, b) => a.id - b.id) ?? [];

  return {
    athlete: athlete ? userProfileAthleteAdapter(athlete) : null,
    id,
    email,
    username,
    phone,
    biography: profile?.bio,
    logo: profile.logo,
    realName: profile.real_name,
    status: profile.status,
    favoritePlayerId: profile?.favorite_player_id,
    favoriteTeamId: profile?.favorite_team_id,
    avatarUrl: profile.avatar_url,
    smallAvatarUrl: profile.small_avatar_url,
    thumbnailUrl: profile.thumbnail_url,
    smallThumbnailUrl: profile.small_thumbnail_url,
    thumbnailNicknameUrl: profile.thumbnail_nickname_url,
    thumbnailLogoUrl: profile.thumbnail_logo_url,
    customLinks: allLInks.filter((link) => link.type === LinkType.Custom),
    socialLinks: allLInks.filter((link) => link.type !== LinkType.Custom),
  };
}
