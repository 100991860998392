import { useCallback, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { videoPostEditorSchema } from 'validation/schemas/editor.schema';

import {
  EditorDataType,
  EditorTheme,
  EditorType,
} from 'components/editor/components/base-editor/base-editor.component';
import { Editor } from 'components/editor/editor.component';
import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './create-video-post-form.module.less';

const createVideoPostFormSchema = z.object({
  editor: videoPostEditorSchema,
});

export type CreateVideoPostFormDataType = z.infer<typeof createVideoPostFormSchema>;

interface ICreateGroupPostFormProps extends IForm<CreateVideoPostFormDataType> {
  isFullHeight?: boolean;
  isPostVideoLoading: boolean;
  postVideo: Maybe<IVideoResponse>;
  onUploadVideo: (video: File) => void;
  onRemoveVideo: () => void;
  onFormStateChange: (flag: boolean) => void;
  onVideoError?: (error: string) => void;
}

export const CreateVideoPostForm = (props: ICreateGroupPostFormProps) => {
  const {
    processing,
    initialValues,
    submitText,
    postVideo,
    isFullHeight = false,
    isPostVideoLoading,
    onSubmit,
    onFormStateChange,
  } = props;

  const editorWrapperRef = useRef<HTMLDivElement>(null);

  const { control, formState, handleSubmit } = useForm<CreateVideoPostFormDataType>({
    defaultValues: initialValues,
    mode: 'onChange',
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(createVideoPostFormSchema),
  });

  const { isValid } = formState;

  const handleEditorChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: EditorDataType) => onChange(value),
    [],
  );

  useEffect(() => {
    onFormStateChange(formState.isDirty);
  }, [formState.isDirty, onFormStateChange]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.PostCreateForm}>
        <div ref={editorWrapperRef} className={styles.PostCreateForm__Editor}>
          <div className={styles.PostCreateForm__EditorLabel}>Message</div>
          <Controller
            name="editor"
            control={control}
            render={({ field }) => (
              <Editor
                isFullHeight={isFullHeight}
                id="editor"
                name="editor"
                isExpand
                isSendHidden
                isEnabledAutoScrolling
                editorType={EditorType.VideoPost}
                theme={EditorTheme.BottomSheet}
                onChange={handleEditorChange(field.onChange)}
                value={field.value}
                isNeedForceExpand
                postVideo={postVideo}
                onUploadVideo={props.onUploadVideo}
                isVideoLoading={isPostVideoLoading}
                onRemoveVideo={props.onRemoveVideo}
                onVideoError={props.onVideoError}
              />
            )}
          />
        </div>
        <div className={styles.PostCreateForm__Footer}>
          <div className={styles.PostCreateForm__CreateBtn}>
            <Button
              type="submit"
              size={ButtonSize.Big}
              theme={ButtonTheme.Primary}
              disabled={!isValid || processing}
              iconName={IconFontName.SendFilled}
            >
              {submitText}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};
