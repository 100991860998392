import { FC, useCallback, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useDaysStreakQuery } from 'query-hooks/reputation/use-days-streak.query';

import { LayoutStore } from 'stores/layout/layout.store';
import { ReputationsPointsStore } from 'stores/reputations-points/reputations-points.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { UserDetails } from 'components/user-details/user-details.component';

import styles from './user-details-container.module.less';

export const UserDetailsContainer: FC = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const reputationsPointsStore = useInjection<ReputationsPointsStore>(TYPES.ReputationsPointsStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const { data: daysStreak = null } = useDaysStreakQuery(
    userPublicStore.userPopUpDetails?.username,
  );

  const handleClosePopUp = useCallback(() => {
    layoutStore.setIsUserDetailsPopUpOpen(false);
    userPublicStore.setUserPopUpDetails(null);
  }, [layoutStore, userPublicStore]);

  const favoriteTeam = useMemo(
    () =>
      teamsStatsStore.teams.find(
        (team) => team.id === userPublicStore.userPopUpDetails?.favoriteTeamId,
      ) || null,
    [userPublicStore.userPopUpDetails, teamsStatsStore.teams],
  );

  const favoritePlayer = useMemo(
    () =>
      teamsStatsStore.players.find(
        (player) => player.id === userPublicStore.userPopUpDetails?.favoritePlayerId,
      ) || null,
    [userPublicStore.userPopUpDetails, teamsStatsStore.players],
  );

  if (!userPublicStore.userPopUpDetails) {
    return null;
  }

  if (isDesktopPlus) {
    return (
      <BaseModalComponent
        visible={layoutStore.isUserDetailsPopUpOpen}
        size={ModalWindowSize.M}
        title={null}
        closeOnOverlayClick
        onClose={handleClosePopUp}
      >
        <UserDetails
          daysStreak={daysStreak}
          reputationPoints={reputationsPointsStore.userPopUpReputationPoints}
          avatarSrc={userPublicStore.userPopUpDetails.avatarUrl}
          player={favoritePlayer}
          team={favoriteTeam}
          thumbnailURL={userPublicStore.userPopUpDetails.smallThumbnailUrl}
          username={userPublicStore.userPopUpDetails.username}
          realName={userPublicStore.userPopUpDetails.name}
          onClose={handleClosePopUp}
        />
      </BaseModalComponent>
    );
  }

  return (
    <div className={styles.UserDetails}>
      <BaseIonBottomSheet
        isAutoHeight
        isOverflowVisible
        visible={layoutStore.isUserDetailsPopUpOpen}
        safeAreaBottom={0}
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        onClose={handleClosePopUp}
      >
        <UserDetails
          daysStreak={daysStreak}
          reputationPoints={reputationsPointsStore.userPopUpReputationPoints}
          avatarSrc={userPublicStore.userPopUpDetails.avatarUrl}
          player={favoritePlayer}
          team={favoriteTeam}
          thumbnailURL={userPublicStore.userPopUpDetails.smallThumbnailUrl}
          username={userPublicStore.userPopUpDetails.username}
          realName={userPublicStore.userPopUpDetails.name}
          onClose={handleClosePopUp}
        />
      </BaseIonBottomSheet>
    </div>
  );
});
