import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { reputationQueryKeys } from 'query-hooks/reputation/contastants/query-keys.constants';

import { ICollaborationItemPayload } from 'services/collaboration/interfaces/collaboration.interface';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { CollaborationStore } from 'stores/collaboration/collaboration.store';
import { ContentCardsStore } from 'stores/content-cards/content-cards.store';
import { IBasePublicationAuthor } from 'stores/entries/interfaces/entries-autor.interface';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { HeadlinesStore } from 'stores/headlines/headlines.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { IInteractiveMediaAttachment, IPost } from 'stores/posts/interfaces/post.interface';
import { PostsStore } from 'stores/posts/posts.store';
import { SharedType } from 'stores/share/enums/share-type.enum';
import { ShareStore } from 'stores/share/share.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import { DEFAULT_SHARE_TITLE } from 'configs/share.config';
import { checkIsUserStaff } from 'helpers/check-is-user-staff.util';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';
import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useTeamClick } from 'hooks/use-team-click';
import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { IAuthor, ShareModal } from 'components/modals/share-modal/share-modal.component';
import { PostCardFeedsTheme } from 'components/posts/post-card/post-card-feeds/post-card-feeds.component';
import { PostsFeed } from 'components/posts/posts-feed/posts-feed.component';
import { Loader } from 'components/ui/loader/loader.component';
import { IPollUpdateData, IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import { ISharePayload } from '../interfaces/share-payload.interface';

export const PostsFeedContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);
  const contentCardsStore = useInjection<ContentCardsStore>(TYPES.ContentCardsStore);
  const bookmarksStore = useInjection<BookmarksStore>(TYPES.BookmarksStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsStore);
  const headlinesStore = useInjection<HeadlinesStore>(TYPES.HeadlinesStore);
  const collaborationStore = useInjection<CollaborationStore>(TYPES.CollaborationStore);
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);

  const navigate = useNavigate();
  const { isNativeApp } = useMainProvider();

  const [sharePublicationData, setSharePublicationData] = useState<Maybe<IPost>>(null);

  const { googleAnalyticsHomeFeed, googleAnalyticsTeamFeed } = useGaScreenCustomView();
  const { shareByNativeModal } = useNativeShare();
  const queryClient = useQueryClient();

  const params = useParams<{
    [path.TEAM_ID_PARAM]: string;
  }>();

  const { teamId } = params;

  useToggleIgnoredTouchClass(postsStore.fetching);

  const isBottomLoading = useMemo(() => {
    return !!postsStore.fetchingState && postsStore.isLoadMore;
  }, [postsStore.fetchingState, postsStore.isLoadMore]);

  const handleLoadMore = useCallback(() => {
    postsStore.fetchToLoadMorePosts();
  }, [postsStore]);

  const handleOpenUserDetailsPopUp = useCallback(
    (userData: IBasePublicationAuthor) => {
      userPublicStore.setUserPopUpDetails(userData);
      layoutStore.setIsUserDetailsPopUpOpen(true);
    },
    [userPublicStore, layoutStore],
  );

  const handleDeletePost = useCallback(
    async (id: string) => {
      const isAuth = authStore.triggerAuthorisationCheck();

      if (isAuth) {
        await postsStore.deletePost({ postId: id });
        await bookmarksStore.refresh();
      }
    },
    [authStore, bookmarksStore, postsStore],
  );

  const handleTogglePostToBookmark = useCallback(
    async (postId: string) => {
      if (!authStore.isAuthorised) return;

      await postsStore.togglePostBookmark({ id: postId });

      await bookmarksStore.refresh();
    },
    [authStore, bookmarksStore, postsStore],
  );

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleShowShareModal = useCallback(
    async (payload: ISharePayload) => {
      await shareStore.fetchSharePublicationData(payload.postId);

      if (shareStore.shareData) {
        postsStore.updatePostShareCount(payload.postId, shareStore.shareData.count);
      }

      if (isNativeApp && shareStore.shareData?.type === SharedType.Post) {
        shareByNativeModal(
          shareStore.shareData.link,
          shareStore.shareData.title || metaTagsStore.metaTags?.title || DEFAULT_SHARE_TITLE,
        );
        return;
      }

      if (!isNativeApp) {
        const currentPost = postsStore.getPostById(payload.postId);

        setSharePublicationData(currentPost);
        shareStore.setIsShareModalVisible(true);
      }
    },
    [isNativeApp, postsStore, shareStore, metaTagsStore.metaTags, shareByNativeModal],
  );

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
    setSharePublicationData(null);
  }, [shareStore]);

  const handlePollVote = useCallback(
    async (pollData: IPollVoteData) => {
      if (!authStore.isAuthorised) {
        if (teamId && !teamsStore.isFollowedTeam) {
          teamsStore.setJoinModalVisible(true);
        }

        return;
      }

      await postsStore.postPollVote(pollData);
      await bookmarksStore.refresh();
    },
    [authStore, postsStore, bookmarksStore, teamsStore, teamId],
  );

  const handlePollExpired = useCallback(
    async (postId: string) => {
      if (!authStore.isAuthorised) {
        return;
      }

      await postsStore.asyncUpdatePostById({
        postId,
      });

      await bookmarksStore.refresh();
    },
    [authStore, postsStore, bookmarksStore],
  );

  const handlePollUpdate = useCallback(
    async (pollData: IPollUpdateData) => {
      if (!authStore.isAuthorised) {
        return;
      }

      await postsStore.updatePoll(pollData);
    },
    [authStore, postsStore],
  );

  const handlePostLike = useCallback(
    async (postId: string): Promise<void> => {
      if (!authStore.isAuthorised) return;

      await postsStore.postLikes({
        postId,
      });
      queryClient.invalidateQueries({
        queryKey: reputationQueryKeys.userBalance(authStore.userMe?.username),
      });
    },
    [authStore, postsStore, queryClient],
  );

  const handleOpenPreview = useCallback(
    (
      author: IAuthor,
      slides: IInteractiveMediaAttachment[],
      date: string,
      attachmentId: number,
    ) => {
      const watermarkEntity = teamsStatsStore.team
        ? { name: teamsStatsStore.team.nickname, logoUrl: teamsStatsStore.team.mediumLogoUrl }
        : null;

      galleryStore.setGalleryAttachments(slides);
      galleryStore.setCurrentAttachmentId(attachmentId);
      galleryStore.setAuthor(author);
      galleryStore.setAttachmentDate(date);
      galleryStore.setIsGalleryOpen(true);
      galleryStore.setWatermarkEntity(watermarkEntity);
    },
    [galleryStore, teamsStatsStore.team],
  );

  const handleResetCurrentPostId = useCallback(
    (postId: Maybe<string>) => {
      postsStore.setCurrentPostId(postId);
    },
    [postsStore],
  );

  const handleGetFavoriteTeam = useCallback(
    (id: Maybe<number>): Maybe<ITeamsStats> => {
      return teamsStatsStore.findTeamById(id);
    },
    [teamsStatsStore],
  );

  const handleGetFavoritePlayer = useCallback(
    (id: Maybe<number>): Maybe<IPlayerStats> => {
      return teamsStatsStore.findPlayerById(id);
    },
    [teamsStatsStore],
  );

  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(path.PLAYER_PROFILE, { [path.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const handleRemovePostFromEntries = useCallback(
    (postId: string) => {
      if (!postsStore.isSubmittedContentReport) {
        return;
      }

      postsStore.setSubmittedContentReport(false);
      postsStore.removePostFromEntries(postId);
    },
    [postsStore],
  );

  const handleReportSubmit = useCallback(
    async (postId: string, reasonId: number) => {
      return postsStore.sendPostReport(postId, reasonId);
    },
    [postsStore],
  );

  const handleCreateCollaborationItem = useCallback(
    async (payload: ICollaborationItemPayload) => {
      await collaborationStore.handleCreateCollaborationItem(payload);
      queryClient.invalidateQueries({
        queryKey: reputationQueryKeys.userBalance(authStore.userMe?.username),
      });
    },
    [collaborationStore, queryClient, authStore],
  );

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      postsStore.forceFetchToRefreshFeed();
      contentCardsStore.refreshContentCards();
    }
  }, [layoutStore.isPulledRefresher, postsStore, contentCardsStore]);

  useEffect(() => {
    if (teamId) {
      googleAnalyticsTeamFeed(ScreenNamesEnum.TeamProfileFeed);
    }
  }, [teamId, googleAnalyticsTeamFeed]);

  useEffect(() => {
    if (!teamId && layoutStore.isFirstTimeIntroShown && !authStore.authMode) {
      let screen = null;

      if (layoutStore.activeFeed === FeedTypes.YourFeed) {
        screen = ScreenNamesEnum.HomeFeedYourFeed;
      }

      if (screen) {
        googleAnalyticsHomeFeed(screen);
      }
    }
  }, [
    teamId,
    layoutStore.activeFeed,
    layoutStore.isFirstTimeIntroShown,
    authStore.authMode,
    googleAnalyticsHomeFeed,
  ]);

  useEffect(() => {
    if (postsStore.isPostCreationModalVisible) {
      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.CreatPost,
          team: teamsStatsStore.team?.name || 'null',
        },
      });
    }
  }, [postsStore.isPostCreationModalVisible, teamsStatsStore.team]);

  return (
    <>
      <PostsFeed
        isCurrentUserStaff={checkIsUserStaff(authStore.userMe?.roles)}
        requestReasons={applicationStore.retrieveReportReasons}
        onLikeClick={handlePostLike}
        onOpenUserDetailsPopUp={handleOpenUserDetailsPopUp}
        feedsTheme={PostCardFeedsTheme.Collapse}
        headlines={headlinesStore.shortcutHeadlines}
        playerContentCards={contentCardsStore.playerContentCards}
        gameContentCards={contentCardsStore.gameContentCards}
        reportReasons={applicationStore.reportReasons}
        isPulledRefresher={layoutStore.isPulledRefresher}
        userId={authStore?.userMe?.id || null}
        isAthlete={authStore.isAthlete}
        hasMore={!postsStore.fetchingState && !postsStore.isLastPageState}
        entries={postsStore.posts}
        team={teamsStatsStore.team}
        isCollaborationVideoLoading={collaborationStore.collaborationVideoLoading}
        fetched={!!postsStore.fetchedState}
        fetching={!!postsStore.fetchingState}
        visitedPostId={postsStore.currentPostId}
        activeFeed={layoutStore.activeFeed}
        onOpenPreview={handleOpenPreview}
        onLoadMore={handleLoadMore}
        onTogglePostToBookmarks={handleTogglePostToBookmark}
        onShareClick={handleShowShareModal}
        onPollVote={handlePollVote}
        onPollUpdate={handlePollUpdate}
        onPollExpired={handlePollExpired}
        onDeletePost={handleDeletePost}
        onResetCurrentPostId={handleResetCurrentPostId}
        onGetFavoriteTeam={handleGetFavoriteTeam}
        onGetFavoritePlayer={handleGetFavoritePlayer}
        onTeamClick={handleTeamClick}
        onPlayerClick={handlePlayerClick}
        onSendReport={handleReportSubmit}
        onCloseReportsPopup={handleRemovePostFromEntries}
        postProcessingId={collaborationStore.postProcessingId}
        convertImageItemToAttachment={collaborationStore.uploadCollaborationImage}
        onCreateCollaborationItem={handleCreateCollaborationItem}
        convertVideoItemToAttachment={collaborationStore.uploadCollaborationVideo}
        collaborationMediaItem={collaborationStore.newCollaborationItemMedia}
        setCollaborationMediaItem={collaborationStore.setNewCollaborationItemMedia}
      />
      <Loader isShow={isBottomLoading} isLocal />
      {!isNativeApp && sharePublicationData && shareStore.shareData?.type === SharedType.Post && (
        <ShareModal
          id={sharePublicationData.uuid}
          author={sharePublicationData.user}
          onClose={handleResetSharing}
          onCopyLink={handleCopySharedLink}
          shareContent={{
            pollOptions: shareStore.shareData.pollOptions,
            title: shareStore.shareData.title,
            attachments: shareStore.shareData.attachmentCounts,
            dateCreated: sharePublicationData.formattedDates.relativeLong,
            content: shareStore.shareData.content,
          }}
          visible={shareStore.isShareModalVisible}
        />
      )}
    </>
  );
});
