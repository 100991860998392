import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { IGameDetailedRecord } from 'query-hooks/scoreboard/interfaces/game-detailed-record.interface';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { SeasonType } from 'services/seasons/enums/season-type.enum';
import { IGamePeriods } from 'services/statistics/interface/game.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatScheduledDate } from 'helpers/format/format-scheduled-date.util';

import { useResponsive } from 'hooks/use-responsive';

import { GameDetailsTabs } from 'components/game/game.config';
import { ScoreboardTableLinks } from 'components/scoreboard/scoreboard-table/scoreboard-table-links/scoreboard-table-links.component';
import { VerticalRowsTable } from 'components/scoreboard/scoreboard-table/vertical-rows-table/vertical-rows-table.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { ScoreboardTableDetails } from './scoreboard-table-details/scoreboard-table-details.component';
import { ScoreboardTableHeader } from './scoreboard-table-header/scoreboard-table-header.component';
import { ScoreboardTableRow } from './scoreboard-table-row/scoreboard-table-row.component';

import styles from './scoreboard-table.module.less';

interface IScoreboardTableProps {
  dateStart: string;
  status: GameStatus;
  home: IGameDetailedRecord;
  visitors: IGameDetailedRecord;
  firstNotFinished: boolean;
  seasonType: SeasonType;
  timezone: string;
  gameId: number;
  periods: IGamePeriods;
  clock: Maybe<string>;
  formattedPeriod: string;
  onPlayerClick: (slug: string, gameId: string) => void;
  onTeamClick: (teamId: number) => void;
  onGameClick: (tabQuery: Maybe<GameDetailsTabs>) => void;
}

export const ScoreboardTable = (props: IScoreboardTableProps) => {
  const {
    gameId,
    dateStart,
    status,
    home,
    visitors,
    seasonType,
    firstNotFinished,
    timezone,
    periods,
    formattedPeriod,
    clock,
    onPlayerClick,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const isFinished = status === GameStatus.Finished;
  const isLive = status === GameStatus.Live;
  const isHalftime = status === GameStatus.Halftime;
  const isScheduled = status === GameStatus.Scheduled;

  const withHeader = useMemo(
    () => firstNotFinished && isDesktopPlus,
    [firstNotFinished, isDesktopPlus],
  );

  const tableClassNames = useMemo(
    () =>
      cn(styles.ScoreboardTable, {
        [styles['ScoreboardTable--with-header']]: withHeader,
        [styles['ScoreboardTable--expanded']]: expanded,
        [styles['ScoreboardTable--live']]: isLive,
        [styles['ScoreboardTable--halftime']]: isHalftime,
      }),
    [withHeader, expanded, isLive, isHalftime],
  );

  const handleDetailsClick = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const handlePlayersClick = useCallback(
    (slug: string) => {
      onPlayerClick(slug, gameId.toString());
    },
    [onPlayerClick, gameId],
  );

  if (isFinished) {
    return (
      <VerticalRowsTable
        total={periods.total}
        home={home}
        status={GameStatus.Finished}
        visitors={visitors}
        currentQuarter={periods.current}
        clock={clock}
        seasonType={seasonType}
        onPlayerClick={handlePlayersClick}
        onTeamClick={props.onTeamClick}
        onGameClick={props.onGameClick}
      />
    );
  }

  return (
    <div className={tableClassNames} role="table">
      <div className={styles.ScoreboardTable__Main}>
        {withHeader && <ScoreboardTableHeader timezone={timezone} />}
        <ScoreboardTableRow
          status={status}
          home={home}
          visitors={visitors}
          dateStart={dateStart}
          formattedPeriod={formattedPeriod}
          clock={clock}
          seasonType={seasonType}
          onTeamClick={props.onTeamClick}
          onGameClick={props.onGameClick}
        />
      </div>
      {withHeader && <hr />}
      {(isLive || isHalftime) && isDesktopPlus && (
        <div className={styles.ScoreboardTable__Button}>
          <Button
            size={ButtonSize.Small}
            theme={ButtonTheme.TextSecondary}
            iconName={expanded ? IconFontName.ChevronUp : IconFontName.ChevronDown}
            onClick={handleDetailsClick}
          >
            Details
          </Button>
        </div>
      )}
      {expanded && (
        <div className={styles.ScoreboardTable__Details}>
          <ScoreboardTableDetails
            home={home}
            visitors={visitors}
            status={status}
            periods={periods}
            clock={clock}
            seasonType={seasonType}
            onPlayerClick={handlePlayersClick}
            onTeamClick={props.onTeamClick}
            onGameClick={props.onGameClick}
          />
        </div>
      )}
      {isScheduled && (
        <div className={styles.ScoreboardTable__Links}>
          <ScoreboardTableLinks
            gameStatusOrTime={formatScheduledDate(dateStart)}
            isFinished={false}
            isLive={false}
            onLinkClick={props.onGameClick}
          />
        </div>
      )}
    </div>
  );
};
